export enum FooterTypeEnum {
  LINK = "LINK",
  PAGE = "PAGE",
}

export default interface IFooter {
  title: string;
  link: string;
  linkType: FooterTypeEnum;
  displayOnLoginFooter: boolean;
}
