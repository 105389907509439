import React, { useCallback, useEffect, useState } from "react";
import { Divider, Row, Col, Button } from "antd";
import { useMarketParameter } from "../Utils/MarketParameterContext";
import { getPublicHtmlPage } from "../Utils/ParameterServiceFunctional";
import FooterDetail from "./FooterDetail";
import IFooter, { FooterTypeEnum } from "./shoppingcart/IFooter";

const { version } = require("../../package.json");

const footerJs = (modal: any, btn: any, span: any) => {
  if (modal && btn && span) {
    // When the user clicks the button, open the modal
    btn.onclick = function () {
      modal.style.display = "block";
    };

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    };

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event: any) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };
  }
};

const Footer = (props: { isAuthenticated?: number }): React.ReactElement => {
  const { isAuthenticated } = props;
  const {
    APP_FOOTER,
    APP_FOOTER_LOGIN,
    FOOTER_CONTENT_DETAIL,
    DISPLAY_FOOTER_CONTACT,
  } = useMarketParameter();
  const [page, setPage] = useState<string>("");
  const [displayIncFooter, setDisplayIncFooter] = useState<boolean>(false);
  const [displayIncFooterLogin, setDisplayIncFooterLogin] =
    useState<boolean>(false);
  const [footerDetailPageName, setFooterDetailPageName] = useState<string>("");
  const [footerDetailTitle, setFooterDetailTitle] = useState<string>("");
  const [footerDetailVisibility, setFooterDetailVisibility] =
    useState<boolean>(false);
  const [footerContentDetail, setFooterContentDetail] = useState<IFooter[]>([]);

  const formatFooter = useCallback((response: string): void => {
    setPage(response);
    var modal1: any = document.getElementById("myModal1");
    var btn1: any = document.getElementById("myBtn1");
    var close1: any = document.getElementById("close1");
    footerJs(modal1, btn1, close1);
    var modal2: any = document.getElementById("myModal2");
    var btn2: any = document.getElementById("myBtn2");
    var close2: any = document.getElementById("close2");
    footerJs(modal2, btn2, close2);
    var modal3: any = document.getElementById("myModal3");
    var btn3: any = document.getElementById("myBtn3");
    var close3: any = document.getElementById("close3");
    footerJs(modal3, btn3, close3);
    var modal4: any = document.getElementById("myModal4");
    var btn4: any = document.getElementById("myBtn4");
    var close4: any = document.getElementById("close4");
    footerJs(modal4, btn4, close4);
  }, []);

  const initFooter = useCallback(async (): Promise<void> => {
    if (
      APP_FOOTER_LOGIN &&
      APP_FOOTER_LOGIN.toUpperCase() !== "INC" &&
      !isAuthenticated
    ) {
      getPublicHtmlPage(APP_FOOTER_LOGIN)
        .then((response: string) => {
          formatFooter(response);
        })
        .catch((error) => {
          setPage("");
        });
    }
    if (APP_FOOTER.toUpperCase() !== "INC" && isAuthenticated) {
      getPublicHtmlPage(APP_FOOTER)
        .then((response: string) => {
          formatFooter(response);
        })
        .catch((error) => {
          setPage("");
        });
    }

    //  Si on est en mode Footer INC et que l'utilisateur est déjà connecté
    //  On vérifie l'existence d'un flux JSON dans le paramètre FOOTER_CONTENT_DETAIL
    let footerContent = false;

    if (FOOTER_CONTENT_DETAIL !== undefined && FOOTER_CONTENT_DETAIL !== "") {
      footerContent = true;
    }
    if (
      (APP_FOOTER.toUpperCase() === "INC" &&
        isAuthenticated &&
        footerContent) ||
      (APP_FOOTER.toUpperCase() === "INC" &&
        isAuthenticated &&
        DISPLAY_FOOTER_CONTACT)
    ) {
      if (footerContent) {
        setFooterContentDetail(JSON.parse(FOOTER_CONTENT_DETAIL));
      }
      setDisplayIncFooter(true);
    }

    //  Si on est en mode Footer INC et que l'utilisateur n'est pas déjà connecté
    if (
      APP_FOOTER_LOGIN.toUpperCase() === "INC" &&
      !isAuthenticated &&
      footerContent
    ) {
      setFooterContentDetail(JSON.parse(FOOTER_CONTENT_DETAIL));
      setDisplayIncFooterLogin(true);
    }
  }, [
    APP_FOOTER,
    APP_FOOTER_LOGIN,
    DISPLAY_FOOTER_CONTACT,
    FOOTER_CONTENT_DETAIL,
    formatFooter,
    isAuthenticated,
  ]);

  // Requête pour récupérer le contenu de ld fichier .html
  useEffect(() => {
    initFooter();
  }, [initFooter]);

  const onFooterClick = useCallback((selectedFooterContent: IFooter): void => {
    if (selectedFooterContent.linkType === FooterTypeEnum.PAGE) {
      setFooterDetailPageName(selectedFooterContent.link);
      setFooterDetailVisibility(true);
    }
    if (selectedFooterContent.linkType === FooterTypeEnum.LINK) {
      setFooterDetailPageName(selectedFooterContent.link);
      setFooterDetailVisibility(true);
    }
  }, []);

  const onDisplayContacts = useCallback((): void => {
    setFooterDetailPageName("CONTACTS");
    setFooterDetailTitle("Nous contacter");
    setFooterDetailVisibility(true);
  }, []);

  const onCancel = useCallback((): void => {
    setFooterDetailPageName("");
    setFooterDetailVisibility(false);
  }, []);

  return (
    <div style={{ marginTop: 50, marginBottom: 10, position: "relative" }}>
      {/* <div style={{ marginTop: 20, marginBottom: 10 }}> */}
      <Divider />
      {page !== "" && <div dangerouslySetInnerHTML={{ __html: page }}></div>}
      {(displayIncFooter || displayIncFooterLogin) && (
        <Row style={{ width: "100%", backgroundColor: "gray" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            {isAuthenticated && DISPLAY_FOOTER_CONTACT && (
              <Button
                type="text"
                style={{ color: "#FFFFFF" }}
                onClick={onDisplayContacts}
              >
                Nous contacter
              </Button>
            )}
            {footerContentDetail.map((currentFooterContentDetail: IFooter) => {
              if (
                !isAuthenticated &&
                !currentFooterContentDetail.displayOnLoginFooter
              )
                return null;
              if (currentFooterContentDetail.linkType === FooterTypeEnum.PAGE) {
                return (
                  <Button
                    key={currentFooterContentDetail.title}
                    type="text"
                    style={{ color: "#FFFFFF" }}
                    onClick={() => onFooterClick(currentFooterContentDetail)}
                  >
                    {currentFooterContentDetail.title}
                  </Button>
                );
              }
              if (currentFooterContentDetail.linkType === FooterTypeEnum.LINK) {
                return (
                  <Button
                    key={currentFooterContentDetail.title}
                    type="text"
                    style={{ color: "#FFFFFF" }}
                    href={currentFooterContentDetail.link}
                    target="_blank"
                  >
                    {currentFooterContentDetail.title}
                  </Button>
                );
              }

              return null;
            })}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24} style={{ marginTop: 20, textAlign: "center" }}>
          v{version} - Conception & Réalisation{" "}
          <a
            href="http://www.inconcept.fr"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={"/api/public/Logo-In-Concept.png"}
              alt="IN Concept"
              style={{ height: 25 }}
            />
          </a>
        </Col>
      </Row>
      <FooterDetail
        footerDetailPageName={footerDetailPageName}
        footerDetailTitle={footerDetailTitle}
        footerDetailVisibility={footerDetailVisibility}
        onCancel={onCancel}
      />
    </div>
  );
};

export default Footer;
