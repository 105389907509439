import IVehicleNode from "./model/IVehicleNode";
import IVehicleType from "./model/IVehicleType";
import IArticleLinkage from "./model/IArticleLinkage";

export const findArticleFamily = async (
  parent: number | undefined,
  deep: number | undefined,
  highlight: boolean,
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/families?customer=" +
      customerId +
      "&type=ARTICLE" +
      "&parent=" +
      (parent === undefined ? "" : parent) +
      "&deep=" +
      (deep === undefined ? "" : deep) +
      "&highlight=" +
      highlight +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=" +
      sort,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findArticleFamilyByVehicleType = async (
  parent: number | undefined,
  deep: number | undefined,
  vehiculeTypeId: number,
  modelYear: number | undefined,
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/vehicleType/" +
      vehiculeTypeId +
      "/families?customer=" +
      customerId +
      "&parent=" +
      (parent === undefined ? "" : parent) +
      "&deep=" +
      (deep === undefined ? "" : deep) +
      "&modelYear=" +
      (modelYear === undefined ? "" : modelYear) +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=" +
      sort,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findVehicleTypeById = async (
  id: number
): Promise<IVehicleType> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/vehicleType/" +
      id +
      "?customer=" +
      customerId +
      "&sort=startDate,asc",
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findVehicleNode = async (
  query: string | undefined,
  parent: number | undefined,
  deep: number | undefined,
  highlight: boolean,
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  let url =
    "/api/market/vehicleNodes?customer=" +
    customerId +
    "&parent=" +
    (parent === undefined ? "" : parent) +
    "&deep=" +
    (deep === undefined ? "" : deep) +
    "&highlight=" +
    highlight +
    "&size=" +
    size +
    "&page=" +
    page +
    "&sort=" +
    sort;

  if (query !== undefined) {
    url += "&query=" + query;
  }

  return fetch(url, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const searchVehicleByPlateNumberCatdata = async (
  plateNumber: string | undefined,
  vin: string | undefined
): Promise<any | undefined> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  let url = `/api/market/vehicleTypes?customer=${customerId}${
    plateNumber ? `&plateNumber=${plateNumber}` : ""
  }${vin ? `&vin=${vin}` : ""}`;

  const response = await fetch(url, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
  if (response.status === 404) {
    return undefined;
  }
  return response.json();
};

export const getVehicleSearchHistory = (
  size: number | undefined
  // page: number | undefined
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  let url = `/api/market/vehicleSearchHistories?customer=${customerId}&withVehicleType=${true}&size=${size}`;

  return fetch(url, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findVehicleNodeById = async (
  id: number
): Promise<IVehicleNode> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch("/api/market/vehicleNodes/" + id + "?customer=" + customerId, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findVehicleTypeByVehiculeNode = async (
  query: string | undefined,
  vehiculeNodeId: number,
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  let url =
    "/api/market/vehicleNodes/" +
    vehiculeNodeId +
    "/vehicleTypes?customer=" +
    customerId +
    "&size=" +
    size +
    "&page=" +
    page +
    "&sort=" +
    sort;

  if (query !== undefined) {
    url += "&query=" + query;
  }

  return fetch(url, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findVehicleLinkageByArticle = async (
  id: number,
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<IArticleLinkage> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/article/" +
      id +
      "/articlelinkage?customer=" +
      customerId +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=" +
      sort,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findArticleCriterionByArticle = async (
  id: number,
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/article/" +
      id +
      "/articleCriteria?customer=" +
      customerId +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=" +
      sort,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findCriterionByArticle = async (
  id: number,
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/article/" +
      id +
      "/criteria?customer=" +
      customerId +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=" +
      sort,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findDocumentFamily = async (
  parent: number | undefined,
  deep: number | undefined,
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/families?customer=" +
      customerId +
      "&type=DOCUMENT" +
      "&parent=" +
      (parent === undefined ? "" : parent) +
      "&deep=" +
      (deep === undefined ? "" : deep) +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=" +
      sort,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};
