import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Select, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import IFamily from '../model/IFamily';
import CatalogService from '../CatalogService';
import ICatalogSearch from '../model/ICatalogSearch';


const { Option } = Select;

class CatalogFamilyCardSearch extends React.Component<
  {
    history: any,
    catalogSearch: ICatalogSearch,
    setCatalogSearch: any,
    clearCatalogSearch: any,
    setTypeSearch: any
  },
  {
    dataLoading: boolean,
    families: IFamily[],
    familiesChildren1: IFamily[],
    familiesChildren2: IFamily[],
  }
  >{

  private catalogService: CatalogService = new CatalogService();

  constructor(props: { history: any, catalogSearch: ICatalogSearch, setCatalogSearch: any, clearCatalogSearch: any, setTypeSearch: any }) {
    super(props);

    this.state = {
      dataLoading: true,
      families: [],
      familiesChildren1: [],
      familiesChildren2: [],
    };
  }

  componentDidMount() {
    this.loadFamily('',1, undefined, 200, 0);
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) =>
          <Card bordered={false} title={<div><FontAwesomeIcon icon={faSearchLocation} /> Recherche par catégorie</div>} size="small"
            bodyStyle={{ minHeight: 100, marginTop: 10 }} headStyle={{ fontWeight: "bold", background: "#FFFFFF", fontSize: "1.1em", color: "#545454" }}>
            <div>
              <Select style={{ width: 344 }}
                onChange={this.selecteFamily}
                filterOption={(input: string, option: any) => this.filterOption(input, option)}
                loading={this.state.dataLoading} placeholder='Famille articles'
                showSearch={true} optionFilterProp="children" value={contextValues.catalogSearch.queryType === '' ? contextValues.catalogSearch.level1Id : undefined} >
                {this.state.families.map((item: IFamily) => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
              <Select style={{ width: 344, marginTop: 5}}
                onChange={this.selecteFamilyChildren1} disabled={this.state.familiesChildren1.length === 0}
                filterOption={(input: string, option: any) => this.filterOption(input, option)}
                loading={this.state.dataLoading} placeholder='Sous famille articles'
                showSearch={true} optionFilterProp="children" value={contextValues.catalogSearch.queryType === 'family' ? contextValues.catalogSearch.level2Id : undefined} >
                {this.state.familiesChildren1.map((item: IFamily) => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
              <Select style={{ width: 344, marginTop: 5 }}
                onChange={this.selecteFamilyChildren2} disabled={this.state.familiesChildren2.length === 0}
                filterOption={(input: string, option: any) => this.filterOption(input, option)}
                loading={this.state.dataLoading} placeholder='Sous famille articles'
                showSearch={true} optionFilterProp="children" value={contextValues.catalogSearch.queryType === 'family' ? contextValues.catalogSearch.level3Id : undefined} >
                {this.state.familiesChildren2.map((item: IFamily) => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
              <div style={{ width: '100%', marginTop: 5 }}>
                <Button type='primary' style={{ float: 'right' }} onClick={() => this.onSearch()}>Rechercher</Button>
              </div>
            </div>
          </Card>
        }
      </BasketContext.Consumer>
    );
  }

  private onSearch = () => {
      this.props.setTypeSearch(3);
      this.props.history.push('/catalog');
  }

  private filterOption = (input: string, option: any): boolean => {

    if (Array.isArray(option.options)) {
      return option.options[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    else {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

  }

  private selecteFamily = (value: number, option: any) => {
    this.loadFamily(option.children, 2,value, 200, 0);
  }

  private selecteFamilyChildren1 = (value: number, option: any) => {
    this.loadFamily(option.children, 3, value, 200, 0);
  }

  private selecteFamilyChildren2 = (value: number, option: any) => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level3Id: value,
      level3Name: option.children,
    }

    this.props.setCatalogSearch(catalogSearch);
  }  

  private loadFamily = (description: string, deep: number, parentId: number | undefined, pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.catalogService.findArticleFamily(parentId, undefined, false, pageSize, page, 'name')
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined && deep === 1) {
          this.setState({
            families: result.content,
            dataLoading: false,
            familiesChildren1: [],
            familiesChildren2: [],
          });

          let catalogSearch: ICatalogSearch = {
            ...this.props.catalogSearch,
            queryType: 'family',
            level1Id: undefined,
            level1Name: undefined,
            level2Id: undefined,
            level2Name: undefined,
            level3Id: undefined,
            level3Name: undefined,          
            level4Id: undefined,
            level4Name: undefined,
            level5Id: undefined,
            level5Name: undefined              
          }
          this.props.setCatalogSearch(catalogSearch);

        }
        else if (result !== undefined && result.content !== undefined && deep === 2) {
          this.setState({
            dataLoading: false,
            familiesChildren1: result.content,
            familiesChildren2: [],        
          });

          let catalogSearch: ICatalogSearch = {
            ...this.props.catalogSearch,
            level1Id: parentId,
            level1Name: description,
            level2Id: undefined,
            level2Name: undefined,
            level3Id: undefined,
            level3Name: undefined,        
            level4Id: undefined,
            level4Name: undefined,
            level5Id: undefined,
            level5Name: undefined                  
          }
          this.props.setCatalogSearch(catalogSearch);

        }
        else if (result !== undefined && result.content !== undefined && deep === 3) {
          this.setState({
            dataLoading: false,
            familiesChildren2: result.content,
          });

          let catalogSearch: ICatalogSearch = {
            ...this.props.catalogSearch,
            level2Id: parentId,
            level2Name: description,
            level3Id: undefined,
            level3Name: undefined,     
            level4Id: undefined,
            level4Name: undefined,
            level5Id: undefined,
            level5Name: undefined                     
          }
          this.props.setCatalogSearch(catalogSearch);

        }        
        else {
          this.setState({
            dataLoading: false
          });
        }
      });
  }

}

export default withRouter(withBasket(CatalogFamilyCardSearch));