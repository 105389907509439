import React, { useCallback } from "react";
import { ShoppingCartOutlined, StarOutlined } from "@ant-design/icons";
import { Row, Col, Button, Card, Tooltip, Typography, Tag, Image } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrownOpen } from "@fortawesome/free-solid-svg-icons";

import IArticleSearchResult from "./IArticleSearchResult";
import ArticleWishlistForm from "./ArticleWishlistForm";
import MoreAvailabilities from "./component/MoreAvailabilities";
import { BasketContext, withBasket } from "../../Utils/BasketProvider";
import ArticlePrice from "./component/ArticlePrice";
import { MarketParameterContext } from "../../Utils/MarketParameterContext";
import ArticleImage from "./component/ArticleImage";
import IArticle from "./IArticle";
import { onAddBookmark } from "./ArticleUtilsFunctional";
import ArticleAvailability from "./ArticleAvailability";
import ArticleEquivalence2 from "./ArticleEquivalence2";

const { Text } = Typography;

interface ArticleCardProps {
  item: IArticleSearchResult;
  dataLoading: boolean;
  onChangeSelect: (platformId: number, item: IArticle) => void;
  onChangeQuantity: (
    quantity: number,
    platformId: number,
    item: IArticle
  ) => void;
  onSelectEquivalence: (articleId: number) => void;
  setSelectedArticle: (selectedArticle: IArticle | undefined) => void;
  isModal: boolean;
  infosTecdoc: boolean;
  infosCatalogF1: boolean;
  openModalAccount: () => void;
}

const ArticleCard = (props: ArticleCardProps): JSX.Element => {
  const {
    item,
    dataLoading,
    onChangeSelect,
    onChangeQuantity,
    onSelectEquivalence,
    setSelectedArticle,
    isModal,
    infosTecdoc,
    infosCatalogF1,
    openModalAccount,
  } = props;

  const customerId = sessionStorage.getItem("customerId");

  const onSelectedArticle = useCallback(
    (articleSearchResult: IArticleSearchResult) => {
      setSelectedArticle(articleSearchResult.article);
    },
    [setSelectedArticle]
  );

  const title = useCallback(
    (
      articleSearchResult: IArticleSearchResult,
      userRole: string,
      showManufacturer: boolean
    ): JSX.Element => {
      const { article } = articleSearchResult;
      return (
        <div>
          <Row>
            {userRole !== "ROLE_GUEST" && (
              <Col flex="40px">
                {articleSearchResult.bookmarked && (
                  <Tooltip
                    title="Cet article est dans vos favoris"
                    placement="right"
                  >
                    <StarOutlined
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#1890ff",
                        marginTop: 10,
                      }}
                    />
                  </Tooltip>
                )}
                {!articleSearchResult.bookmarked && (
                  <Tooltip
                    title="Ajouter cet article au favoris"
                    placement="right"
                  >
                    <Button
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      onClick={() => onAddBookmark(articleSearchResult)}
                      type="link"
                    >
                      <StarOutlined
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          color: "#BFBFBF",
                        }}
                      />
                    </Button>
                  </Tooltip>
                )}
              </Col>
            )}
            <Col flex="auto">
              <Button
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                }}
                type="link"
                onClick={() => onSelectedArticle(articleSearchResult)}
              >
                {article.reference} : {article.name}
              </Button>
            </Col>
            <Col flex="70px">
              {articleSearchResult.quantityInWishlists > 0 && (
                <Tooltip
                  title={`${articleSearchResult.quantityInWishlists} dans le panier`}
                  placement="left"
                >
                  <Tag color="orange">
                    {articleSearchResult.quantityInWishlists}
                    <ShoppingCartOutlined />
                  </Tag>
                </Tooltip>
              )}
            </Col>
            <Col flex="100px" style={{ textAlign: "right" }}>
              {showManufacturer && (
                <Image
                  src={
                    "/api/market/manufacturers/" +
                    article.manufacturer.id +
                    "/logo?customer=" +
                    customerId
                  }
                  key={article.reference}
                  alt={article.reference}
                  style={{ maxWidth: 100, maxHeight: 30 }}
                />
              )}
            </Col>
          </Row>
        </div>
      );
    },
    [customerId, onSelectedArticle]
  );

  // const { article } = item;

  return (
    <MarketParameterContext.Consumer>
      {(parameters) => (
        <BasketContext.Consumer>
          {(basket) => (
            <Card
              title={title(item, basket.userRole, parameters.SHOW_MANUFACTURER)}
              headStyle={{
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: "#FAFAFA",
              }}
              style={{ backgroundColor: "yellow" }}
            >
              {item.known && (
                // this.props.item.article.availability &&
                <>
                  <Row>
                    <Col flex="130px">
                      <ArticleImage item={item.article} />
                    </Col>
                    <Col flex="550px">
                      <ArticleAvailability
                        currentItem={item.article}
                        loading={dataLoading}
                        showAvailability={false}
                        showManufacturer={parameters.SHOW_MANUFACTURER}
                        currentSupplierAvailabilityStatus={
                          parameters.SUPPLIER_AVAILABILITY_STATUS
                        }
                        currentSupplierAvailabilityLabel={
                          parameters.SUPPLIER_AVAILABILITY_LABEL
                        }
                        currentSupplierAvailabilityWarningEnabled={
                          parameters.SUPPLIER_AVAILABILITY_WARNING_ENABLED
                        }
                        openModalAccount={openModalAccount}
                      />
                    </Col>
                    <Col flex="auto">
                      <ArticleWishlistForm
                        articleState={item.article}
                        currentSupplierAvailabilityStatus={
                          parameters.SUPPLIER_AVAILABILITY_STATUS
                        }
                        onSelectPlatform={(value: any) =>
                          onChangeSelect(value, item.article)
                        }
                        onChangeQuantity={onChangeQuantity}
                      />
                      <br />
                      <Col xl={24} xxl={12}>
                        <MoreAvailabilities
                          item={item.article}
                          loading={item.article.loading}
                        />
                      </Col>
                    </Col>
                    <Col flex="140px">
                      {basket.userRole !== "ROLE_GUEST" && (
                        <ArticlePrice
                          item={item.article}
                          loading={item.article.loading}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 15 }}>
                    <Col span={24}>
                      <ArticleEquivalence2
                        item={item.article}
                        onSelectEquivalence={onSelectEquivalence}
                        isModal={isModal}
                        infosTecdoc={infosTecdoc}
                        infosCatalogF1={infosCatalogF1}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {!item.known && (
                <Row style={{ color: "#faad14" }}>
                  <Col flex={"50px"}>
                    <FontAwesomeIcon
                      icon={faFrownOpen}
                      style={{ fontSize: 40 }}
                    />
                  </Col>
                  <Col style={{ paddingTop: 8 }}>
                    <Text type="warning">Article non trouvé.</Text>
                  </Col>
                </Row>
              )}
            </Card>
          )}
        </BasketContext.Consumer>
      )}
    </MarketParameterContext.Consumer>
  );
};

export default withBasket(ArticleCard);

// class ArticleCard extends React.Component<
//   {
//     item: IArticleSearchResult;
//     onChangeSelect: any;
//     onChangeQuantity: any;
//     onSelectEquivalence: any;
//     // setArticleView: any;
//     setSelectedArticle: any;
//     isModal: boolean;
//     infosTecdoc: boolean;
//     infosCatalogF1: boolean;
//     openModalAccount: () => void;
//   },
//   {}
// > {
//   private articleUtils: ArticleUtils = new ArticleUtils();
//   // private customerId = sessionStorage.getItem("customerId");

//   // private title = (
//   //   articleSearchResult: IArticleSearchResult,
//   //   userRole: string,
//   //   showManufacturer: boolean
//   // ) => {
//   //   const { article } = articleSearchResult;
//   //   return (
//   //     <div>
//   //       <Row>
//   //         {userRole !== "ROLE_GUEST" && (
//   //           <Col flex="40px">
//   //             {articleSearchResult.bookmarked && (
//   //               <Tooltip
//   //                 title="Cet article est dans vos favoris"
//   //                 placement="right"
//   //               >
//   //                 <StarOutlined
//   //                   style={{
//   //                     fontSize: 16,
//   //                     fontWeight: 500,
//   //                     color: "#1890ff",
//   //                     marginTop: 10,
//   //                   }}
//   //                 />
//   //               </Tooltip>
//   //             )}
//   //             {!articleSearchResult.bookmarked && (
//   //               <Tooltip
//   //                 title="Ajouter cet article au favoris"
//   //                 placement="right"
//   //               >
//   //                 <Button
//   //                   style={{ paddingLeft: 0, paddingRight: 0 }}
//   //                   onClick={() =>
//   //                     this.articleUtils.onAddBookmark(this, articleSearchResult)
//   //                   }
//   //                   type="link"
//   //                 >
//   //                   <StarOutlined
//   //                     style={{
//   //                       fontSize: 16,
//   //                       fontWeight: 500,
//   //                       color: "#BFBFBF",
//   //                     }}
//   //                   />
//   //                 </Button>
//   //               </Tooltip>
//   //             )}
//   //           </Col>
//   //         )}
//   //         <Col flex="auto">
//   //           <Button
//   //             style={{
//   //               fontSize: 16,
//   //               fontWeight: 500,
//   //               paddingLeft: 0,
//   //               paddingRight: 0,
//   //               paddingTop: 0,
//   //             }}
//   //             type="link"
//   //             onClick={() => this.onSelectedArticle(articleSearchResult)}
//   //           >
//   //             {article.reference} : {article.name}
//   //           </Button>
//   //         </Col>
//   //         <Col flex="70px">
//   //           {articleSearchResult.quantityInWishlists > 0 && (
//   //             <Tooltip
//   //               title={`${articleSearchResult.quantityInWishlists} dans le panier`}
//   //               placement="left"
//   //             >
//   //               <Tag color="orange">
//   //                 {articleSearchResult.quantityInWishlists}
//   //                 <ShoppingCartOutlined />
//   //               </Tag>
//   //             </Tooltip>
//   //           )}
//   //         </Col>
//   //         <Col flex="100px" style={{ textAlign: "right" }}>
//   //           {showManufacturer && (
//   //             <Image
//   //               src={
//   //                 "/api/market/manufacturers/" +
//   //                 article.manufacturer.id +
//   //                 "/logo?customer=" +
//   //                 this.customerId
//   //               }
//   //               key={article.reference}
//   //               alt={article.reference}
//   //               style={{ maxWidth: 100, maxHeight: 30 }}
//   //             />
//   //           )}
//   //         </Col>
//   //       </Row>
//   //     </div>
//   //   );
//   // };

//   // render() {
//   //   const { article } = this.props.item;
//   //   return (
//   //     <MarketParameterContext.Consumer>
//   //       {(parameters) => (
//   //         <BasketContext.Consumer>
//   //           {(basket) => (
//   //             <Card
//   //               title={this.title(
//   //                 this.props.item,
//   //                 basket.userRole,
//   //                 parameters.SHOW_MANUFACTURER
//   //               )}
//   //               headStyle={{
//   //                 paddingLeft: 10,
//   //                 paddingRight: 10,
//   //                 backgroundColor: "#FAFAFA",
//   //               }}
//   //             >
//   //               {this.props.item.known && (
//   //                 // this.props.item.article.availability &&
//   //                 <>
//   //                   <Row>
//   //                     <Col flex="130px">
//   //                       <ArticleImage item={article} />
//   //                     </Col>
//   //                     <Col flex="550px">
//   //                       <ArticleAvailability
//   //                         item={article}
//   //                         loading={article.loading}
//   //                         showAvailability={false}
//   //                         showManufacturer={parameters.SHOW_MANUFACTURER}
//   //                         currentSupplierAvailabilityStatus={
//   //                           parameters.SUPPLIER_AVAILABILITY_STATUS
//   //                         }
//   //                         currentSupplierAvailabilityLabel={
//   //                           parameters.SUPPLIER_AVAILABILITY_LABEL
//   //                         }
//   //                         currentSupplierAvailabilityWarningEnabled={
//   //                           parameters.SUPPLIER_AVAILABILITY_WARNING_ENABLED
//   //                         }
//   //                         openModalAccount={this.props.openModalAccount}
//   //                       />
//   //                     </Col>
//   //                     <Col flex="auto">
//   //                       <ArticleWishlistForm
//   //                         item={article}
//   //                         currentSupplierAvailabilityStatus={
//   //                           parameters.SUPPLIER_AVAILABILITY_STATUS
//   //                         }
//   //                         onSelectPlatform={(value: any) =>
//   //                           this.props.onChangeSelect(value, article)
//   //                         }
//   //                         onChangeQuantity={this.props.onChangeQuantity}
//   //                       />
//   //                       <br />
//   //                       <Col xl={24} xxl={12}>
//   //                         <MoreAvailabilities
//   //                           item={article}
//   //                           // loading={article.loading}
//   //                         />
//   //                       </Col>
//   //                     </Col>
//   //                     <Col flex="140px">
//   //                       {basket.userRole !== "ROLE_GUEST" && (
//   //                         <ArticlePrice
//   //                           item={article}
//   //                           loading={article.loading}
//   //                         />
//   //                       )}
//   //                     </Col>
//   //                   </Row>
//   //                   <Row style={{ marginTop: 15 }}>
//   //                     <Col span={24}>
//   //                       <ArticleEquivalence
//   //                         item={article}
//   //                         onSelect={this.props.onSelectEquivalence}
//   //                         isModal={this.props.isModal}
//   //                         infosTecdoc={this.props.infosTecdoc}
//   //                         infosCatalogF1={this.props.infosCatalogF1}
//   //                       />
//   //                     </Col>
//   //                   </Row>
//   //                 </>
//   //               )}

//   //               {!this.props.item.known && (
//   //                 <Row style={{ color: "#faad14" }}>
//   //                   <Col flex={"50px"}>
//   //                     <FontAwesomeIcon
//   //                       icon={faFrownOpen}
//   //                       style={{ fontSize: 40 }}
//   //                     />
//   //                   </Col>
//   //                   <Col style={{ paddingTop: 8 }}>
//   //                     <Text type="warning">Article non trouvé.</Text>
//   //                   </Col>
//   //                 </Row>
//   //               )}
//   //             </Card>
//   //           )}
//   //         </BasketContext.Consumer>
//   //       )}
//   //     </MarketParameterContext.Consumer>
//   //   );
//   // }

//   // private onSelectedArticle = (articleSearchResult: IArticleSearchResult) => {
//   //   this.props.setSelectedArticle(articleSearchResult.article);
//   // };
// }

// export default withBasket(ArticleCard);
// class ArticleCard extends React.Component<
//   {
//     item: IArticleSearchResult;
//     onChangeSelect: any;
//     onChangeQuantity: any;
//     onSelectEquivalence: any;
//     setArticleView: any;
//     setSelectedArticle: any;
//     isModal: boolean;
//     infosTecdoc: boolean;
//     infosCatalogF1: boolean;
//     openModalAccount: () => void;
//   },
//   {}
// > {
//   private articleUtils: ArticleUtils = new ArticleUtils();
//   private customerId = sessionStorage.getItem("customerId");

//   private title = (
//     articleSearchResult: IArticleSearchResult,
//     userRole: string,
//     showManufacturer: boolean
//   ) => {
//     const { article } = articleSearchResult;
//     return (
//       <div>
//         <Row>
//           {userRole !== "ROLE_GUEST" && (
//             <Col flex="40px">
//               {articleSearchResult.bookmarked && (
//                 <Tooltip
//                   title="Cet article est dans vos favoris"
//                   placement="right"
//                 >
//                   <StarOutlined
//                     style={{
//                       fontSize: 16,
//                       fontWeight: 500,
//                       color: "#1890ff",
//                       marginTop: 10,
//                     }}
//                   />
//                 </Tooltip>
//               )}
//               {!articleSearchResult.bookmarked && (
//                 <Tooltip
//                   title="Ajouter cet article au favoris"
//                   placement="right"
//                 >
//                   <Button
//                     style={{ paddingLeft: 0, paddingRight: 0 }}
//                     onClick={() =>
//                       this.articleUtils.onAddBookmark(this, articleSearchResult)
//                     }
//                     type="link"
//                   >
//                     <StarOutlined
//                       style={{
//                         fontSize: 16,
//                         fontWeight: 500,
//                         color: "#BFBFBF",
//                       }}
//                     />
//                   </Button>
//                 </Tooltip>
//               )}
//             </Col>
//           )}
//           <Col flex="auto">
//             <Button
//               style={{
//                 fontSize: 16,
//                 fontWeight: 500,
//                 paddingLeft: 0,
//                 paddingRight: 0,
//                 paddingTop: 0,
//               }}
//               type="link"
//               onClick={() => this.onSelectedArticle(articleSearchResult)}
//             >
//               {article.reference} : {article.name}
//             </Button>
//           </Col>
//           <Col flex="70px">
//             {articleSearchResult.quantityInWishlists > 0 && (
//               <Tooltip
//                 title={`${articleSearchResult.quantityInWishlists} dans le panier`}
//                 placement="left"
//               >
//                 <Tag color="orange">
//                   {articleSearchResult.quantityInWishlists}
//                   <ShoppingCartOutlined />
//                 </Tag>
//               </Tooltip>
//             )}
//           </Col>
//           <Col flex="100px" style={{ textAlign: "right" }}>
//             {showManufacturer && (
//               <Image
//                 src={
//                   "/api/market/manufacturers/" +
//                   article.manufacturer.id +
//                   "/logo?customer=" +
//                   this.customerId
//                 }
//                 key={article.reference}
//                 alt={article.reference}
//                 style={{ maxWidth: 100, maxHeight: 30 }}
//               />
//             )}
//           </Col>
//         </Row>
//       </div>
//     );
//   };

//   render() {
//     const { article } = this.props.item;
//     return (
//       <MarketParameterContext.Consumer>
//         {(parameters) => (
//           <BasketContext.Consumer>
//             {(basket) => (
//               <Card
//                 title={this.title(
//                   this.props.item,
//                   basket.userRole,
//                   parameters.SHOW_MANUFACTURER
//                 )}
//                 headStyle={{
//                   paddingLeft: 10,
//                   paddingRight: 10,
//                   backgroundColor: "#FAFAFA",
//                 }}
//               >
//                 {this.props.item.known && (
//                   // this.props.item.article.availability &&
//                   <>
//                     <Row>
//                       <Col flex="130px">
//                         <ArticleImage item={article} />
//                       </Col>
//                       <Col flex="550px">
//                         <ArticleAvailability
//                           item={article}
//                           loading={article.loading}
//                           showAvailability={false}
//                           showManufacturer={parameters.SHOW_MANUFACTURER}
//                           currentSupplierAvailabilityStatus={
//                             parameters.SUPPLIER_AVAILABILITY_STATUS
//                           }
//                           currentSupplierAvailabilityLabel={
//                             parameters.SUPPLIER_AVAILABILITY_LABEL
//                           }
//                           currentSupplierAvailabilityWarningEnabled={
//                             parameters.SUPPLIER_AVAILABILITY_WARNING_ENABLED
//                           }
//                           openModalAccount={this.props.openModalAccount}
//                         />
//                       </Col>
//                       <Col flex="auto">
//                         <ArticleWishlistForm
//                           item={article}
//                           currentSupplierAvailabilityStatus={
//                             parameters.SUPPLIER_AVAILABILITY_STATUS
//                           }
//                           onSelectPlatform={(value: any) =>
//                             this.props.onChangeSelect(value, article)
//                           }
//                           onChangeQuantity={this.props.onChangeQuantity}
//                         />
//                         <br />
//                         <Col xl={24} xxl={12}>
//                           <MoreAvailabilities
//                             item={article}
//                             // loading={article.loading}
//                           />
//                         </Col>
//                       </Col>
//                       <Col flex="140px">
//                         {basket.userRole !== "ROLE_GUEST" && (
//                           <ArticlePrice
//                             item={article}
//                             loading={article.loading}
//                           />
//                         )}
//                       </Col>
//                     </Row>
//                     <Row style={{ marginTop: 15 }}>
//                       <Col span={24}>
//                         <ArticleEquivalence
//                           item={article}
//                           onSelect={this.props.onSelectEquivalence}
//                           isModal={this.props.isModal}
//                           infosTecdoc={this.props.infosTecdoc}
//                           infosCatalogF1={this.props.infosCatalogF1}
//                         />
//                       </Col>
//                     </Row>
//                   </>
//                 )}

//                 {!this.props.item.known && (
//                   <Row style={{ color: "#faad14" }}>
//                     <Col flex={"50px"}>
//                       <FontAwesomeIcon
//                         icon={faFrownOpen}
//                         style={{ fontSize: 40 }}
//                       />
//                     </Col>
//                     <Col style={{ paddingTop: 8 }}>
//                       <Text type="warning">Article non trouvé.</Text>
//                     </Col>
//                   </Row>
//                 )}
//               </Card>
//             )}
//           </BasketContext.Consumer>
//         )}
//       </MarketParameterContext.Consumer>
//     );
//   }

//   private onSelectedArticle = (articleSearchResult: IArticleSearchResult) => {
//     this.props.setSelectedArticle(articleSearchResult.article);
//   };
// }

// export default withBasket(ArticleCard);
