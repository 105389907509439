import { Skeleton, Typography } from "antd";

import IArticle from "../IArticle";
import IAvailability from "../IAvailability";
import { MD5 } from "object-hash";
import parse from "html-react-parser";
import { BasketContext } from "../../../Utils/BasketProvider";
import {
  formatJsonDate,
  getAvailabilityText,
} from "../../../Utils/FlybyUtilsFunctional";
import IMarketArticleQuantitative, {
  ArticleCustomerPlatformQuantitativeCacheApplicationTypeEnum,
  ArticleCustomerPlatformQuantitativeCacheTypeEnum,
} from "../IMarketArticleQuantitative";

type MoreAvailabilitiesProps = {
  item: IArticle;
  loading: boolean;
};

const MoreAvailabilities = (props: MoreAvailabilitiesProps): JSX.Element => {
  const { item, loading } = props;
  const { Text } = Typography;
  let currentQuantity = 0;

  const formatQuantitativesLabelDiscountPercent = (
    quantitative: IMarketArticleQuantitative
  ): string => {
    switch (quantitative.applicationType) {
      case ArticleCustomerPlatformQuantitativeCacheApplicationTypeEnum.ADDITIONAL:
        return `<b>Remise additionnelle</b> de <b>${quantitative.discount}%</b> à partir de ${quantitative.quantity}`;
      case ArticleCustomerPlatformQuantitativeCacheApplicationTypeEnum.SUBSTITUTE:
        return `Remise de <b>${quantitative.discount}% se substituant</b> à partir de ${quantitative.quantity}`;
      default:
        return `La remise est de <b>${quantitative.discount}%</b> à partir de ${quantitative.quantity}`;
    }
  };

  const formatQuantitativesLabelDiscountValue = (
    quantitative: IMarketArticleQuantitative
  ): string => {
    switch (quantitative.applicationType) {
      case ArticleCustomerPlatformQuantitativeCacheApplicationTypeEnum.ADDITIONAL:
        return `<b>Remise additionnelle</b> de <b>${quantitative.discount}€</b> à partir de ${quantitative.quantity}`;
      case ArticleCustomerPlatformQuantitativeCacheApplicationTypeEnum.SUBSTITUTE:
        return `Prix unitaire de <b>${quantitative.discount}€ se substituant</b> à partir de ${quantitative.quantity}`;
      default:
        return `Le prix unitaire est de <b>${quantitative.price}€</b> à partir de ${quantitative.quantity}`;
    }
  };

  const formatQuantitativesLabelNetPrice = (
    quantitative: IMarketArticleQuantitative
  ): string => {
    switch (quantitative.applicationType) {
      // case ArticleCustomerPlatformQuantitativeCacheApplicationTypeEnum.ADDITIONAL:
      //   return `<b>Remise additionnelle</b> de <b>${quantitative.discount}%</b> à partir de ${quantitative.quantity}`;
      case ArticleCustomerPlatformQuantitativeCacheApplicationTypeEnum.SUBSTITUTE:
        return `Prix net de <b>${quantitative.discount}% se substituant</b> à partir de ${quantitative.quantity}`;
      default:
        return `Prix net de <b>${quantitative.price}€</b> à partir de ${quantitative.quantity}`;
    }
  };

  const formatQuantitativesLabelFreeArticle = (
    quantitative: IMarketArticleQuantitative
  ): string => {
    return `<b>Gratuit : ${quantitative.freeQuantity} ${quantitative.freeArticleDesignation}</b> à partir de ${quantitative.quantity}`;
  };

  const formatQuantitativesLabel = (
    quantitative: IMarketArticleQuantitative
  ): string => {
    let lineBreak = false;
    if (currentQuantity !== 0 && currentQuantity !== quantitative.quantity) {
      lineBreak = true;
    }
    currentQuantity = quantitative.quantity ? quantitative.quantity : 0;
    switch (quantitative.type) {
      case ArticleCustomerPlatformQuantitativeCacheTypeEnum.DISCOUNT_PERCENT:
        return lineBreak
          ? `<div style="margin-top: 5px; margin-bottom: 5px; height: 1px; backgroundColor:#d0d0d0"></div> ${formatQuantitativesLabelDiscountPercent(
              quantitative
            )}`
          : formatQuantitativesLabelDiscountPercent(quantitative);
      case ArticleCustomerPlatformQuantitativeCacheTypeEnum.DISCOUNT_VALUE:
        return lineBreak
          ? `<div style="margin-top: 5px; margin-bottom: 5px; height: 1px; backgroundColor:#d0d0d0"></div> ${formatQuantitativesLabelDiscountValue(
              quantitative
            )}`
          : formatQuantitativesLabelDiscountValue(quantitative);
      case ArticleCustomerPlatformQuantitativeCacheTypeEnum.NET_PRICE:
        return lineBreak
          ? `<div style="margin-top: 5px; margin-bottom: 5px; height: 1px; backgroundColor:#d0d0d0"></div> ${formatQuantitativesLabelNetPrice(
              quantitative
            )}`
          : formatQuantitativesLabelNetPrice(quantitative);
      case ArticleCustomerPlatformQuantitativeCacheTypeEnum.FREE:
        return lineBreak
          ? `<div style="margin-top: 5px; margin-bottom: 5px; height: 1px; backgroundColor:#d0d0d0"></div> ${formatQuantitativesLabelFreeArticle(
              quantitative
            )}`
          : formatQuantitativesLabelFreeArticle(quantitative);
    }

    return "";
  };

  return (
    <BasketContext.Consumer>
      {(basket) => (
        <Skeleton loading={loading} active>
          {basket.userRole !== "ROLE_GUEST" && (
            <>
              {item.availability !== undefined &&
                !item.moreAvailabilitiesShow &&
                item.availability.comment &&
                item.availability.comment.split("\n").map((line: string) => (
                  <span key={MD5(line)}>
                    {parse(line)}
                    <br />
                  </span>
                ))}
              {item.availability !== undefined &&
                !item.moreAvailabilitiesShow &&
                item.availability.quantitatives &&
                item.availability.quantitatives.length > 0 && (
                  <div
                    style={{
                      border: "1px solid #d0d0d0",
                      backgroundColor: "#f8f8f8",
                      padding: "10px",
                      width: "90%",
                      minHeight: "100px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        color: "#1890ff",
                        fontWeight: "bold",
                        fontSize: "1.25em",
                      }}
                    >
                      Conditions
                    </div>
                    {item.availability.quantitatives.length > 0 &&
                      item.availability.quantitatives.map(
                        (currentQuantitative) => (
                          <span key={MD5(currentQuantitative)}>
                            {parse(
                              formatQuantitativesLabel(currentQuantitative)
                            )}
                            <br />
                          </span>
                        )
                      )}
                  </div>
                )}

              {item.availability !== undefined &&
                item.moreAvailabilitiesShow && (
                  <>
                    {item.moreAvailabilities !== undefined &&
                      item.moreAvailabilities.length > 0 && (
                        <span>
                          <Text type="warning">Autres disponibilités :</Text>
                          <br />
                        </span>
                      )}

                    {item.moreAvailabilities !== undefined &&
                      item.moreAvailabilities.map(
                        (moreAvailability: IAvailability) => (
                          <span key={moreAvailability.platform.id}>
                            {moreAvailability.platform.name}
                            {moreAvailability.deliveredAt && (
                              <>
                                {" "}
                                :{" "}
                                {getAvailabilityText(
                                  moreAvailability.state,
                                  moreAvailability.deliveredAt,
                                  moreAvailability.quantityBackorder,
                                  moreAvailability.longerAvailabilityAlert,
                                  undefined
                                )}{" "}
                                le{" "}
                                {formatJsonDate(moreAvailability.deliveredAt)}
                              </>
                            )}
                            <br />
                          </span>
                        )
                      )}
                  </>
                )}
            </>
          )}
        </Skeleton>
      )}
    </BasketContext.Consumer>
  );
};

export default MoreAvailabilities;
