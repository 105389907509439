import IArticle from "../home/IArticle";

export const search = async (
  query: string,
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch(
    "/api/market/bookmarks?size=" +
      size +
      "&page=" +
      page +
      "&query=" +
      query +
      "&sort=" +
      sort +
      "&customer=" +
      customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const addArticleBookmark = async (
  article: IArticle,
  customerId: number
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");

  let data = {
    article: {
      id: article.id,
    },
    customer: {
      id: customerId,
    },
  };

  return fetch("/api/market/bookmarks?customer=" + customerId, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const deleteArticleBookmark = async (
  bookmarkId: number
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch(
    "/api/market/bookmarks/" + bookmarkId + "?customer=" + customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "DELETE",
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};
