import React from "react";
import "../../../App.css";
import { Card, List, Button } from "antd";

import { withBasket, BasketContext } from "../../../Utils/BasketProvider";
import ICatalogSearch from "../model/ICatalogSearch";
import CatalogService from "../CatalogService";
import IFamily from "../model/IFamily";
class CatalogVehicleTypeFamilySearch extends React.Component<
  {
    catalogSearch: ICatalogSearch;
    setCatalogSearch: any;
  },
  {
    dataLoading: boolean;
    data: IFamily[] | undefined;
  }
> {
  private catalogService: CatalogService = new CatalogService();

  constructor(props: { catalogSearch: ICatalogSearch; setCatalogSearch: any }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: undefined,
    };
  }

  componentDidMount() {
    if (
      this.props.catalogSearch.queryType === "vehicleNode" &&
      this.props.catalogSearch.level3Id !== undefined &&
      this.props.catalogSearch.level4Id !== undefined
    ) {
      this.loadFamilyByVehicleType(
        this.props.catalogSearch.level4Id,
        this.props.catalogSearch.level3Id,
        this.props.catalogSearch.modelYear,
        200,
        0
      );
    }
  }

  componentWillReceiveProps(newProps: {
    catalogSearch: ICatalogSearch;
    setCatalogSearch: any;
  }) {
    if (
      newProps.catalogSearch.queryType === "vehicleNode" &&
      newProps.catalogSearch.level3Id !== undefined &&
      newProps.catalogSearch.level4Id !== undefined &&
      (newProps.catalogSearch.level3Id !== this.props.catalogSearch.level3Id ||
        newProps.catalogSearch.level4Id !== this.props.catalogSearch.level4Id)
    ) {
      this.loadFamilyByVehicleType(
        newProps.catalogSearch.level4Id,
        newProps.catalogSearch.level3Id,
        newProps.catalogSearch.modelYear,
        200,
        0
      );
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) =>
          contextValues.catalogSearch.queryType === "vehicleNode" &&
          contextValues.catalogSearch.level4Id !== undefined && (
            <Card
              size="small"
              title={contextValues.catalogSearch.level4Name}
              // style={{ marginTop: 5 }}
              bordered={true}
              headStyle={{ background: "rgb(236, 236, 236)" }}
            >
              <div
                style={{ marginLeft: -10, marginRight: -10, marginBottom: -5 }}
              >
                <List
                  size="small"
                  split={false}
                  dataSource={this.state.data}
                  loading={this.state.dataLoading}
                  renderItem={(children: IFamily) => (
                    <List.Item
                      style={{ border: 0, paddingTop: 4, paddingBottom: 4 }}
                    >
                      <Button
                        onClick={() =>
                          this.onSearch(
                            contextValues,
                            // contextValues.catalogSearch.queryType,
                            children
                          )
                        }
                        style={{
                          paddingLeft: 4,
                          textAlign: "left",
                          width: "100%",
                          whiteSpace: "initial",
                          height: "fit-content",
                        }}
                        type="link"
                      >
                        {children.name}
                      </Button>
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          )
        }
      </BasketContext.Consumer>
    );
  }

  private onSearch = (
    contextValues: any,
    // queryType: string,
    children: IFamily
  ) => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level5Id: children.id,
      level5Name: children.name,
    };
    contextValues.setSelectedArticle(undefined);
    this.props.setCatalogSearch(catalogSearch);
  };

  private loadFamilyByVehicleType = (
    parentId: number,
    vehiculeTypeId: number,
    modelYear: number | undefined,
    pageSize: number | undefined,
    page: number | undefined
  ) => {
    this.setState({
      dataLoading: true,
    });

    this.catalogService
      .findArticleFamilyByVehicleType(
        parentId,
        1,
        vehiculeTypeId,
        modelYear,
        pageSize,
        page,
        "name"
      )
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            data: result.content,
            dataLoading: false,
          });
        } else {
          this.setState({
            dataLoading: false,
          });
        }
      });
  };
}

export default withBasket(CatalogVehicleTypeFamilySearch);
