import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button, Row, Col, Typography, Card, notification, Progress } from 'antd';

import { withBasket } from '../../Utils/BasketProvider';
import IErrorResponse from '../../Utils/IErrorResponse';
import IErrorResponseDetail from '../../Utils/IErrorResponseDetail';
import NavigationProvider, { NavigationContext, withAppMenu } from '../../Utils/NavigationProvider';
import SalesOrderService from './SalesOrderService';
import FlybyUtils from '../../Utils/FlybyUtils';
import IOrderProgress from './IOrderProgress';

const { Title, Text } = Typography;

class ImportSalesOrder extends React.Component<
  {
    refreshWishlist: any,
    refreshWishlistTotal: any,
    updateMenuKey: any
  },
  {
    listErrors?: IErrorResponse,
    success: string,
    dataLoading: boolean,
    fileList: any,
    percent: number
  }
  >{

  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");
  private salesOrderService: SalesOrderService = new SalesOrderService();
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private runImport: any = null;

  constructor(props: { refreshWishlist: any, refreshWishlistTotal: any, updateMenuKey: any }) {
    super(props);

    this.state = {
      listErrors: undefined,
      success: 'NONE',
      dataLoading: false,
      fileList: [],
      percent: 0
    };
  }

  render() {

    return (
      <div>
        <Title level={4} style={{ marginBottom: 25 }}>Mes commandes / Importer une commande</Title>

        <Row>
          <Col span={12}>

            {this.state.success === 'NONE' &&
              <div>
                <Upload
                  accept={'.csv'}
                  action={'/api/market/orderImport?&customer=' + this.customerId}
                  headers={{ 'Authorization': 'Bearer ' + this.accessToken }}
                  showUploadList={{ showRemoveIcon: false }}
                  onChange={this.onChange}
                  beforeUpload={this.beforeUpload}
                  fileList={this.state.fileList}
                >
                  <Button><UploadOutlined />Cliquez ici pour importer le fichier</Button>
                </Upload>
                <br />

                {this.state.listErrors !== undefined &&
                  <div>
                    <Text type="danger" style={{ marginBottom: 15 }}>Votre fichier de commande comporte des erreurs : {this.state.listErrors.message}</Text><br />
                    <ul>
                      {this.state.listErrors.details !== undefined && this.state.listErrors.details.map((item: IErrorResponseDetail) =>
                        <li key={item.index}><Typography.Text mark>[{item.index}]</Typography.Text> {item.property} {item.description} {item.value}</li>
                      )}
                    </ul>
                  </div>
                }
              </div>
            }

            {this.state.success === 'ERROR' &&
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Text type="danger" style={{ marginBottom: 5, marginTop: 5 }}>Impossible d'importer votre fichier de commande.</Text>
              </div>
            }

            {this.state.success === 'INPROGRESS' &&
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Progress percent={this.state.percent} />
              </div>
            }

            <NavigationProvider>
              <NavigationContext.Consumer>
                {appMenu =>
                  <div>
                    {this.state.success === 'SUCCESS' &&
                      <Text>
                        Votre commande est importée dans votre panier. <br />
                                                Cliquez <Link to="/shopping-cart" onClick={this.onclickShoppingCart}>ici</Link> pour voir votre panier.
                                            </Text>
                    }
                  </div>
                }
              </NavigationContext.Consumer>
            </NavigationProvider>
          </Col>
          <Col span={12}>
            <Card title="Quels champs le fichier de commande doit-il inclure ?" style={{ width: 500, float: "right" }}>
              <Text>Afin de créer un enregistrement de commande par importation, il est obligatoire d’avoir des données dans les colonnes de fichier .csv suivantes :</Text><br />
              <br />
              <Text strong>Code de la plateforme (obligatoire) :</Text><br />ce champ correspond à l'identifiant de la plateforme, <br />liste disponible<Button type='link' onClick={this.onPlatformExport}>ici</Button><br />
              <Text strong>Code TECCOM du fabricant :</Text><br />insérez le numéro le code TECCOM du fabricant, liste disponible<Button type='link' onClick={this.onManufacturerExport}>ici</Button><br />
              <Text strong>Référence de l'article* :</Text><br />indiquez la référence de l'article<br />
              <Text strong>Code EAN de l'article* :</Text><br />indiquez le code EAN de l'article<br />
              <Text strong>Quantité (obligatoire) :</Text><br />fournissez la quantité au format 00.00<br />
              <br />
              <Text type="danger">*Vous devez renseigner soit la référence de l'article ou soit l'EAN.</Text>
            </Card>
          </Col>
        </Row>

      </div>
    );
  }

  private beforeUpload = (file: any, fileList: any): boolean => {
    this.setState({
      fileList: []
    });
    return true;
  }

  private onChange = (result: any) => {
    if (result.file.status === 'uploading') {
      this.setState({
        listErrors: undefined,
        fileList: result.fileList,
        percent: 0,
      });
    }

    if (result.file.status === 'done') {
      this.runImport = setInterval(this.refreshImportOrder, 5000);
      this.setState({
        listErrors: undefined,
        success: 'INPROGRESS',
        percent: 0,
      });
    }
    else if (result.file.status === 'error') {
      this.setState({
        listErrors: result.file.response,
        success: 'NONE',
        percent: 0,
      });
    }
  }

  private refreshImportOrder = () => {
    this.salesOrderService.getOrderProgress()
      .then((status: IOrderProgress) => {
        if(status !== undefined && status.percent !== null){
          let percent: number = status.percent;

          if (status.status === 'FINISHED') {
            this.props.refreshWishlist();
            this.props.refreshWishlistTotal();
            this.setState({
              percent: percent,
              listErrors: undefined,
              success: 'SUCCESS'
            });
  
            clearInterval(this.runImport);
          }
          else if (status.status === 'ERROR'){
            this.setState({
              percent: 0,
              listErrors: undefined,
              success: 'ERROR'
            });
          }
          else {
            this.setState({
              percent: percent,
              listErrors: undefined,
              success: 'INPROGRESS'
            });
          }
        }
      });
  }

  private onclickShoppingCart = () => {
    this.props.updateMenuKey('/shopping-cart');
  }

  private onPlatformExport = () => {

    this.salesOrderService.platformExport()
      .then(blob => {
        this.flybyUtils.showFile(blob, "text/csv", "plateformes.csv");
      })
      .catch((err: any) => {
        notification.error({ message: 'Importer une commande', description: 'Impossible d\'afficher le document.' });

      });
  }

  private onManufacturerExport = () => {

    this.salesOrderService.manufacturerExport()
      .then(blob => {
        this.flybyUtils.showFile(blob, "text/csv", "fabricants.csv");
      })
      .catch((err: any) => {
        notification.error({ message: 'Importer une commande', description: 'Impossible d\'afficher le document.' });
      });
  }
}

export default withAppMenu(withBasket(ImportSalesOrder));