import React from 'react';
import '../../App.css';
import { Row, Col, List, Card } from 'antd';
import { withRouter } from 'react-router-dom';
import { withAppMenu } from '../../Utils/NavigationProvider';
import NotificationService from './NotificationService';
import FlybyUtils from '../../Utils/FlybyUtils';
import INotification from './INotification';
import IData from '../../Utils/IData';
import parse from 'html-react-parser';

import 'react-quill/dist/quill.snow.css';
class CardsNotifications extends React.Component<
  {
    type: string,
  },
  {
    dataLoading: boolean,
    data: IData,
  }>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private notificationService: NotificationService = new NotificationService();

  constructor(props: { type: string, showCatalogF1: boolean }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: this.flybyUtils.getEmptyData(),
    };
  }

  componentDidMount() {
    this.loadData(10, 0);
  }


  render() {

    return (
      <div>
        {this.state.data.content && this.state.data.content.length > 0 &&
            <Row gutter={24} justify="center" style={{
              marginTop: 25, marginBottom: 25,
            }}>
              <Col >
                <List
                  grid={{ gutter: 16, column: 1 }}
                  size={'small'}
                  dataSource={this.state.data.content}
                  renderItem={(item: INotification) => (
                    <List.Item>
                      <Card className="ql-editor" bordered={false}>{parse(item.message)}</Card>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          
        }
      </div>
    );
  }


  private loadData = (pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.notificationService.search(this.props.type, pageSize, page, 'id')
      .then((data: any) => {
        if (data !== undefined) {

          this.setState({
            data: data,
            dataLoading: false,
          });
        }
      });
  }

}

export default withRouter(withAppMenu(CardsNotifications));