import { Button, Modal } from "antd";
import React, { useCallback, useState } from "react";
import FlybyUtils from "../../../Utils/FlybyUtils";
import { useMarketParameter } from "../../../Utils/MarketParameterContext";
import ArticleAvailabilityRequestInformationForm from "./ArticleAvailabilityRequestInformationForm";

interface ArticleAvailabilityRequestInformationProps {
  articleId: number;
  date: any;
}

const ArticleAvailabilityRequestInformation: React.FC<
  ArticleAvailabilityRequestInformationProps
> = (props: { articleId: number; date: any }) => {
  const { articleId, date } = props;

  const flybyUtils: FlybyUtils = new FlybyUtils();

  const [openModal, setOpenModal] = useState(false);
  const { REQUEST_ITEM_AVAILABILITY_MAIL_TO } = useMarketParameter();

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      {(date === null || date === undefined) &&
        REQUEST_ITEM_AVAILABILITY_MAIL_TO === "" && <span>Nous contacter</span>}

      {(date === null || date === undefined) &&
        REQUEST_ITEM_AVAILABILITY_MAIL_TO !== "" && (
          <Button
            type="link"
            style={{ paddingLeft: 0, color: "#cf1322" }}
            onClick={() => setOpenModal(true)}
          >
            Nous contacter
          </Button>
        )}

      {date !== null &&
        date !== undefined &&
        REQUEST_ITEM_AVAILABILITY_MAIL_TO === "" && (
          <span>{flybyUtils.formatJsonDate(date)}</span>
        )}

      <Modal
        open={openModal}
        destroyOnClose
        closable={false}
        title="Demande d'information sur la disponibilité"
        style={{ top: 20 }}
        width={450}
        bodyStyle={{ height: 460 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <ArticleAvailabilityRequestInformationForm
          closeModal={closeModal}
          articleId={articleId}
        />
      </Modal>
    </>
  );
};

export default ArticleAvailabilityRequestInformation;
