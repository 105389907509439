import React from "react";
import "../../App.css";
import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import FlybyUtils from "../../Utils/FlybyUtils";
import DocumentHistoryService from "./DocumentHistoryService";
import IData from "../../Utils/IData";
import { MarketParameterContext } from "../../Utils/MarketParameterContext";
import IDocumentHistoryDetail from "./IDocumentHistoryDetail";

class DocumentHistoryCustom extends React.Component<
  {},
  {
    data: IData;
    dataLoading: boolean;
  }
> {
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private documentHistoryService: DocumentHistoryService =
    new DocumentHistoryService();

  private columns: ColumnProps<IDocumentHistoryDetail>[] = [
    {
      title: "Date commande",
      width: 100,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.formatJsonDateDDMMYYYY(record.documentHistory.date)}
        </div>
      ),
      align: "right",
    },
    {
      title: "Numéro commande",
      render: (text: string, record: any) => (
        <div>{record.documentHistory.reference}</div>
      ),
      width: 200,
    },
    {
      title: "Code fournisseur",
      render: (text: string, record: any) => (
        <div>{record.externalManufacturerCode}</div>
      ),
      width: 100,
    },
    {
      title: "Référence article",
      render: (text: string, record: any) => (
        <div>{record.articleReference}</div>
      ),
      width: 100,
    },
    {
      title: "Description",
      render: (text: string, record: any) => <div>{record.description}</div>,
      width: 200,
    },
    {
      title: "Qté RAL",
      width: 80,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.hideZeroValue(
            this.flybyUtils.numberFormat(record.quantityBackordered, 2)
          )}
        </div>
      ),
      align: "right",
    },
    {
      title: "Livraison",
      render: (text: string, record: any) => (
        <div>{record.documentHistory.comment}</div>
      ),
      width: 150,
    },
  ];

  constructor(props: {}) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
    };
  }

  componentDidMount() {
    this.searchDocumentHistory(200, 0);
  }

  render() {
    return (
      <MarketParameterContext.Consumer>
        {(appParms) => (
          <div>
            <h3>{appParms.CUSTOM_DOCUMENTS_HISTORY}</h3>

            <Table
              rowKey="id"
              loading={this.state.dataLoading}
              columns={this.columns}
              dataSource={this.state.data.content}
              onChange={this.onChange}
              pagination={{
                current: this.state.data.number + 1,
                pageSize: this.state.data.size,
                total: this.state.data.totalElements,
              }}
              style={{
                border: "1px solid #e8e8e8",
                borderWidth: "1px 1px 1px 1px",
              }}
              size="middle"
            />
          </div>
        )}
      </MarketParameterContext.Consumer>
    );
  }

  private onChange = (
    pagination: any,
    filters: Record<never, string[]>,
    sorter: any,
    extra: any
  ) => {
    pagination = this.flybyUtils.getCurrentPage(pagination);

    this.searchDocumentHistory(pagination.pageSize, pagination.current);
  };

  private searchDocumentHistory = (
    pageSize: number | undefined,
    current: number | undefined
  ) => {
    if (!this.state.dataLoading) {
      this.setState({
        dataLoading: true,
      });
    }

    this.documentHistoryService
      .getDocumentHistoryDetailsCustom(pageSize, current)
      .then((data: any) => {
        if (data !== undefined) {
          this.setState({
            data: data,
            dataLoading: false,
          });
        }
      });
  };
}

export default DocumentHistoryCustom;
