import { useCallback, useEffect, useState } from "react";
import "../../App.css";
import { Input, Button, Card, Spin, List, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";

import { BasketContext, withBasket } from "../../Utils/BasketProvider";
import IVehicleSearch from "../catalog/model/IVehicleSearch";
import {
  getVehicleSearchHistory,
  searchVehicleByPlateNumberCatdata,
} from "../catalog/CatalogServiceFunctional";
import { EditTwoTone } from "@ant-design/icons";
import ICatalogSearch from "../catalog/model/ICatalogSearch";
import moment from "moment";
import { withRouter } from "react-router-dom";

type CatdataSearchProps = {
  catalogSearch: ICatalogSearch;
  history: any;
  setCatalogSearch: (value: ICatalogSearch) => void;
  setTypeSearch: (typeSearch: number) => void;
  clearCatalogSearch: () => void;
};

const CatdataSearch = (props: CatdataSearchProps): JSX.Element => {
  const {
    catalogSearch,
    history,
    setCatalogSearch,
    setTypeSearch,
    clearCatalogSearch,
  } = props;
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [vehiculeHistory, setVehiculeHistory] = useState<IVehicleSearch[]>([]);

  const Search = Input.Search;

  const onSearchPlateNumber = useCallback(
    async (query: string, queryType: string) => {
      if (query !== undefined && query !== "") {
        setDataLoading(true);
        const vehicleSearch = await searchVehicleByPlateNumberCatdata(
          query.toUpperCase(),
          undefined
        );
        if (vehicleSearch.content && vehicleSearch.content.length === 0) {
          message.error("Aucun véhicule ne correspond à la recherche");
        } else {
          if (vehicleSearch.content.length > 1) {
            message.error(
              "Plusieurs véhicules correspondent à la recherche. Contactez les équipes d'Allmakes"
            );
          } else {
            if (
              vehicleSearch.content.length > 0 &&
              vehicleSearch.content[0] &&
              vehicleSearch.content[0].vehicleType &&
              vehicleSearch.content[0].vehicleType.vehicleNode
            ) {
              clearCatalogSearch();
              let currentCatalogSearch: ICatalogSearch = {
                ...catalogSearch,
                level1Id:
                  vehicleSearch.content[0].vehicleType.vehicleNode.parent.id,
                level1Name:
                  vehicleSearch.content[0].vehicleType.vehicleNode.parent
                    .description,
                level2Id: vehicleSearch.content[0].vehicleType.vehicleNode.id,
                level2Name:
                  vehicleSearch.content[0].vehicleType.vehicleNode.description,
                level3Id: vehicleSearch.content[0].vehicleType.id,
                level3Name: vehicleSearch.content[0].vehicleType.description,
                level4Id: undefined,
                level4Name: undefined,
                level5Id: undefined,
                level5Name: undefined,
                modelYear: moment(
                  vehicleSearch.content[0].vehicleType.startDate,
                  "YYYYMMDD"
                ).year(),
                plateNumber: query.toUpperCase(),
              };
              setCatalogSearch(currentCatalogSearch);
              setTypeSearch(3);
              history.push("/catalog");
            }
          }
        }
        setDataLoading(false);
      }
    },
    [
      catalogSearch,
      clearCatalogSearch,
      history,
      setCatalogSearch,
      setTypeSearch,
    ]
  );

  const getSearchVehicleHistory = useCallback(async () => {
    setDataLoading(true);
    const loadedVehicleSearchHistory = await getVehicleSearchHistory(5);
    if (
      loadedVehicleSearchHistory.content &&
      loadedVehicleSearchHistory.content.length > 0
    ) {
      setVehiculeHistory(loadedVehicleSearchHistory.content);
    }
    setDataLoading(false);
  }, []);

  useEffect(() => {
    getSearchVehicleHistory();
  }, [getSearchVehicleHistory]);

  return (
    <Spin size="large" spinning={dataLoading}>
      <BasketContext.Consumer>
        {(contextValues) => (
          <Card
            bordered={false}
            title={
              <div>
                <FontAwesomeIcon icon={faCar} /> Par immatriculation
              </div>
            }
            size="small"
            bodyStyle={{ minHeight: 100, marginTop: 10 }}
            headStyle={{
              fontWeight: "bold",
              background: "#FFFFFF",
              fontSize: "1.1em",
              color: "#545454",
            }}
          >
            <Search
              size="large"
              placeholder="Immatriculation : XX-123-YY"
              onSearch={(value: any) =>
                onSearchPlateNumber(value, "plateNumber")
              }
              style={{ width: "100%" }}
              enterButton
              defaultValue={
                contextValues.tecdocSearch.query !== undefined
                  ? contextValues.tecdocSearch.query.plateNumber
                  : ""
              }
            />
            <div
              id="scrollableDiv"
              style={{
                height: 200,
                overflow: "auto",
                padding: "0 16px",
                border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
            >
              <List
                size="small"
                dataSource={vehiculeHistory}
                renderItem={(item) => (
                  <List.Item
                    extra={[
                      <Button
                        type="link"
                        key={item.plateNumber}
                        icon={<EditTwoTone />}
                        onClick={(): Promise<void> =>
                          onSearchPlateNumber(item.plateNumber, "plateNumber")
                        }
                      />,
                    ]}
                  >
                    <List.Item.Meta
                      description={`${item.mark} / ${item.model} / ${item.plateNumber}`}
                    />
                  </List.Item>
                )}
              />
            </div>
          </Card>
        )}
      </BasketContext.Consumer>
    </Spin>
  );
};

export default withRouter(withBasket(CatdataSearch));
