
import React from 'react';
import { Row, Col, Typography, Tag } from 'antd';

import IArticle from '../../../tecdoc/model/IArticle';
import IArticleCriteria from '../../../tecdoc/model/IArticleCriteria';
import ILinkageAttribute from '../../model/ILinkageAttribute';
import ITextModule from '../../model/ITextModule';
import TecdocUtils from '../../TecdocUtils';

const { Text } = Typography;

class ArticleCriteria extends React.Component<
  {
    tecdocArticle: IArticle | undefined
  },
  {
  }>{

  private tecdocUtils: TecdocUtils = new TecdocUtils();

  render() {
    return (

      <>
        {this.props.tecdocArticle === undefined &&
          <div><Text type="secondary">Nous n'avons pas trouvé de donnée technique.</Text></div>
        }

        {this.props.tecdocArticle !== undefined &&
          <Row>
            <Col span={12} >
              {this.props.tecdocArticle.supplier && this.props.tecdocArticle.supplier.bez &&
                <><Text strong>Fabricant : </Text>{this.props.tecdocArticle.supplier.bez}<br /></>
              }
              <Text strong>Référence : </Text>{this.props.tecdocArticle.artNr}<br />

              {this.sortBySortNr(this.props.tecdocArticle.articleCriteria).map((criteria: IArticleCriteria) =>
                <div key={criteria.kritNr + '' + criteria.sortNr}>
                  {criteria.typ !== 'K' && criteria.typ !== 'V' &&
                    <span><Text strong>{criteria.bezCriteriaAbk} : </Text> {criteria.kritWert} {criteria.bezCriteriaEinheit}<br /></span>
                  }
                </div>
              )}
              {this.sortBySortNr(this.props.tecdocArticle.linkageAttributes).map((attribute: ILinkageAttribute) =>
                <div key={attribute.kritNr + '' + attribute.sortNr}>
                  {attribute.typ !== 'K' && attribute.typ !== 'V' &&
                    <span><Text strong>{attribute.bezCriteriaAbk} : </Text> {attribute.kritWert} {attribute.bezCriteriaEinheit}<br /></span>
                  }
                  {attribute.typ === 'K' && (attribute.bezKeyEntry != null || attribute.bezCriteriaAbk != null) &&
                    <span><Text strong>{attribute.bezCriteriaAbk} : </Text> {attribute.bezKeyEntry}<br /></span>
                  }
                </div>
              )}
            </Col>
            <Col span={12} >
              {this.props.tecdocArticle.articleCriteria.map((criteria: IArticleCriteria) => criteria.kritNr === 591 &&
                <div key={criteria.kritNr + '' + criteria.sortNr} style={{ marginBottom: 10 }}>
                  <Tag color="#2db7f5">{criteria.bezCriteriaAbk}</Tag><br />
                </div>
              )}

              <Text strong>Référence commerciale : </Text>{this.props.tecdocArticle.gebrNr}<br />
              <Text strong>EAN(s) : </Text>{this.tecdocUtils.getEans(this.props.tecdocArticle.eans)}<br />
              {this.sortBySortNr(this.props.tecdocArticle.articleCriteria).map((criteria: IArticleCriteria) =>
                <div key={criteria.kritNr + '' + criteria.sortNr}>
                  {criteria.typ === 'A' &&
                    <span><Text strong>{criteria.bezCriteriaAbk} : </Text> {criteria.kritWert} {criteria.bezCriteriaEinheit}<br /></span>
                  }
                </div>
              )}

              {this.props.tecdocArticle.textModules !== undefined && this.props.tecdocArticle.textModules.length > 0 &&
                <div style={{ marginTop: 10 }}>
                  {this.props.tecdocArticle.textModules.map((text: ITextModule) =>
                    <div key={Math.random().toString(36).substr(2, 9)}>
                      <span>{text.text}<br /></span>
                    </div>
                  )}
                </div>
              }
            </Col>
          </Row>
        }
      </>
    );
  }

  private sortBySortNr = (data: IArticleCriteria[] | ILinkageAttribute[]): IArticleCriteria[] => {
    return data.sort((a: any, b: any) => { return a.sortNr - b.sortNr });
  }
}

export default ArticleCriteria;