import React from 'react';
import '../../../App.css';
import { Card, List, Radio } from 'antd';

import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import ISearchStructure from '../model/ISearchStructure';
import TecdocService from '../TecdocService';
import ITecdocSearch from '../model/ITecdocSearch';
import { RadioChangeEvent } from 'antd/lib/radio';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  width: 180, textOverflow: "ellipsis"
};

class TecdocSearchStructure extends React.Component<
  {
    tecdocSearch: ITecdocSearch,
    setTecdocSearch: any,
    clearTecdocSearch: any
  },
  {
    treeData: any,
    dataLoading: boolean,
  }>{

  private tecdocService: TecdocService = new TecdocService();

  constructor(props: { tecdocSearch: ITecdocSearch, setTecdocSearch: any, clearTecdocSearch: any }) {
    super(props);

    this.state = {
      treeData: [],
      dataLoading: true,
    };
  }

  componentDidMount() {
    this.loadSearchStructuresCustom(null, 1, 200, 0);
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.ktypNr !== undefined &&
          <Radio.Group onChange={this.onChange} style={{width: '100%'}}>
            <Card>

              <List grid={{ gutter: 8, column: 3 }} dataSource={this.state.treeData} loading={this.state.dataLoading}
                renderItem={(family: ISearchStructure) => (
                  <List.Item >
                    <Card title={<span>{family.bez}</span>}
                      bordered={false} bodyStyle={{ marginTop: 1, padding: 0, height: 200, overflowY: "auto" }}>

                      {family.children.map((subFamily: ISearchStructure) =>
                        <span key={'F' + subFamily.nodeId} >
                          <Radio key={subFamily.nodeId.toString()} style={radioStyle} value={subFamily.nodeId}>{subFamily.bez}</Radio>
                          {subFamily.children.map((subFamily2: ISearchStructure) =>
                            <Radio key={subFamily2.nodeId.toString()} style={radioStyle} value={subFamily2.nodeId}>{subFamily2.bez}</Radio>
                          )}
                        </span>
                      )}

                    </Card>
                  </List.Item>
                )}
              >
              </List>
            </Card>
          </Radio.Group>
        }
      </BasketContext.Consumer>
    );
  }

  private onChange = (e: RadioChangeEvent) => {
    this.tecdocService.findSearchStructureWithRoot(e.target.value)
      .then((values: ISearchStructure) => {
        let tecdocSearch: ITecdocSearch = this.props.tecdocSearch;
        tecdocSearch.searchStructure = values;
        this.props.setTecdocSearch(tecdocSearch);
      });
  }

  private loadSearchStructuresCustom = (nodeParentId: number | null, stufe: number, pageSize: number | undefined, current: number | undefined) => {
    this.setState({
      dataLoading: true,
    });

    this.tecdocService.findSearchStructuresCustom(nodeParentId, stufe, pageSize, current)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            treeData: result.content,
            dataLoading: false
          });
        }

      });
  }
}

export default withBasket(TecdocSearchStructure);     