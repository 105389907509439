import { Contact } from "./Contact";

export const getContacts = async (
  size: number | undefined,
  page: number | undefined,
  sort: string
): Promise<Contact> => {
  const accessToken = sessionStorage.getItem("accessToken");

  return fetch(
    "/api/market/contacts?size=" + size + "&page=" + page + "&sort=" + sort,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};
