
import React from 'react';
import { Skeleton } from 'antd';
import ArticleCriteria from '../tecdoc/component/article/ArticleCriteria';
import TecdocService from '../tecdoc/TecdocService';
import IArticleTecdoc from '../tecdoc/model/IArticle';
import IArticle from './IArticle';
import ManufacturerService from './ManufacturerService';
import IMasterdataSupplier from './IMasterdataSupplier';

class ArticleTecdocCharacteristic extends React.Component<
  {
    item: IArticle
  },
  {
    dataLoading: boolean,
    tecdocArticle: IArticleTecdoc | undefined,
  }>{

  private tecdocService: TecdocService = new TecdocService();
  private manufacturerService: ManufacturerService = new ManufacturerService();

  constructor(props: { item: IArticle }) {
    super(props);

    this.state = {
      dataLoading: true,
      tecdocArticle: undefined,
    };
  }

  componentDidMount() {
    this.loadData(this.props.item);
  }

  componentWillReceiveProps(newProps: { item: IArticle }) {
    if (newProps.item.id !== this.props.item.id) {
      this.loadData(newProps.item);
    }
  }

  render() {
    return (
      <Skeleton loading={this.state.dataLoading} active>
        <ArticleCriteria tecdocArticle={this.state.tecdocArticle} />
      </Skeleton>
    );
  }

  private loadData = (item: IArticle) => {
    this.setState({
      dataLoading: true,
    });

    let findResult = false;

    this.manufacturerService.findManufacturerById(item.manufacturer.id)
      .then((masterDataSuppliers: any) => {

        if (masterDataSuppliers !== undefined) {

          masterDataSuppliers.content.forEach((s: IMasterdataSupplier) => {
            if (!findResult) {
              this.tecdocService.findArticleByCleanReference(item.reference, s.tecdocCode)
                .then((data: any) => {
                  if (data !== undefined) {
                    this.setState({
                      tecdocArticle: data,
                      dataLoading: false,
                    });

                    findResult = true;
                  }
                  else {
                    this.setState({
                      tecdocArticle: undefined,
                      dataLoading: false,
                    });
                  }
                }).catch(() => {
                  this.setState({
                    tecdocArticle: undefined,
                    dataLoading: false,
                  });
                });
            }
          });
        }
        else {
          this.setState({
            tecdocArticle: undefined,
            dataLoading: false,
          });
        }
      }).catch(() => {
        this.setState({
          tecdocArticle: undefined,
          dataLoading: false,
        });
      });
  }
}

export default ArticleTecdocCharacteristic;