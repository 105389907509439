import { PhoneOutlined } from "@ant-design/icons";
import React from "react";
import { useMarketParameter } from "./MarketParameterContext";

const HeaderPhone = (): React.ReactElement => {
  const { PHONE } = useMarketParameter();

  return (
    <>
      {PHONE !== undefined && PHONE !== "" && (
        <div
          style={{
            // float: "right",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#40A9FF",
          }}
        >
          <span>
            <PhoneOutlined /> {PHONE}
          </span>
        </div>
      )}
    </>
  );
};

export default HeaderPhone;
