import React from 'react';
import '../../../App.css';
import Table, { ColumnProps } from 'antd/lib/table';

import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import TecdocService from '../TecdocService';
import IVehicleType from '../model/IVehicleType';
import TecdocUtils from '../TecdocUtils';
import IData from '../../../Utils/IData';
import FlybyUtils from '../../../Utils/FlybyUtils';
import ITecdocSearch from '../model/ITecdocSearch';
import { Button } from 'antd';
import IKeyEntry from '../model/IKeyEntry';
import TecdocVehicleFilter from './TecdocVehicleFilter';


class TecdocVehicle extends React.Component<
  {
    tecdocSearch: ITecdocSearch,
    setTecdocSearch: any
  },
  {
    data: IData
    dataLoading: boolean,
    filters: string
  }>{

  private tecdocUtils: TecdocUtils = new TecdocUtils();
  private tecdocService: TecdocService = new TecdocService();
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private kmodNr: number | undefined;
  private fuelType: IKeyEntry[] | undefined;
  private bodyType: IKeyEntry[] | undefined;

  private columns: ColumnProps<IVehicleType>[] = [
    {
      title: 'KtypNr',
      dataIndex: 'ktypNr',
      width: 80,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text: string, record: IVehicleType) => <Button onClick={() => this.onSelectVehicleType(record)} type={"link"} style={{ margin: 0, padding: 0 }}>{record.bez}</Button>,
    },
    {
      title: 'Depuis le',
      dataIndex: 'bjvon',
      render: (text: string, record: IVehicleType) => <div>{this.flybyUtils.convertNumberToDateMMYYYY(record.bjvon)}</div>,
      width: 120,
      align: "right",
    },
    {
      title: 'Jusqu\'à',
      dataIndex: 'bjbis',
      render: (text: string, record: IVehicleType) => <div>{this.flybyUtils.convertNumberToDateMMYYYY(record.bjbis)}</div>,
      width: 120,
      align: "right",
    },
    {
      title: 'Ch.',
      dataIndex: 'ps',
      width: 80,
      align: "right",
    },
    {
      title: 'Kw',
      dataIndex: 'kw',
      width: 80,
      align: "right",
    },
    {
      title: 'Cc',
      dataIndex: 'ccmTech',
      width: 80,
      align: "right",
    },
    {
      title: 'ABS',
      dataIndex: 'abs',
      width: 80,
      render: (text: string, record: any) => <div>{this.tecdocUtils.getYesNo(record.abs)}</div>,      
    },    
    {
      title: 'Carrosserie',
      dataIndex: 'aufbauArt',
      render: (text: string, record: any) => <div>{this.tecdocUtils.getKeyType(this.bodyType, record.aufbauArt)}</div>,
      width: 120
    },
    {
      title: 'Carburant',
      dataIndex: 'krStoffArt',
      render: (text: string, record: any) => <div>{this.tecdocUtils.getKeyType(this.fuelType, record.krStoffArt)}</div>,
      width: 120
    },
    {
      title: 'Code moteur',
      dataIndex: '',
      render: (text: string, record: any) => <div>{this.tecdocUtils.getMotorCode(record.engineNumberVehicleTypes)}</div>,
      width: 120,
    }    
  ];

  constructor(props: { tecdocSearch: ITecdocSearch, setTecdocSearch: any }) {
    super(props);
    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
      filters: ''
    };
  }

  componentDidMount() {
    let promise1 = this.tecdocService.findKeyEntries(182, 200, 0);
    let promise2 = this.tecdocService.findKeyEntries(86, 200, 0);

    Promise.all([promise1, promise2]).then((result: any) => {
      this.fuelType = result[0].content;
      this.bodyType = result[1].content;
      if (this.props.tecdocSearch.vehicle !== undefined && this.props.tecdocSearch.vehicle.kmodNr !== undefined) {
        this.kmodNr = this.props.tecdocSearch.vehicle.kmodNr;
        this.searchTecdocVehicleType(this.props.tecdocSearch.vehicle.kmodNr, '', this.flybyUtils.getDefaultPageSize(), 0);
      }
    });
  }

  componentWillReceiveProps(newProps: { tecdocSearch: ITecdocSearch, setTecdocSearch: any }) {
    if (newProps.tecdocSearch.vehicle !== undefined && newProps.tecdocSearch.vehicle.kmodNr !== undefined && this.kmodNr !== newProps.tecdocSearch.vehicle.kmodNr) {
      this.kmodNr = newProps.tecdocSearch.vehicle.kmodNr;
      this.searchTecdocVehicleType(newProps.tecdocSearch.vehicle.kmodNr, '', this.flybyUtils.getDefaultPageSize(), 0);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) =>
          <div>
            <TecdocVehicleFilter onFilters={this.onFilters} />
            <Table rowKey="ktypNr" loading={this.state.dataLoading} columns={this.columns} dataSource={this.state.data.content}
              onChange={this.onChange} pagination={{ current: (this.state.data.number + 1), pageSize: this.state.data.size, total: this.state.data.totalElements }}
              style={{ border: '1px solid #e8e8e8', borderWidth: '1px 1px 1px 1px' }} size="middle" />
          </div>
        }
      </BasketContext.Consumer>
    );
  }

  private onSelectVehicleType = (vehicleType: IVehicleType) => {

    let tecdocSearch = this.props.tecdocSearch;
    if (this.props.tecdocSearch.vehicle !== undefined) {

      tecdocSearch = {
        ...tecdocSearch,
        vehicle: {
          herNr: this.props.tecdocSearch.vehicle.herNr,
          herNrDescription: this.props.tecdocSearch.vehicle.herNrDescription,
          kmodNr: this.props.tecdocSearch.vehicle.kmodNr,
          kmodNrDescription: this.props.tecdocSearch.vehicle.kmodNrDescription,
          ktypNr: vehicleType.ktypNr,
          ktypNrDescription: vehicleType.bez
        },
        searchStructure: undefined
      }

      this.props.setTecdocSearch(tecdocSearch);
    }
  }

  private onChange = (pagination: any, filters: Record<never, string[]>, sorter: any, extra: any) => {
    if (this.props.tecdocSearch.vehicle !== undefined && this.props.tecdocSearch.vehicle.kmodNr !== undefined) {
      pagination = this.flybyUtils.getCurrentPage(pagination);
      this.searchTecdocVehicleType(this.props.tecdocSearch.vehicle.kmodNr, this.state.filters, pagination.pageSize, pagination.current);
    }
  }

  private onFilters = (filters: string) => {
    if (this.props.tecdocSearch.vehicle !== undefined && this.props.tecdocSearch.vehicle.kmodNr !== undefined) {
      this.searchTecdocVehicleType(this.props.tecdocSearch.vehicle.kmodNr, filters, this.flybyUtils.getDefaultPageSize(), 0);
    }
  }

  private searchTecdocVehicleType = (kmodNr: number, filters: string, pageSize: number | undefined, current: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.tecdocService.findTecdocVehicleType(kmodNr, filters, pageSize, current)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            data: result,
            dataLoading: false,
            filters: filters
          });
        }
        else {
          this.setState({
            dataLoading: false,
            filters: filters
          });
        }

      });

  }
}

export default withBasket(TecdocVehicle);     