import IAddress from "../shoppingcart/IAddress";

class CustomerService {
  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");

  public async search(
    query: string,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    return fetch(
      "/api/market/customerContacts?size=" +
        size +
        "&page=" +
        page +
        "&query=" +
        query +
        "&sort=" +
        sort +
        "&customer=" +
        this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findAddress(
    type: string,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    return fetch(
      "/api/market/addresses?size=" +
        size +
        "&page=" +
        page +
        "&type=" +
        type +
        "&sort=" +
        sort +
        "&customer=" +
        this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getAddress(addressId: number): Promise<any> {
    return fetch(
      "/api/market/addresses/" + addressId + "?customer=" + this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async updateAddress(
    address: IAddress,
    addressId: number
  ): Promise<any> {
    return fetch(
      "/api/market/addresses/" + addressId + "?customer=" + this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        method: "PUT",
        body: JSON.stringify(address),
      }
    )
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async addAddress(address: IAddress): Promise<any> {
    return fetch("/api/market/addresses?customer=" + this.customerId, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(address),
    })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async deleteAddress(addressId: number): Promise<any> {
    return fetch(
      "/api/market/addresses/" + addressId + "?customer=" + this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        method: "DELETE",
      }
    )
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}

export default CustomerService;
