import React from 'react';
import '../../App.css';
import { withRouter } from 'react-router-dom';
import { withAppMenu } from '../../Utils/NavigationProvider';
import NotificationService from './NotificationService';
import FlybyUtils from '../../Utils/FlybyUtils';
import INotification from './INotification';
import IData from '../../Utils/IData';
import parse from 'html-react-parser';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'react-quill/dist/quill.snow.css';

const settings = {
  dots: true,
  autoplay: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};

class CardsSlider extends React.Component<
  {},
  {
    dataLoading: boolean,
    data: IData
  }>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private notificationService: NotificationService = new NotificationService();

  constructor(props: { articleId: number }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: this.flybyUtils.getEmptyData(),
    };
  }

  componentDidMount() {
    this.loadData(10, 0);
  }

  render() {

    return (
      <>
        {this.state.data.content && this.state.data.content.length > 0 &&
          <div style={{ marginBottom: 40 }}>
            <Slider {...settings} >

              {this.state.data.content && this.state.data.content.map((item: INotification) => (
                <div key={item.id}>
                  <div className="ql-editor">{parse(item.message)}</div>
                </div>
              ))}

            </Slider>
          </div>
        }
      </>
    );
  }

  private loadData = (pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.notificationService.search('HOME_CAROUSEL', pageSize, page, 'id')
      .then((data: any) => {
        if (data !== undefined) {

          this.setState({
            data: data,
            dataLoading: false,
          });
        }
      });
  }

}

export default withRouter(withAppMenu(CardsSlider));