import { MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification } from "antd";
import React, { useCallback, useState } from "react";
import "../../App.css";
import IRequestAccount from "./IRequestAccount";
import RequestAccountService from "./RequestAccountService";

interface OpenAccountProps {
  closeModal: () => void;
}

const OpenAccount: React.FC<OpenAccountProps> = (props: {
  closeModal: () => void;
}) => {
  const { closeModal } = props;

  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    (values: any) => {
      setLoading(true);
      const requestAccountService: RequestAccountService =
        new RequestAccountService();

      let requestAccount: IRequestAccount = {
        ...values,
      };

      requestAccountService.postRequest(requestAccount).then((result: any) => {
        setLoading(false);
        notification.info({
          message: "Ouverture d'un compte",
          description: "Votre demande a bien été prise en compte.",
        });
        closeModal();
      });
    },
    [closeModal]
  );

  return (
    <div>
      <Form onFinish={onFinish} className="login-form">
        <Form.Item
          name="company"
          rules={[
            {
              required: true,
              message: "La raison sociale est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Raison sociale" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Le nom est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Nom" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "L'email est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="address"
          rules={[
            {
              required: true,
              message: "L'adresse est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Adresse" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="zipCode"
          rules={[
            {
              required: true,
              message: "Le code postal est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Code postal" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="city"
          rules={[
            {
              required: true,
              message: "La ville est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Ville" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="country"
          rules={[
            {
              required: true,
              message: "Le pays est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Pays" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Le téléphone est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Téléphone" autoFocus={true} />
        </Form.Item>
        <Form.Item name="mobile">
          <Input placeholder="Mobile" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="siret"
          rules={[
            {
              required: true,
              message: "Le siret est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Siret" autoFocus={true} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            Valider
          </Button>
          <Button
            type="default"
            disabled={loading}
            className="login-form-button"
            style={{ marginTop: 5 }}
            onClick={closeModal}
          >
            Annuler
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default OpenAccount;
