import React from 'react';
import '../App.css';
import SalesOrderHistory from './shoppingcart/SalesOrderHistory';
import DocumentHistory from './document/DocumentHistory';
import Return from './document/Return';
import ReturnRequest from './document/ReturnRequest';


class PageRequest extends React.Component<
  {
    page: string | null
  },
  {

  }>{

  componentDidMount() {

  }

  render() {
    return (
      <div>
        {this.props.page === 'sales-order-history' &&
          <SalesOrderHistory />
        }

        {this.props.page === 'documents-history' &&
          <DocumentHistory />
        }

        {this.props.page === 'returns' &&
          <Return externalRequest={true}/>
        }

        {this.props.page === 'return-request' &&
          <ReturnRequest  externalRequest={true}/>
        }
      </div>
    );
  }
}

export default PageRequest;        