
import React from 'react';
import { Table, Skeleton, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import IArticleLinkage from '../catalog/model/IArticleLinkage';
import FlybyUtils from '../../Utils/FlybyUtils';
import CatalogService from '../catalog/CatalogService';
import IData from '../../Utils/IData';

const { Text } = Typography;

class CatalogArticleLinkageCaracteristic extends React.Component<
  {
    articleId: number
  },
  {
    dataLoading: boolean,
    data: IData
  }>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private catalogService: CatalogService = new CatalogService();

  private columns: ColumnProps<IArticleLinkage>[] = [
    {
      title: 'Type',
      dataIndex: 'manufacturer',
      render: (text: string, record: IArticleLinkage) => <span>{record.vehicleType.vehicleNode.parent.description}</span>,
    },
    {
      title: 'Marque',
      dataIndex: 'model',
      render: (text: string, record: IArticleLinkage) => <span>{record.vehicleType.vehicleNode.description}</span>,
    },
    {
      title: 'Modèle',
      dataIndex: 'type',
      render: (text: string, record: IArticleLinkage) => <span>{record.vehicleType.description}</span>,
    },
  ];

  constructor(props: { articleId: number }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: this.flybyUtils.getEmptyData(),
    };
  }

  componentDidMount() {
    this.loadData(this.props.articleId, 6, 0);
  }

  componentWillReceiveProps(newProps: { articleId: number }) {
    if (newProps.articleId !== this.props.articleId) {
      this.loadData(newProps.articleId, 6, 0);
    }
  }

  render() {
    return (

      <Skeleton loading={this.state.dataLoading} active>
        {(!this.state.data.content || this.state.data.content.length === 0) &&
          <Text type="secondary">Nous n'avons pas trouvé de véhicule compatible.</Text>
        }

        {this.state.data.content && this.state.data.content.length > 0 &&
          <div >
            <Table rowKey="id" columns={this.columns} dataSource={this.state.data.content}
              onChange={this.onChange} pagination={{ current: (this.state.data.number + 1), pageSize: this.state.data.size, total: this.state.data.totalElements }}
              size="small" />
          </div>
        }
      </Skeleton>

    );
  }

  private onChange = (pagination: any, filters: Record<never, string[]>, sorter: any, extra: any) => {
    pagination = this.flybyUtils.getCurrentPage(pagination);
    this.loadData(this.props.articleId, pagination.pageSize, pagination.current);
  }

  private loadData = (articleId: number, pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.catalogService.findVehicleLinkageByArticle(articleId, pageSize, page, 'vehicleType.startDate,asc')
      .then((data: any) => {
        if (data !== undefined) {

          this.setState({
            data: data,
            dataLoading: false,
          });
        }
      });
  }
}

export default CatalogArticleLinkageCaracteristic;