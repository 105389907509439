import ParameterService from "../../Utils/ParameterService";

class LoginService {
  private parameterService: ParameterService = new ParameterService();
  private accessToken = sessionStorage.getItem("accessToken");

  public async login(values: any, setParameter: void): Promise<any> {
    sessionStorage.clear();

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append(
      "Authorization",
      "Basic " + Buffer.from("market:").toString("base64")
    );

    return fetch("/api/oauth/token", {
      headers: headers,
      body:
        "grant_type=password&username=" +
        values.username +
        "&password=" +
        values.password,
      method: "POST",
      cache: "no-cache",
    })
      .then((response: any) => {
        if (response.status === 200) {
          return response.json().then((data: object) => {
            if (data["access_token"] !== null) {
              sessionStorage.setItem("accessToken", data["access_token"]);

              return this.setUserParams(data["access_token"], setParameter);
            }
            return false;
          });
        }
        return false;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async setUserParams(
    accessToken: string,
    setParameter: void
  ): Promise<any> {
    return fetch("/api/market/session", {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return undefined;
        }
      })
      .then((data: any) => {
        if (data !== undefined && data.customers.length > 0) {
          sessionStorage.setItem("customerId", data.customers[0].id);
          sessionStorage.setItem("userId", data.user.id);
          sessionStorage.setItem("gdprAccepted", data.user.gdprAccepted);
          this.session(accessToken).then((sessionData: any) => {
            sessionStorage.setItem(
              "userFirstName",
              sessionData?.user.firstName
            );
            sessionStorage.setItem("userLastName", sessionData?.user.lastName);
            sessionStorage.setItem(
              "customerName",
              sessionData?.customers[0].name
            );
          });

          let platform = data.customerPlatforms.find(
            (x: any) => x.attached === true
          );
          if (platform !== undefined) {
            sessionStorage.setItem("attachedPlatformId", platform.platform.id);
          }

          return this.getGlobalParameters(
            accessToken,
            data.customers[0].id,
            setParameter
          ).then((data: any) => {
            return data;
          });
        } else {
          sessionStorage.clear();
          return false;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private getGlobalParameters = (
    accessToken: string,
    customerId: number,
    setParameter: void
  ): Promise<any> => {
    return this.parameterService
      .findParametersByCustomerId(accessToken, customerId)
      .then((data: any) => {
        sessionStorage.setItem("disableMarket", "false");

        data.forEach((element: any) => {
          if (element.name === "TECDOC_PROFIL_UID") {
            sessionStorage.setItem("tecdocUid", element.value);
          }
          if (element.name === "TECDOC_SHOW_INFOS") {
            sessionStorage.setItem("tecdocShowInfo", element.value);
          }
          if (element.name === "CATALOG_F1") {
            sessionStorage.setItem("catalogF1", element.value);
          }
          if (element.name === "SHOW_PLATFORM_LIST") {
            sessionStorage.setItem("showPlatformList", element.value);
          }
          if (element.name === "MANUFACTURERS_FILTER") {
            sessionStorage.setItem("manufacturersFilter", element.value);
          }
          if (element.name === "DISPLAY_NAME_AND_COMPANY_NAME") {
            sessionStorage.setItem("displayNameAndCompanyName", element.value);
          }
        });

        return true;
      });
  };

  public async session(token?: string): Promise<any> {
    return fetch("/api/market/session", {
      headers: {
        Authorization:
          "Bearer " + (token !== undefined ? token : this.accessToken),
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async acceptGdpr(): Promise<any> {
    let data = {
      gdprAccepted: true,
    };
    return fetch("/api/market/session", {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "PUT",
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async requestResetPassword(
    callbackUrl: string,
    usernameOrEmail: string
  ): Promise<any> {
    return fetch(
      "/api/market/resetPassword?callbackUrl=" +
        callbackUrl +
        "&usernameOrEmail=" +
        usernameOrEmail,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async resetPassword(
    resetPasswordToken: string,
    password: string
  ): Promise<any> {
    let data = {
      resetPasswordToken: resetPasswordToken,
      password: password,
    };

    return fetch("/api/market/resetPassword", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<any> {
    let data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    return fetch("/api/market/changePassword", {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}

export default LoginService;
// class LoginService {
//   private parameterService: ParameterService = new ParameterService();
//   private accessToken = sessionStorage.getItem("accessToken");

//   public async login(values: any, setParameter: void): Promise<any> {
//     sessionStorage.clear();

//     let headers = new Headers();
//     headers.append("Content-Type", "application/x-www-form-urlencoded");
//     headers.append(
//       "Authorization",
//       "Basic " + Buffer.from("market:").toString("base64")
//     );

//     return fetch("/api/oauth/token", {
//       headers: headers,
//       body:
//         "grant_type=password&username=" +
//         values.username +
//         "&password=" +
//         values.password,
//       method: "POST",
//       cache: "no-cache",
//     })
//       .then((response: any) => {
//         if (response.status === 200) {
//           return response.json().then((data: object) => {
//             if (data["access_token"] !== null) {
//               sessionStorage.setItem("accessToken", data["access_token"]);

//               return this.setUserParams(data["access_token"], setParameter);
//             }
//             return false;
//           });
//         }
//         return false;
//       })
//       .catch((err: any) => {
//         console.log(err);
//       });
//   }

//   public async setUserParams(
//     accessToken: string,
//     setParameter: void
//   ): Promise<any> {
//     return fetch("/api/market/session", {
//       headers: {
//         Authorization: "Bearer " + accessToken,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     })
//       .then((response: any) => {
//         if (response.status === 200) {
//           return response.json();
//         } else {
//           return undefined;
//         }
//       })
//       .then((data: any) => {
//         if (data !== undefined && data.customers.length > 0) {
//           sessionStorage.setItem("customerId", data.customers[0].id);
//           sessionStorage.setItem("userId", data.user.id);
//           sessionStorage.setItem("gdprAccepted", data.user.gdprAccepted);

//           let platform = data.customerPlatforms.find(
//             (x: any) => x.attached === true
//           );
//           if (platform !== undefined) {
//             sessionStorage.setItem("attachedPlatformId", platform.platform.id);
//           }

//           return this.getGlobalParameters(
//             accessToken,
//             data.customers[0].id,
//             setParameter
//           ).then((data: any) => {
//             return data;
//           });
//         } else {
//           sessionStorage.clear();
//           return false;
//         }
//       })
//       .catch((err: any) => {
//         console.log(err);
//       });
//   }

//   private getGlobalParameters = (
//     accessToken: string,
//     customerId: number,
//     setParameter: void
//   ): Promise<any> => {
//     return this.parameterService
//       .findParameters(accessToken, customerId)
//       .then((data: any) => {
//         sessionStorage.setItem("disableMarket", "false");

//         data.forEach((element: any) => {
//           if (element.name === "TECDOC_PROFIL_UID") {
//             sessionStorage.setItem("tecdocUid", element.value);
//           }
//           if (element.name === "SHOW_PLATFORM_LIST") {
//             sessionStorage.setItem("showPlatformList", element.value);
//           }
//           if (element.name === "MANUFACTURERS_FILTER") {
//             sessionStorage.setItem("manufacturersFilter", element.value);
//           }
//         });

//         return true;
//       });
//   };

//   public async session(token?: string): Promise<any> {
//     return fetch("/api/market/session", {
//       headers: {
//         Authorization:
//           "Bearer " + (token !== undefined ? token : this.accessToken),
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     })
//       .then((response: any) => {
//         if (response.status === 200) {
//           return response.json();
//         } else {
//           return undefined;
//         }
//       })
//       .catch((err: any) => {
//         console.log(err);
//       });
//   }

//   public async acceptGdpr(): Promise<any> {
//     let data = {
//       gdprAccepted: true,
//     };

//     return fetch("/api/market/session", {
//       headers: {
//         Authorization: "Bearer " + this.accessToken,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//       method: "PUT",
//       body: JSON.stringify(data),
//     })
//       .then((response: any) => {
//         return response;
//       })
//       .catch((err: any) => {
//         console.log(err);
//       });
//   }

//   public async requestResetPassword(
//     callbackUrl: string,
//     usernameOrEmail: string
//   ): Promise<any> {
//     return fetch(
//       "/api/market/resetPassword?callbackUrl=" +
//         callbackUrl +
//         "&usernameOrEmail=" +
//         usernameOrEmail,
//       {
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json;charset=UTF-8",
//         },
//       }
//     )
//       .then((response: any) => {
//         return response.json();
//       })
//       .catch((err: any) => {
//         console.log(err);
//       });
//   }

//   public async resetPassword(
//     resetPasswordToken: string,
//     password: string
//   ): Promise<any> {
//     let data = {
//       resetPasswordToken: resetPasswordToken,
//       password: password,
//     };

//     return fetch("/api/market/resetPassword", {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//       method: "POST",
//       body: JSON.stringify(data),
//     })
//       .then((response: any) => {
//         return response;
//       })
//       .catch((err: any) => {
//         console.log(err);
//       });
//   }

//   public async changePassword(
//     oldPassword: string,
//     newPassword: string
//   ): Promise<any> {
//     let data = {
//       oldPassword: oldPassword,
//       newPassword: newPassword,
//     };

//     return fetch("/api/market/changePassword", {
//       headers: {
//         Authorization: "Bearer " + this.accessToken,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//       method: "POST",
//       body: JSON.stringify(data),
//     })
//       .then((response: any) => {
//         return response;
//       })
//       .catch((err: any) => {
//         console.log(err);
//       });
//   }
// }

// export default LoginService;
