import React from "react";
import { TreeSelect, Select, Typography, Checkbox, Row, Col } from "antd";
import { BasketContext, withBasket } from "../../Utils/BasketProvider";
import CatalogService from "../catalog/CatalogService";
import IFamily from "../catalog/model/IFamily";
import IFilterQuery from "../../Utils/IFilterQuery";
import ManufacturerService from "./ManufacturerService";
import IManufacturer from "./IManufacturer";
import { SelectValue } from "antd/lib/select";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const { Option } = Select;
const { Text } = Typography;

interface ITreeData {
  key: string;
  value: string;
  title: string;
  children: ITreeData[];
}

class AdvancedSearch extends React.Component<
  {
    onSearch: any;
    setFilterQuery: any;
    clearFilterQuery: any;
    filterQuery: IFilterQuery;
    totalRecordFound: number;
  },
  {
    families: ITreeData[];
    manufacturers: IManufacturer[];
  }
> {
  private catalogService: CatalogService = new CatalogService();
  private manufacturerService: ManufacturerService = new ManufacturerService();

  constructor(props: {
    onSearch: any;
    setFilterQuery: any;
    clearFilterQuery: any;
    filterQuery: IFilterQuery;
    totalRecordFound: number;
  }) {
    super(props);

    this.state = {
      families: [],
      manufacturers: [],
    };
  }

  componentDidMount() {
    this.loadFamily(500, 0);
    this.loadManufacturer(500, 0);
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(value) => (
          <Row
            style={{
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 3,
              marginRight: 3,
              padding: 5,
              border: "solid",
              borderColor: "#e8e8e8",
              borderWidth: "1px",
            }}
            gutter={16}
          >
            {this.state.families.length > 0 && (
              <Col flex="250px">
                <div style={{ marginTop: 5 }}>
                  <Text strong>Catégories</Text>
                </div>
                <TreeSelect
                  treeCheckable={true}
                  loading={this.state.families.length === 0}
                  showCheckedStrategy={"SHOW_PARENT"}
                  placeholder={"Catégories"}
                  filterTreeNode={(input: string, treeNode: any) =>
                    this.filterOptionTreeSelect(input, treeNode)
                  }
                  showSearch={true}
                  style={{ width: "100%" }}
                  onChange={(values) =>
                    this.onSelectFamily(values, value.filterQuery)
                  }
                  value={value.filterQuery.families}
                  treeData={this.state.families}
                  allowClear={true}
                />
              </Col>
            )}

            <Col flex="250px">
              <div style={{ marginTop: 5 }}>
                <Text strong>Fabricants</Text>
              </div>
              <Select
                mode="multiple"
                loading={this.state.manufacturers.length === 0}
                style={{ width: "100%" }}
                placeholder="Fabricants"
                filterOption={(input: string, option: any) =>
                  this.filterOptionSelect(input, option)
                }
                optionFilterProp="children"
                onChange={(values) =>
                  this.onSelectManufacturer(values, value.filterQuery)
                }
                value={value.filterQuery.manufacturers}
                allowClear={true}
                autoClearSearchValue={true}
              >
                {this.state.manufacturers.map((manufacturer: IManufacturer) => (
                  <Option
                    key={manufacturer.id}
                    value={manufacturer.id.toString()}
                  >
                    {manufacturer.name}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col flex="auto" style={{ alignSelf: "flex-end" }}>
              <Checkbox
                onChange={(e) => this.onChangeExtended(e, value.filterQuery)}
                checked={value.filterQuery.extended}
              >
                Recherche étendue
              </Checkbox>
              <br />
              <Text type="secondary">
                Référence, équivalences, description ...
              </Text>
            </Col>

            <Col flex="150px" style={{ alignSelf: "flex-end" }}>
              <Text>Nombre de résultats</Text>
              <br />
              <Text>trouvés : {this.props.totalRecordFound}</Text>
            </Col>
          </Row>
        )}
      </BasketContext.Consumer>
    );
  }

  private filterOptionTreeSelect = (input: string, treeNode: any): boolean => {
    if (Array.isArray(treeNode.children) && treeNode.children.length > 0) {
      return (
        treeNode.children[0].title.toLowerCase().indexOf(input.toLowerCase()) >=
        0
      );
    } else {
      return treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  };

  private filterOptionSelect = (input: string, option: any): boolean => {
    if (Array.isArray(option.options)) {
      return (
        option.options[0].children.toLowerCase().indexOf(input.toLowerCase()) >=
        0
      );
    } else {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  };

  private onChangeExtended = (
    e: CheckboxChangeEvent,
    filterQuery: IFilterQuery
  ) => {
    this.props.setFilterQuery({
      ...filterQuery,
      extended: e.target.checked,
    });
  };

  private onSelectManufacturer = (
    value: SelectValue,
    filterQuery: IFilterQuery
  ) => {
    this.props.setFilterQuery({
      ...filterQuery,
      manufacturers: value,
    });
  };

  private onSelectFamily = (value: string[], filterQuery: IFilterQuery) => {
    this.props.setFilterQuery({
      ...filterQuery,
      families: value,
    });
  };

  private loadManufacturer = (
    pageSize: number | undefined,
    page: number | undefined
  ) => {
    this.manufacturerService
      .search(pageSize, page, "name")
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            manufacturers: result.content,
          });
        }
      });
  };

  private loadFamily = (
    pageSize: number | undefined,
    page: number | undefined
  ) => {
    this.catalogService
      .findArticleFamily(undefined, 1, false, pageSize, page, "name")
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            families: this.formatTreeData(result.content),
          });
        }
      });
  };

  private formatTreeData = (data: IFamily[]): ITreeData[] => {
    let treeData: ITreeData[] = [];

    data.forEach((family: IFamily) => {
      if (family.children !== undefined && family.children.length > 0) {
        let node: ITreeData = {
          key: family.id.toString(),
          value: family.id.toString(),
          title: family.name,
          children: this.formatTreeData(family.children),
        };
        treeData.push(node);
      } else {
        let node: ITreeData = {
          key: family.id.toString(),
          value: family.id.toString(),
          title: family.name,
          children: [],
        };
        treeData.push(node);
      }
    });

    return treeData;
  };
}

export default withBasket(AdvancedSearch);
