import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { getPublicHtmlPage } from "../Utils/ParameterServiceFunctional";
import Mail from "./mail/Mail";

type FooterDetailProps = {
  footerDetailPageName: string;
  footerDetailTitle: string;
  footerDetailVisibility: boolean;
  onCancel: () => void;
};

const FooterDetail = (props: FooterDetailProps): JSX.Element => {
  const {
    footerDetailPageName,
    footerDetailTitle,
    footerDetailVisibility,
    onCancel,
  } = props;
  const [htmlPage, setHTMLPage] = useState<string>("");

  useEffect(() => {
    if (footerDetailPageName !== "CONTACTS") {
      getPublicHtmlPage(footerDetailPageName)
        .then((response: string) => {
          setHTMLPage(response);
        })
        .catch((error) => {
          setHTMLPage("");
        });
    }
  }, [footerDetailPageName]);

  return (
    <Modal
      className="Bordered"
      width="50%"
      bodyStyle={{ height: "80vh", overflow: "hidden", borderRadius: "10px" }}
      centered
      // title={footerDetailTitle}
      open={footerDetailVisibility}
      footer={[
        <Button type="primary" key="cancel" onClick={onCancel}>
          Fermer
        </Button>,
      ]}
      onCancel={(): void => {
        onCancel();
      }}
      destroyOnClose
    >
      {footerDetailPageName !== "CONTACTS" && (
        <div style={{ overflow: "hidden" }}>
          <iframe
            className="responsive-iframe"
            srcDoc={htmlPage}
            title={footerDetailTitle}
            width="100%"
            style={{ border: "none" }}
          ></iframe>
        </div>
      )}
      {footerDetailPageName === "CONTACTS" && <Mail />}
    </Modal>
  );
};

export default FooterDetail;
