import React, { ReactText } from 'react';
import '../../../App.css';
import { Tree, Skeleton } from 'antd';

import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import TecdocService from '../TecdocService';
import ISearchStructure from '../model/ISearchStructure';
import ITecdocSearch from '../model/ITecdocSearch';
import FlybyUtils from '../../../Utils/FlybyUtils';


class TecdocTreeSearchStructure extends React.Component<
  {
    tecdocSearch: ITecdocSearch,
    setTecdocSearch: any,
    clearTecdocSearch: any
  },
  {
    treeData: any,
    dataLoading: boolean,
    expandedKeys: string[] | undefined
  }>{

  private tecdocService: TecdocService = new TecdocService();
  private flybyUtils: FlybyUtils = new FlybyUtils();

  constructor(props: { tecdocSearch: ITecdocSearch, setTecdocSearch: any, clearTecdocSearch: any }) {
    super(props);

    this.state = {
      treeData: [],
      dataLoading: true,
      expandedKeys: undefined
    };
  }

  componentDidMount() {
    this.loadSearchStructures(null, 1, false, 200, 0);
  }

  componentWillReceiveProps(newProps: { tecdocSearch: ITecdocSearch, setTecdocSearch: any, clearTecdocSearch: any }) {
    
    if (newProps.tecdocSearch.searchStructure !== undefined) {
      let selectedNodes: string[] = [];
      const { expandedKeys } = this.state;
      if(expandedKeys !== undefined){
        selectedNodes = expandedKeys;
      }
      newProps.tecdocSearch.searchStructure.treeNodes.forEach((nodeId: number) => { selectedNodes.push(nodeId.toString()) });

      this.setState({
        expandedKeys: selectedNodes
      });

    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.ktypNr !== undefined &&
          <div style={{ minHeight: 500, height: 500, overflowX: 'auto', overflowY: 'auto' }}>

            <Skeleton loading={this.state.dataLoading}>
              {!this.state.dataLoading &&
                <Tree key='0' checkable={false} multiple={false} loadData={this.onLoadData}
                  onSelect={(selectedKeys, { selected }) => this.onCheck(selectedKeys, selected)}
                  onExpand={this.onExpand}
                  expandedKeys={this.state.expandedKeys}
                  treeData={this.state.treeData}
                  selectedKeys={contextValues.tecdocSearch.searchStructure !== undefined ? [contextValues.tecdocSearch.searchStructure.nodeId.toString()] : []}>                  
                </Tree>
              }
            </Skeleton>
          </div>
        }
      </BasketContext.Consumer>
    );
  }

  private onExpand = (expandedKeys: any[], info: {node: any, expanded: boolean,  nativeEvent: MouseEvent}) => {

    if(info.expanded){
      expandedKeys.push(info.node.nodeId.toString());
    }
    else{
      expandedKeys.splice( expandedKeys.indexOf(info.node.nodeId.toString()), 1 );
    }

    this.setState({
      expandedKeys: expandedKeys,
    });
    
  }

  private onCheck = (selectedKeys: ReactText[], selected: boolean) => {
    this.flybyUtils.toTop();

    if (selected) {
      this.tecdocService.findSearchStructureWithRoot(Number(selectedKeys[0]))
        .then((values: ISearchStructure) => {
          let tecdocSearch: ITecdocSearch = this.props.tecdocSearch;
          tecdocSearch.searchStructure = values;
          this.props.setTecdocSearch(tecdocSearch);
        });
    }
    else {
      let tecdocSearch: ITecdocSearch = this.props.tecdocSearch;
      tecdocSearch.searchStructure = undefined;
      this.props.setTecdocSearch(tecdocSearch);
    }
  }

  private onLoadData = (treeNode: any) => {
    
    const { treeData } = this.state;    
    
    return this.tecdocService.findSearchStructures(treeNode.nodeId, (treeNode.stufe + 1), false, 200, 0)
      .then((result: any) => {
        if (result !== undefined) {

          let node = this.findById(treeData, treeNode.nodeId);
          if(node !== undefined){
            node.children = this.formatTreeData(result.content);
            
            
            this.setState({
              treeData: [...this.state.treeData],
            });
          }
        }
        return;
      });
  }

  private findById = (tree: ISearchStructure[], nodeId: number): ISearchStructure | undefined => {
    for (let node of tree) {
      if (node.nodeId === nodeId) return node
  
      if (node.children) {
        let desiredNode = this.findById(node.children, nodeId)
        if (desiredNode) return desiredNode
      }
    }
    return undefined;
  }

  private loadSearchStructures = (nodeParentId: number | null, stufe: number, loadChilds: boolean, pageSize: number | undefined, current: number | undefined) => {
    this.setState({
      dataLoading: true,
    });

    this.tecdocService.findSearchStructures(nodeParentId, stufe, loadChilds, pageSize, current)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            treeData: this.formatTreeData(result.content),
            dataLoading: false,
            expandedKeys: undefined
          });
        }
        else {
          this.setState({
            dataLoading: false,
            expandedKeys: undefined
          });
        }

      });
  }

  private formatTreeData = (data: ISearchStructure[]): ISearchStructure[] => {
    data.forEach((searchStructure: ISearchStructure) => {
      searchStructure.key = searchStructure.nodeId.toString();
      searchStructure.title = searchStructure.bez;
      searchStructure.isLeaf = !searchStructure.haveChildren;
    });

    return data;
  }
}

export default withBasket(TecdocTreeSearchStructure);     