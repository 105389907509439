import WishlistService from "../home/WishlistService";
import IWishlist from "../home/IWishlist";
import { Select } from "antd";
import { IShippingMethod } from "./IShippingMethod";

const Option = Select.Option;

class ShippingMethodService {
  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");
  private wishlistService: WishlistService = new WishlistService();

  public async getFavoritesShippingMethods(): Promise<any> {
    return fetch(
      "/api/market/shippingMethods?&customer=" +
        this.customerId +
        "&currentDefault=true",
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getShippingMethods(platformId: number): Promise<any> {
    return fetch(
      "/api/market/shippingMethods?&customer=" +
        this.customerId +
        "&platform=" +
        platformId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getWishlistsShippingMethods(): Promise<any> {
    let listShippingMethods: any = [];

    // Récupère la wishlist du customer
    this.wishlistService.findByCustomerId().then((wishlists: any) => {
      if (wishlists.content !== undefined) {
        // Pour chaque élément de la wishlist
        wishlists.content.forEach((wishlist: IWishlist) => {
          // On récupère les shippingMethods de chaque plateform
          this.getShippingMethods(wishlist.platform.id).then((result: any) => {
            let options: any = [];
            // Pour chaque shippingMethod
            result.content.forEach((shippingMethod: IShippingMethod) => {
              options.push(
                <Option value={shippingMethod.id} key={shippingMethod.id}>
                  {shippingMethod.name}
                </Option>
              );
            });

            listShippingMethods[wishlist.platform.id] = options;
          });
        });
      }
    });

    return listShippingMethods;
  }
}

export default ShippingMethodService;
