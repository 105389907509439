import React from 'react';
import '../../../App.css';
import { Select, Typography, Button } from 'antd';

import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import TecdocService from '../TecdocService';
import IManufacturer from "../model/IManufacturer";
import IVehicleModelSerie from '../model/IVehicleModelSerie';
import FlybyUtils from '../../../Utils/FlybyUtils';
import ITecdocSearch from '../model/ITecdocSearch';

const { Option, OptGroup } = Select;
const { Text } = Typography;
const initSearch: ITecdocSearch = {
  queryType: '',
  query: undefined,
  vehicle: {
    herNr: undefined,
    kmodNr: undefined,
    ktypNr: undefined
  },
  searchStructure: undefined
}


class TecdocModelSearch extends React.Component<
  {
    tecdocSearch: ITecdocSearch,
    setTecdocSearch: any
  },
  {
    dataLoading: boolean,
    manufacturers: IManufacturer[],
    vehicleModelSerie: IVehicleModelSerie[],
    search: ITecdocSearch
  }
  >{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private tecdocService: TecdocService = new TecdocService();
  private herNr: number | undefined;
  private kmodNr: number | undefined;

  constructor(props: { tecdocSearch: ITecdocSearch, setTecdocSearch: any }) {
    super(props);

    this.state = {
      dataLoading: false,
      manufacturers: [],
      vehicleModelSerie: [],
      search: initSearch
    };
  }

  componentDidMount() {
    this.loadTecdocManufactuer();
  }

  componentWillReceiveProps(newProps: { tecdocSearch: ITecdocSearch, setTecdocSearch: any }) {

    if (newProps.tecdocSearch.vehicle === undefined && this.herNr !== undefined) {
      this.herNr = undefined;
      this.kmodNr = undefined;
      this.setState({ search: initSearch });
    }

    if (newProps.tecdocSearch.vehicle !== undefined && newProps.tecdocSearch.vehicle.kmodNr === undefined
      && this.kmodNr !== undefined) {
      this.kmodNr = undefined;
      this.setState({ search: newProps.tecdocSearch });
    }

    if (newProps.tecdocSearch.vehicle !== undefined && newProps.tecdocSearch.vehicle.herNr !== undefined
      && this.herNr !== newProps.tecdocSearch.vehicle.herNr) {
      this.herNr = newProps.tecdocSearch.vehicle.herNr;
      this.loadTecdocVehicleModelSerie(newProps.tecdocSearch.vehicle.herNr, newProps.tecdocSearch.vehicle.herNrDescription);
    }

    if (newProps.tecdocSearch.vehicle !== undefined && newProps.tecdocSearch.vehicle.kmodNr !== undefined
      && this.kmodNr !== newProps.tecdocSearch.vehicle.kmodNr) {
      this.kmodNr = newProps.tecdocSearch.vehicle.kmodNr;
      this.loadTecdocVehicleType(newProps.tecdocSearch.vehicle.kmodNr, newProps.tecdocSearch.vehicle.kmodNrDescription);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) =>

          <div>
            <div style={{ marginTop: 10 }}><Text strong>Recherche par modèle</Text></div>
            <Select placeholder="Marque" style={{ width: "100%", marginTop: 5 }} loading={this.state.dataLoading}
              showSearch={true} optionFilterProp="children"
              value={this.herNr} allowClear={true}
              filterOption={(input: string, option: any) => this.filterOption(input, option)}
              onChange={(value: number, option: any) => this.loadTecdocVehicleModelSerie(value, option)}>
              <OptGroup key='highlight' label={<span style={{ color: '#000000', fontWeight: 'bold' }}>Marques les plus populaires</span>} >
                {this.state.manufacturers.map((manufacturer: IManufacturer) => manufacturer.highlight && (
                  <Option key={manufacturer.herNr + 'highlight'} value={manufacturer.herNr} {...manufacturer} >{manufacturer.bez}</Option>
                ))}
              </OptGroup>
              <OptGroup key='all' label={<span style={{ color: '#000000', fontWeight: 'bold' }}>Toutes les marques</span>}>
                {this.state.manufacturers.map((manufacturer: IManufacturer) => (
                  <Option key={manufacturer.herNr} value={manufacturer.herNr} {...manufacturer} >{manufacturer.bez}</Option>
                ))}
              </OptGroup>
            </Select>
            <Select placeholder="Modèle" style={{ width: "100%", marginTop: 5 }} loading={this.state.dataLoading}
              disabled={this.state.vehicleModelSerie.length === 0}
              value={this.kmodNr} allowClear={true}
              showSearch={true} optionFilterProp="children"
              filterOption={(input: string, option: any) => this.filterOption2(input, option)}
              onChange={(value: number, option: any) => this.loadTecdocVehicleType(value, option)}>
              {this.state.vehicleModelSerie.map((vehicleModelSerie: IVehicleModelSerie) => (
                <Option key={vehicleModelSerie.kmodNr} value={vehicleModelSerie.kmodNr} {...vehicleModelSerie}>
                  {vehicleModelSerie.bez} {this.flybyUtils.convertNumberToDateMMYYYY(vehicleModelSerie.bjvon)} {vehicleModelSerie.bjbis !== undefined ? '-' : ''} {this.flybyUtils.convertNumberToDateMMYYYY(vehicleModelSerie.bjbis)}
                </Option>
              ))}
            </Select>
            <div style={{ marginTop: 5, width: '100%' }}>
              <Button type="primary" disabled={this.state.dataLoading} onClick={this.onSearch} style={{ float: 'right', marginBottom: 5 }}>Rechercher</Button>
            </div>
          </div>
        }
      </BasketContext.Consumer>
    );
  }

  private filterOption = (input: string, option: any): boolean => {
    if (Array.isArray(option.options)) {
      return (
        option.options[0].children
          .toString()
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
    return (
      option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }

  private filterOption2 = (input: string, option: any): boolean => {
    if (Array.isArray(option.children)) {
      return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  private loadTecdocManufactuer = () => {

    this.setState({ dataLoading: true });

    this.tecdocService.findManufacturer(200, undefined, '&sort=bez,asc')
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {

          this.setState({
            manufacturers: result.content,
            vehicleModelSerie: [],
            dataLoading: false
          });
        }
        else {
          this.setState({ dataLoading: false });
        }
      });
  }

  private loadTecdocVehicleModelSerie = (herNr: number, option: any) => {

    this.herNr = herNr;

    if (herNr === undefined) {
      let search = this.state.search;
      search.vehicle = {
        herNr: undefined,
        herNrDescription: undefined,
        kmodNr: undefined,
        kmodNrDescription: undefined,
        ktypNr: undefined,
      }
      search.searchStructure = undefined;

      this.kmodNr = undefined;

      this.setState({
        search: search,
      });      
    }
    else {
      let search = this.state.search;
      search.vehicle = {
        herNr: herNr,
        herNrDescription: option.bez,
        kmodNr: undefined,
        ktypNr: undefined,
      }
      search.searchStructure = undefined;

      this.setState({
        search: search,
        dataLoading: true
      });

      this.tecdocService.findTecdocVehicleModelSerie(herNr, 1000, 0)
        .then((result: any) => {
          if (result !== undefined && result.content !== undefined) {

            this.setState({
              vehicleModelSerie: result.content,
              dataLoading: false
            });

            this.herNr = herNr;
          }
          else {
            this.setState({ dataLoading: false });
          }

        });
    }
  }

  private loadTecdocVehicleType = (kmodNr: number, option: any) => {

    this.kmodNr = kmodNr;

    if (kmodNr === undefined) {
      let search = this.state.search;
      if (search.vehicle !== undefined) {
        search.vehicle.kmodNr = undefined;
        search.vehicle.kmodNrDescription = undefined;
        search.searchStructure = undefined;
        this.setState({
          search: search
        });
      }
    }
    else {
      let search = this.state.search;
      if (search.vehicle !== undefined) {
        search.vehicle.kmodNr = kmodNr;
        search.vehicle.kmodNrDescription = option.bez;
        search.searchStructure = undefined;
        this.setState({
          search: search
        });
      }
    }

  }

  private onSearch = () => {
    this.props.setTecdocSearch(this.state.search);
  }
}

export default withBasket(TecdocModelSearch);     