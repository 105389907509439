/**
 * Composant functional utilisé en doublon
 * d'un composant écrit en classe (WishlistMethodService.tsx)
 */
// import IShippingMethod from "./IShippingMethod";
import { findByCustomerId } from "../home/WishlistServiceFunctional";
import IWishlist from "../home/IWishlist";
import {
  IShippingMethod,
  IShippingMethodsByPlatformId,
} from "./IShippingMethod";
import { Select } from "antd";

const Option = Select.Option;

export const getFavoritesShippingMethods = async (): Promise<any> => {
  const customerId = sessionStorage.getItem("customerId");
  const accessToken = sessionStorage.getItem("accessToken");

  return fetch(
    "/api/market/shippingMethods?&customer=" +
      customerId +
      "&currentDefault=true",
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const getShippingMethods = async (platformId: number): Promise<any> => {
  const customerId = sessionStorage.getItem("customerId");
  const accessToken = sessionStorage.getItem("accessToken");

  return fetch(
    "/api/market/shippingMethods?&customer=" +
      customerId +
      "&platform=" +
      platformId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

// Récupère la wishlist du customer
export const getWishlistsShippingMethods = async (): Promise<
  IShippingMethodsByPlatformId[]
> => {
  let listShippingMethods: IShippingMethodsByPlatformId[] = [];

  // Récupère la wishlist du customer
  findByCustomerId().then((wishlists: any) => {
    if (wishlists.content !== undefined) {
      // Pour chaque élément de la wishlist
      wishlists.content.forEach((wishlist: IWishlist) => {
        // On récupère les shippingMethods de chaque plateform
        getShippingMethods(wishlist.platform.id).then((result: any) => {
          let options: any = [];
          // Pour chaque shippingMethod
          result.content.forEach((shippingMethod: IShippingMethod) => {
            options.push(
              <Option value={shippingMethod.id} key={shippingMethod.id}>
                {shippingMethod.name}
              </Option>
            );
          });

          listShippingMethods[wishlist.platform.id] = options;
        });
      });
    }
  });
  return listShippingMethods;
};
