import IVehicleNode from "./model/IVehicleNode";
import IVehicleType from "./model/IVehicleType";
import IArticleLinkage from "./model/IArticleLinkage";
import IVehicleSearch from "./model/IVehicleSearch";
class CatalogService {
  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");

  public async findArticleFamily(
    parent: number | undefined,
    deep: number | undefined,
    highlight: boolean,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    return fetch(
      "/api/market/families?customer=" +
        this.customerId +
        "&type=ARTICLE" +
        "&parent=" +
        (parent === undefined ? "" : parent) +
        "&deep=" +
        (deep === undefined ? "" : deep) +
        "&highlight=" +
        highlight +
        "&size=" +
        size +
        "&page=" +
        page +
        "&sort=" +
        sort,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleFamilyByVehicleType(
    parent: number | undefined,
    deep: number | undefined,
    vehiculeTypeId: number,
    modelYear: number | undefined,
    size: number | undefined,
    page: number | undefined,
    sort: string,
    query?: string
  ): Promise<any> {
    return fetch(
      "/api/market/vehicleType/" +
        vehiculeTypeId +
        "/families?customer=" +
        this.customerId +
        "&parent=" +
        (parent === undefined ? "" : parent) +
        "&deep=" +
        (deep === undefined ? "" : deep) +
        "&modelYear=" +
        (modelYear === undefined ? "" : modelYear) +
        "&size=" +
        size +
        "&page=" +
        page +
        "&sort=" +
        sort +
        (query ? "&query=" + query : ""),
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findVehicleTypeById(id: number): Promise<IVehicleType> {
    return fetch(
      "/api/market/vehicleType/" +
        id +
        "?customer=" +
        this.customerId +
        "&sort=startDate,asc",
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findVehicleNode(
    query: string | undefined,
    parent: number | undefined,
    deep: number | undefined,
    highlight: boolean,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    let url =
      "/api/market/vehicleNodes?customer=" +
      this.customerId +
      "&parent=" +
      (parent === undefined ? "" : parent) +
      "&deep=" +
      (deep === undefined ? "" : deep) +
      "&highlight=" +
      highlight +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=" +
      sort;

    if (query !== undefined) {
      url += "&query=" + query;
    }

    return fetch(url, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async searchVehicleByPlateNumberCatdata(
    plateNumber: string | undefined,
    vin: string | undefined
  ): Promise<IVehicleSearch> {
    let url = `/api/market/vehicleTypes?customer=${this.customerId}${
      plateNumber ? `&plateNumber=${plateNumber}` : ""
    }${vin ? `&vin=${vin}` : ""}`;

    return fetch(url, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findVehicleNodeById(id: number): Promise<IVehicleNode> {
    return fetch(
      "/api/market/vehicleNodes/" + id + "?customer=" + this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findVehicleTypeByVehiculeNode(
    query: string | undefined,
    vehiculeNodeId: number,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    let url =
      "/api/market/vehicleNodes/" +
      vehiculeNodeId +
      "/vehicleTypes?customer=" +
      this.customerId +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=" +
      sort;

    if (query !== undefined) {
      url += "&query=" + query;
    }

    return fetch(url, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findVehicleLinkageByArticle(
    id: number,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<IArticleLinkage> {
    return fetch(
      "/api/market/article/" +
        id +
        "/articlelinkage?customer=" +
        this.customerId +
        "&size=" +
        size +
        "&page=" +
        page +
        "&sort=" +
        sort,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleCriterionByArticle(
    id: number,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    return fetch(
      "/api/market/article/" +
        id +
        "/articleCriteria?customer=" +
        this.customerId +
        "&size=" +
        size +
        "&page=" +
        page +
        "&sort=" +
        sort,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findCriterionByArticle(
    id: number,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    return fetch(
      "/api/market/article/" +
        id +
        "/criteria?customer=" +
        this.customerId +
        "&size=" +
        size +
        "&page=" +
        page +
        "&sort=" +
        sort,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findDocumentFamily(
    parent: number | undefined,
    deep: number | undefined,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    return fetch(
      "/api/market/families?customer=" +
        this.customerId +
        "&type=DOCUMENT" +
        "&parent=" +
        (parent === undefined ? "" : parent) +
        "&deep=" +
        (deep === undefined ? "" : deep) +
        "&size=" +
        size +
        "&page=" +
        page +
        "&sort=" +
        sort,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}

export default CatalogService;
