import React, { useCallback, useEffect, useState } from "react";
import { Tag, Tabs, Typography, Image, Skeleton } from "antd";

import IArticle from "./IArticle";
import IArticleEquivalence from "./IArticleEquivalence";
import CatalogArticleLinkage from "./CatalogArticleLinkage";
import ArticleDocument from "./ArticleDocument";
import { BasketContext, withBasket } from "../../Utils/BasketProvider";
import ICatalogSearch from "../catalog/model/ICatalogSearch";
import CatalogArticleCriterion from "../catalog/component/CatalogArticleCriterion";
import ArticleTag from "./ArticleTag";
import ArticleTecdocCharacteristic from "./ArticleTecdocCharacteristic";
import { MarketParameterContext } from "../../Utils/MarketParameterContext";
import CatalogArticleLinkageCaracteristic from "./CatalogArticleLinkageCaracteristic";
import { getAvailabilityEquivalenceColor } from "../../Utils/FlybyUtilsFunctional";
import { findValueCustomer } from "../../Utils/ParameterServiceFunctional";
import {
  findArticleDocuments,
  findEquivalences,
} from "./ArticleServiceFunctional";
import {
  IArticleTab,
  defaultArticleTab,
} from "./ArticleEquivalenceUtilsFunctional";

const { TabPane } = Tabs;
const { Text } = Typography;

interface ArticleEquivalenceProps {
  catalogSearch: ICatalogSearch;
  item: IArticle;
  isModal: boolean;
  infosTecdoc: boolean;
  infosCatalogF1: boolean;
  onSelectEquivalence: (articleId: number) => void;
}

const ArticleEquivalence2 = (
  props: ArticleEquivalenceProps
): React.ReactElement => {
  const { item, isModal, infosTecdoc, infosCatalogF1, onSelectEquivalence } =
    props;

  const [currentArticleEquivalenceList, setCurrentArticleEquivalenceList] =
    useState<IArticleEquivalence[]>([]);
  const [showArticleDocument, setShowArticleDocument] =
    useState<boolean>(false);

  const [articleTab, setArticleTab] = useState<IArticleTab>(defaultArticleTab);
  const [dataLoading, setDataloading] = useState<boolean>(false);
  const customerId = sessionStorage.getItem("customerId");

  const loadArticleEquivalences = useCallback(async (articleId: number) => {
    setDataloading(true);
    try {
      const loadedArticleEquivalences = await findEquivalences(
        articleId,
        100,
        0
      );
      if (loadedArticleEquivalences) {
        setCurrentArticleEquivalenceList(loadedArticleEquivalences);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataloading(false);
    }
  }, []);

  const loadArticleDocument = useCallback((articleId: number) => {
    findArticleDocuments(articleId, 1, 0).then((result) => {
      if (result && result.content.length > 0) {
        setShowArticleDocument(true);
      } else {
        setShowArticleDocument(false);
      }
    });
  }, []);

  useEffect(() => {
    findValueCustomer("ARTICLE_TAB").then((value: string) => {
      if (value !== undefined) {
        setArticleTab(JSON.parse(value));
      }
      loadArticleEquivalences(item.id);
      loadArticleDocument(item.id);
    });
  }, [item.id, loadArticleDocument, loadArticleEquivalences]);

  return (
    <MarketParameterContext.Consumer>
      {(parameters) => (
        <BasketContext.Consumer>
          {(contextValues) => (
            <Tabs defaultActiveKey={articleTab.defaultTab}>
              {articleTab.tab1.show && (
                <TabPane tab={articleTab.tab1.name} key={articleTab.tab1.id}>
                  <Skeleton loading={dataLoading} active>
                    {currentArticleEquivalenceList.length > 0 &&
                      currentArticleEquivalenceList.map(
                        (selectedArticleEquivalence: IArticleEquivalence) => (
                          <Tag
                            key={selectedArticleEquivalence.id}
                            onClick={
                              isModal
                                ? () => {}
                                : () =>
                                    onSelectEquivalence(
                                      selectedArticleEquivalence
                                        .equivalentArticle.id
                                    )
                            }
                            style={
                              isModal
                                ? {
                                    marginBottom: 2,
                                    padding: 3,
                                    textAlign: "center",
                                  }
                                : {
                                    marginBottom: 2,
                                    padding: 3,
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }
                            }
                          >
                            <span>
                              <Image
                                src={
                                  "/api/market/manufacturers/" +
                                  selectedArticleEquivalence.equivalentArticle
                                    .manufacturer.id +
                                  "/logo?customer=" +
                                  customerId
                                }
                                alt={
                                  selectedArticleEquivalence.equivalentArticle
                                    .reference
                                }
                                key={
                                  selectedArticleEquivalence.equivalentArticle
                                    .reference
                                }
                                style={{ height: 20 }}
                              />
                              <br />
                            </span>
                            {parameters.SHOW_MANUFACTURER && (
                              <span
                                style={{
                                  color: getAvailabilityEquivalenceColor(
                                    selectedArticleEquivalence.available
                                  ),
                                }}
                              >
                                {selectedArticleEquivalence.equivalentArticle
                                  .manufacturer.name +
                                  " :: " +
                                  selectedArticleEquivalence.equivalentArticle
                                    .reference}
                              </span>
                            )}
                            {!parameters.SHOW_MANUFACTURER && (
                              <span
                                style={{
                                  color: getAvailabilityEquivalenceColor(
                                    selectedArticleEquivalence.available
                                  ),
                                }}
                              >
                                {
                                  selectedArticleEquivalence.equivalentArticle
                                    .reference
                                }
                              </span>
                            )}
                          </Tag>
                        )
                      )}
                    {currentArticleEquivalenceList.length <= 0 && (
                      <Text type="secondary">
                        Nous n'avons pas trouvé d'équivalence.
                      </Text>
                    )}
                  </Skeleton>
                </TabPane>
              )}

              {articleTab.tab2.show && (
                <TabPane tab={articleTab.tab2.name} key={articleTab.tab2.id}>
                  <ArticleTag articleId={item.id} />
                </TabPane>
              )}

              {articleTab.tab3.show && infosTecdoc && (
                <TabPane tab={articleTab.tab3.name} key={articleTab.tab3.id}>
                  <ArticleTecdocCharacteristic item={item} />
                </TabPane>
              )}
              {articleTab.tab4.show &&
                infosCatalogF1 &&
                contextValues.catalogSearch.queryType === "characteristic" &&
                contextValues.userRole !== "ROLE_GUEST" && (
                  <TabPane tab={articleTab.tab4.name} key={articleTab.tab4.id}>
                    <CatalogArticleCriterion articleId={item.id} />
                  </TabPane>
                )}
              {articleTab.tab5.show &&
                infosCatalogF1 &&
                contextValues.catalogSearch.queryType === "characteristic" &&
                contextValues.userRole !== "ROLE_GUEST" && (
                  <TabPane tab={articleTab.tab5.name} key={articleTab.tab5.id}>
                    <CatalogArticleLinkageCaracteristic articleId={item.id} />
                  </TabPane>
                )}
              {articleTab.tab6.show &&
                infosCatalogF1 &&
                contextValues.catalogSearch.queryType !== "characteristic" &&
                contextValues.userRole !== "ROLE_GUEST" && (
                  <TabPane tab={articleTab.tab6.name} key={articleTab.tab6.id}>
                    <CatalogArticleLinkage articleId={item.id} />
                  </TabPane>
                )}
              {articleTab.tab7.show && showArticleDocument && (
                <TabPane tab={articleTab.tab7.name} key={articleTab.tab7.id}>
                  <ArticleDocument articleId={item.id} />
                </TabPane>
              )}
            </Tabs>
          )}
        </BasketContext.Consumer>
      )}
    </MarketParameterContext.Consumer>
  );
};

export default withBasket(ArticleEquivalence2);
