import React from 'react';
import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import { Breadcrumb, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRegistered, faCar, faCarSide, faSearch, faCarBattery, faCogs, faCrosshairs } from '@fortawesome/free-solid-svg-icons';

import ITecdocSearch from '../model/ITecdocSearch';

class TecdocBreadcrumb extends React.Component<
  {
    tecdocSearch: ITecdocSearch,
    clearTecdocSearch: any,
    setTecdocSearch: any
  }, {}>{

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => ((contextValues.tecdocSearch.vehicle === undefined
          && (contextValues.tecdocSearch.queryType === 'motorCode' || contextValues.tecdocSearch.queryType === 'mineType'))
          || contextValues.tecdocSearch.vehicle !== undefined) &&
          <Breadcrumb style={{ marginBottom: 15 }}>

            <Breadcrumb.Item >
              <FontAwesomeIcon icon={faSearch} style={{ marginRight: 5 }} />
              <Button onClick={this.onCancelSearch} type={"link"} style={{ margin: 0, padding: 0 }}>Nouvelle recherche</Button>
            </Breadcrumb.Item>

            {contextValues.tecdocSearch.vehicle === undefined && contextValues.tecdocSearch.queryType === 'motorCode' &&
              <Breadcrumb.Item >
                <FontAwesomeIcon icon={faCogs} style={{ marginRight: 5 }} />
                <span>Recherche par code moteur</span>
              </Breadcrumb.Item>
            }

            {contextValues.tecdocSearch.vehicle === undefined && contextValues.tecdocSearch.queryType === 'mineType' &&
              <Breadcrumb.Item >
                <FontAwesomeIcon icon={faCogs} style={{ marginRight: 5 }} />
                <span>Recherche type mine</span>
              </Breadcrumb.Item>
            }

            {contextValues.tecdocSearch.vehicle === undefined
              && contextValues.tecdocSearch.queryType === 'motorCode' && contextValues.tecdocSearch.query?.motorCode !== '' &&
              <Breadcrumb.Item >
                <FontAwesomeIcon icon={faCrosshairs} style={{ marginRight: 5 }} />
                <span>{contextValues.tecdocSearch.query?.motorCode}</span>
              </Breadcrumb.Item>
            }

            {contextValues.tecdocSearch.vehicle === undefined 
              && contextValues.tecdocSearch.queryType === 'mineType' && contextValues.tecdocSearch.query?.mineType !== '' &&
              <Breadcrumb.Item >
                <FontAwesomeIcon icon={faCrosshairs} style={{ marginRight: 5 }} />
                <span>{contextValues.tecdocSearch.query?.mineType}</span>
              </Breadcrumb.Item>
            }             

            {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.herNr !== undefined &&
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faRegistered} style={{ marginRight: 5 }} />
                <Button onClick={this.onCancelVehicleModelSerie} type={"link"} style={{ margin: 0, padding: 0 }}>{contextValues.tecdocSearch.vehicle.herNrDescription}</Button>
              </Breadcrumb.Item>
            }
            {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.kmodNr !== undefined &&
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faCarSide} style={{ marginRight: 5 }} />
                <Button onClick={this.onCancelVehicleType} type={"link"} style={{ margin: 0, padding: 0 }}>{contextValues.tecdocSearch.vehicle.kmodNrDescription}</Button>
              </Breadcrumb.Item>
            }
            {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.ktypNr !== undefined &&
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faCar} style={{ marginRight: 5 }} />
                <Button onClick={this.onCancelVehicle} type={"link"} style={{ margin: 0, padding: 0 }}>{contextValues.tecdocSearch.vehicle.ktypNrDescription}</Button>
              </Breadcrumb.Item>
            }
            {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.searchStructure !== undefined &&
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faCarBattery} style={{ marginRight: 5 }} />
                <span>{contextValues.tecdocSearch.searchStructure.bez}</span>
              </Breadcrumb.Item>
            }
          </Breadcrumb>

        }
      </BasketContext.Consumer>
    );
  }

  private onCancelVehicle = () => {
    let tecdocSearch = this.props.tecdocSearch;
    if (this.props.tecdocSearch.vehicle !== undefined) {
      tecdocSearch.vehicle = {
        herNr: this.props.tecdocSearch.vehicle.herNr,
        herNrDescription: this.props.tecdocSearch.vehicle.herNrDescription,
        kmodNr: this.props.tecdocSearch.vehicle.kmodNr,
        kmodNrDescription: this.props.tecdocSearch.vehicle.kmodNrDescription,
        ktypNr: this.props.tecdocSearch.vehicle.ktypNr,
        ktypNrDescription: this.props.tecdocSearch.vehicle.ktypNrDescription,
      }
      tecdocSearch.searchStructure = undefined;
      this.props.setTecdocSearch(tecdocSearch);
    }
  }

  private onCancelVehicleType = () => {
    let tecdocSearch = this.props.tecdocSearch;
    if (this.props.tecdocSearch.vehicle !== undefined) {
      tecdocSearch.vehicle = {
        herNr: this.props.tecdocSearch.vehicle.herNr,
        herNrDescription: this.props.tecdocSearch.vehicle.herNrDescription,
        kmodNr: this.props.tecdocSearch.vehicle.kmodNr,
        kmodNrDescription: this.props.tecdocSearch.vehicle.kmodNrDescription,
        ktypNr: undefined,
      }
      tecdocSearch.searchStructure = undefined;
      this.props.setTecdocSearch(tecdocSearch);
    }
  }

  private onCancelVehicleModelSerie = () => {
    let tecdocSearch = this.props.tecdocSearch;
    if (this.props.tecdocSearch.vehicle !== undefined) {
      tecdocSearch.vehicle = {
        herNr: this.props.tecdocSearch.vehicle.herNr,
        herNrDescription: this.props.tecdocSearch.vehicle.herNrDescription,
        kmodNr: undefined,
        ktypNr: undefined,
      }
      tecdocSearch.searchStructure = undefined;
      this.props.setTecdocSearch(tecdocSearch);
    }
  }

  private onCancelSearch = () => {
    this.props.clearTecdocSearch();
  }
}

export default withBasket(TecdocBreadcrumb);     