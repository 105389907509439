
import React from 'react';
import { Skeleton, Typography, Tag } from 'antd';

import ArticleService from './ArticleService';
import IArticleTag from './IArticleTag';

const { Text } = Typography;

class ArticleTag extends React.Component<
  {
    articleId: number
  },
  {
    dataLoading: boolean,
    articleTags: IArticleTag[] | undefined,
  }>{

  private articleService: ArticleService = new ArticleService();

  constructor(props: { articleId: number }) {
    super(props);

    this.state = {
      dataLoading: true,
      articleTags: undefined,
    };
  }

  componentDidMount() {
    this.loadData(this.props.articleId);
  }

  componentWillReceiveProps(newProps: { articleId: number }) {
    if (newProps.articleId !== this.props.articleId) {
      this.loadData(newProps.articleId);
    }
  }


  render() {
    return (

      <Skeleton loading={this.state.dataLoading} active>
        {this.state.articleTags !== undefined && this.state.articleTags.length > 0 &&
          this.state.articleTags.map((articleTag: IArticleTag) =>
            articleTag.type === 'EQUIVALENCE' &&
            <Tag key={articleTag.id} style={{ marginBottom: 2, padding: 3, textAlign: "center" }}>
              {articleTag.value}
            </Tag>
          )}

        {(!this.state.articleTags || this.state.articleTags.length <= 0) &&
          <Text type="secondary">Aucun résultat.</Text>
        }
      </Skeleton>

    );
  }


  private loadData = (articleId: number) => {

    this.setState({
      dataLoading: true,
    });

    this.articleService.findTags(articleId, 'EQUIVALENCE')
      .then((data: any) => {
        if (data !== undefined) {
          this.setState({
            articleTags: data.content,
            dataLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          articleTags: undefined,
          dataLoading: false,
        });
      });
  }
}

export default ArticleTag;