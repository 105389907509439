export  enum PaymentMethod {
  BUSINESS_ACCOUNT = "BUSINESS_ACCOUNT", 
  CREDIT_CARD = "CREDIT_CARD", 
  CHECK = "CHECK", 
  TRANSFER = "TRANSFER"
}

export  enum PaymentState {
  PENDING = "PENDING", 
  ACCEPTED = "ACCEPTED", 
  REJECTED = "REJECTED"
}
