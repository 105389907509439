import { Card, Col, List, Row, Tree } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useMarketParameter } from "../../Utils/MarketParameterContext";
import PdfViewer from "../../Utils/PdfViewer";
import { formatFamily } from "../../Utils/TreeDataUtil";
import CatalogService from "../catalog/CatalogService";
import IFamily from "../catalog/model/IFamily";
import DocumentService from "./DocumentService";
import IDocument from "./IDocument";

const Library = (props: {}): React.ReactElement => {
  const [data, setData] = useState<any>([]);
  const [fetching, setFetching] = useState(false);
  const [pdfFile, setPdfFile] = useState(false);
  const [document, setDocument] = useState<IDocument>();
  const [families, setFamilies] = useState<IFamily[]>();
  const [documentFamilyId, setDocumentFamilyId] = useState<number>(-1);
  const marketParameter = useMarketParameter();

  const loadForm = useCallback(
    async (
      documentFamily: number,
      loadFamilies: boolean,
      highlight: boolean | undefined,
      pageSize: number,
      pageNumber: number
    ): Promise<void> => {
      setFetching(true);
      const catalogService: CatalogService = new CatalogService();
      const documentService: DocumentService = new DocumentService();
      if (loadFamilies) {
        const families = await catalogService.findDocumentFamily(
          undefined,
          5,
          pageSize,
          pageNumber,
          "name,asc"
        );

        const root: any[] = [];
        root.push({
          id: -1,
          name: "Accueil",
          imageName: "",
          children: families.content,
        });

        setFamilies(root);
      }

      let family: any = documentFamily;
      if (documentFamily === -1) {
        family = undefined;
      }

      const documents = await documentService.search(
        undefined,
        undefined,
        undefined,
        undefined,
        highlight,
        family,
        80,
        0,
        "name,asc"
      );

      setDocumentFamilyId(documentFamily);
      setData(documents);
      setFetching(false);
    },
    []
  );

  const onClick = useCallback((document: IDocument): void => {
    if (document.url.toLocaleLowerCase().endsWith(".pdf")) {
      setDocument(document);
      setPdfFile(true);
    } else {
      window.open(document.url);
    }
  }, []);

  const onCloseReport = useCallback((): void => {
    setDocument(undefined);
    setPdfFile(false);
  }, []);

  const onChangeList = useCallback(
    async (pageNumber: number, pageSize?: number): Promise<void> => {
      if (pageSize) {
        loadForm(documentFamilyId, false, undefined, pageSize, pageNumber - 1);
      }
    },
    [loadForm, documentFamilyId]
  );

  const onClickFamily = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>, treeNode: any): void => {
      if (Number(treeNode.key) === -1) {
        loadForm(Number(treeNode.key), false, true, 80, 0);
      } else {
        loadForm(Number(treeNode.key), false, undefined, 80, 0);
      }
    },
    [loadForm]
  );

  useEffect(() => {
    loadForm(-1, true, true, 80, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {marketParameter.DOCUMENT && (
        <div>
          <h3>Documentation</h3>

          <Row style={{ marginTop: 15 }}>
            <Col flex="250px">
              <div style={{ overflowX: "auto" }}>
                {families && families.length > 0 && (
                  <Tree
                    defaultExpandedKeys={["-1"]}
                    selectedKeys={
                      documentFamilyId
                        ? [documentFamilyId.toString()]
                        : undefined
                    }
                    showLine={false}
                    showIcon={true}
                    treeData={formatFamily(families)}
                    onClick={onClickFamily}
                  />
                )}
              </div>
            </Col>
            <Col flex="auto">
              {data.content && data.content.length > 0 && (
                <Row
                  gutter={24}
                  justify="center"
                  style={{
                    marginTop: 25,
                    marginBottom: 25,
                  }}
                >
                  <Col>
                    <List
                      loading={fetching}
                      grid={{ gutter: 16, column: 8 }}
                      size={"small"}
                      dataSource={data.content}
                      pagination={{
                        onChange: onChangeList,
                        current: data.number + 1,
                        pageSize: data.size,
                        total: data.totalElements,
                      }}
                      renderItem={(item: IDocument) => (
                        <List.Item>
                          <Card
                            bordered={false}
                            hoverable
                            bodyStyle={{ padding: 5 }}
                            onClick={() => onClick(item)}
                          >
                            <div style={{ textAlign: "center" }}>
                              {item.thumbnailUrl && (
                                <img
                                  src={item.thumbnailUrl}
                                  alt={item.name}
                                  style={{ height: 120 }}
                                />
                              )}
                              {!item.thumbnailUrl && (
                                <img
                                  src="/api/public/placeholder.jpg"
                                  alt={item.name}
                                  style={{ height: 120 }}
                                />
                              )}
                              <br />
                              {item.name}
                            </div>
                          </Card>
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              )}
              {document && (
                <PdfViewer
                  fileString={"/api/market/documents/" + document.id + "/link"}
                  urlType={true}
                  visible={pdfFile}
                  onClosePrint={onCloseReport}
                />
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Library;
