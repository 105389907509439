import { MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import ArticleService from "../ArticleService";
import IArticle from "../IArticle";
import IArticleAvailabilityRequestInformation from "../IArticleAvailabilityRequestInformation";
import { getSession } from "../../login/LoginServiceFunctional";

const { TextArea } = Input;

interface ArticleAvailabilityRequestInformationFormProps {
  closeModal: () => void;
  articleId: number;
}

const ArticleAvailabilityRequestInformationForm: React.FC<
  ArticleAvailabilityRequestInformationFormProps
> = (props: { closeModal: () => void; articleId: number }) => {
  const { closeModal, articleId } = props;

  const [form] = Form.useForm();
  const accessToken = sessionStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    (values: any) => {
      setLoading(true);
      const articleService: ArticleService = new ArticleService();

      let articleAvailabilityRequestInformation: IArticleAvailabilityRequestInformation =
        {
          ...values,
        };

      articleService
        .postRequest(articleId, articleAvailabilityRequestInformation)
        .then((result: any) => {
          setLoading(false);
          notification.info({
            message: "Demande d'information sur la disponibilité",
            description: "Votre demande a bien été prise en compte.",
          });
          closeModal();
        });
    },
    [articleId, closeModal]
  );

  useEffect(() => {
    if (accessToken !== null) {
      const articleService: ArticleService = new ArticleService();
      getSession(accessToken).then((sessionData: any) => {
        form.setFieldsValue({
          company: sessionData.customers[0].name,
          name:
            sessionData.user.firstName + " " + sessionData.user.lastName
              ? sessionData.user.lastName
              : "",
          email: sessionData.user.email,
          phone: "",
        });
      });

      articleService.findArticleById(articleId).then((article: IArticle) => {
        form.setFieldsValue({
          message:
            "Je recherche la disponibilité pour la référence : " +
            article.manufacturer.name +
            " " +
            article.reference,
        });
      });
    }
  }, [accessToken, form, articleId]);

  return (
    <div>
      <Form onFinish={onFinish} className="login-form" form={form}>
        <Form.Item
          name="company"
          rules={[
            {
              required: true,
              message: "La raison sociale est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Raison sociale" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Le nom est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Nom" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "L'email est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Le téléphone est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input placeholder="Téléphone" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[
            {
              required: true,
              message: "Le message est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <TextArea rows={4} placeholder="Message" autoFocus={true} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            Valider
          </Button>
          <Button
            type="default"
            disabled={loading}
            className="login-form-button"
            style={{ marginTop: 5 }}
            onClick={closeModal}
          >
            Annuler
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ArticleAvailabilityRequestInformationForm;
