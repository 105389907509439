import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Tabs,
  Typography,
} from "antd";
import { Store } from "antd/es/form/interface";
import { MarketParameterContext } from "../../Utils/MarketParameterContext";
import IStep from "./IStep";
import FlybyLayoutForm from "../../Utils/FlybyLayoutForm";
import React, { useCallback, useEffect, useState } from "react";
import IOrderPlatforms from "./IOrderPlatforms";
import IWishlist from "../home/IWishlist";
import ISession from "../myaccount/ISession";
import ISalesOrder from "./ISalesOrder";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  SaleOrderResume,
  ShoppingCartHeader,
  ShoppingCartPlatformArticlesList,
  ShoppingCartPlatformHeader,
} from "./ShoppingCartUtils";
import TabPane from "antd/lib/tabs/TabPane";
import IAvailability from "../home/IAvailability";
import IWishlistArticle from "../home/IWishlistArticles";
import ShippingMethodService from "./ShippingMethodService";
import IPlatform from "../home/IPlatform";
import ArticleService from "../home/ArticleService";
import SuggestedQuantityForm from "../home/SuggestedQuantityForm";
import { getSession } from "../login/LoginServiceFunctional";
import { updateWhishlistService } from "../home/WishlistServiceFunctional";
import { findParameterValueByName } from "../../Utils/ParameterServiceFunctional";
import { IShippingMethod } from "./IShippingMethod";
// import { updateWhishlistS } from "../home/WishlistServiceFuntional";

interface Step1Props {
  stepConf: IStep | undefined;
  values: any;
  onRefreshAvailability: any;
  onChangeQuantity: any;
  onDeleteWishlist: any;
  onSelectAllItems: any;
  showCutoff: boolean;
  deleteArticle: any;
  onSelectItem: any;
  onRefreshWishlist: any;
  onUpdateWhishlist: any;
  addArticle: any;
  onNextStep: any;
  updateSalesOrder: any;
}

const Step1 = ({
  stepConf,
  values,
  onRefreshAvailability,
  onChangeQuantity,
  onDeleteWishlist,
  onSelectAllItems,
  showCutoff,
  deleteArticle,
  onSelectItem,
  onRefreshWishlist,
  onUpdateWhishlist,
  addArticle,
  onNextStep,
  updateSalesOrder,
}: Step1Props): React.ReactElement => {
  const { Text } = Typography;
  const [form] = Form.useForm();

  const shippingMethodService: ShippingMethodService =
    new ShippingMethodService();
  const articleService: ArticleService = new ArticleService();

  const [showSuggestedQuantityFormState, setShowSuggestedQuantityFormState] =
    useState<boolean>(false);
  const [transfertArticleState, setTransfertArticleState] = useState<
    any | undefined
  >(undefined);
  const [selectedWishlistArticleIdState, setSelectedWishlistArticleIdState] =
    useState<any | undefined>(undefined);
  const [updatingState, SetUpdatingState] = useState<boolean>(false);
  const [termsOfSalesState, setTermsOfSalesState] = useState<any | undefined>(
    undefined
  );
  const [timerState, setTimerState] = useState<any | null>(null);

  // Requête de mise à jour de la wishlist
  const updateWhishlisting = useCallback((wishlist: IWishlist) => {
    let wishlistUpdate = {
      id: wishlist.id,
      name: wishlist.name,
      reference: wishlist.reference,
      reference2: wishlist.reference2,
      platform: {
        id: wishlist.platform.id,
      },
      shippingMethod: {
        id: wishlist.shippingMethod.id,
      },
    };
    return updateWhishlistService(wishlistUpdate);
  }, []);

  const updateSaleOrder = useCallback(
    (session: ISession, orderPlatforms: IOrderPlatforms[], value: Store) => {
      return {
        ...values.salesOrder,
        deliveryCompanyName: session.customers[0].name,
        deliveryAddress1: session.customers[0].deliveryAddress1,
        deliveryAddress2: session.customers[0].deliveryAddress2,
        deliveryZipCode: session.customers[0].deliveryZipCode,
        deliveryCity: session.customers[0].deliveryCity,
        billingCompanyName: session.customers[0].name,
        billingAddress1: session.customers[0].billingAddress1,
        billingAddress2: session.customers[0].billingAddress2,
        billingZipCode: session.customers[0].billingZipCode,
        billingCity: session.customers[0].billingCity,
        paymentState: "ACCEPTED",
        paymentMethod: "BUSINESS_ACCOUNT",
        orderPlatforms: orderPlatforms,
        termsOfSales: value.termsOfSales,
      };
    },
    [values.salesOrder]
  );

  const onSubmit = useCallback(
    (value: Store) => {
      // Construction du tableau d'object IOrderPlatforms
      let orderPlatforms: IOrderPlatforms[] = [];
      values.basketContent.forEach((wishlist: IWishlist) => {
        if (
          wishlist.wishlistArticles.findIndex(
            (item: any) => item.selected === true
          ) > -1
        ) {
          orderPlatforms.push({
            wishlist: {
              id: wishlist.id,
            },
            reference: wishlist.reference,
            reference2: wishlist.reference2,
          });
        }
      });

      SetUpdatingState(true);

      const promises = values.basketContent.map((wishlist: IWishlist) => {
        return updateWhishlisting(wishlist);
      });

      // Quand toutes les promises ont été résolues
      // => renvoie une promesse résolue pour continuer le traitement
      Promise.all(promises)
        .then((result: any) => {
          return getSession();
        })
        // Construction de l'object ISalesOrder
        .then((session: ISession) => {
          if (session !== undefined) {
            let salesOrder: ISalesOrder = updateSaleOrder(
              session,
              orderPlatforms,
              value
            );
            // update du context
            updateSalesOrder(salesOrder);
            onRefreshWishlist();
          }
          return session;
        })
        .then((results: any) => {
          SetUpdatingState(false);
          onNextStep(stepConf?.nextAction.nextStep);
        });
    },
    [
      onNextStep,
      onRefreshWishlist,
      stepConf?.nextAction.nextStep,
      updateSaleOrder,
      updateSalesOrder,
      updateWhishlisting,
      values.basketContent,
    ]
  );

  const onChangeReference = (value: any, wishlist: IWishlist) => {
    wishlist.reference = value.target.value;
  };

  const onChangeReference2 = (value: any, wishlist: IWishlist) => {
    wishlist.reference2 = value.target.value;
  };

  const onChangeShippingMethod = (value: any, wishlist: IWishlist) => {
    shippingMethodService
      // Récupère la liste de des expéditions disponibles de la plateforme
      .getShippingMethods(wishlist.platform.id)
      .then((result: any) => {
        // Remet l'objet entier (shippingMethod) qui correspond
        // à la valeur sélectionnée dans le sélect, dans la wishlist
        wishlist.shippingMethod = result.content.find(
          (shippingMethod: IShippingMethod) => shippingMethod.id === value
        );

        clearTimeout(timerState);
        onUpdateWhishlist(wishlist);

        setTimerState(
          setTimeout(() => {
            values.basketContent.forEach((wish: IWishlist) => {
              if (wish.id === wishlist.id) {
                wish.wishlistArticles.forEach((item: IWishlistArticle) => {
                  onRefreshAvailability(item, wish.platform.id);
                });
              }
            });
          }, 1000)
        );
      });
  };

  const onSuggestedQuantityOk = () => {
    if (selectedWishlistArticleIdState !== undefined) {
      if (
        transfertArticleState !== undefined &&
        transfertArticleState.availability !== undefined
      ) {
        let quantity =
          transfertArticleState.availability.quantityOrdered === undefined
            ? transfertArticleState.availability.suggestedQuantity
            : transfertArticleState.availability.quantityOrdered;
        addArticle(
          transfertArticleState,
          quantity,
          transfertArticleState.availability.platform.id,
          selectedWishlistArticleIdState
        );
      }

      if (
        transfertArticleState !== undefined &&
        transfertArticleState.moreAvailabilities !== undefined
      ) {
        transfertArticleState.moreAvailabilities.forEach(
          (availability: IAvailability) => {
            let quantity =
              availability.quantityOrdered === undefined
                ? 0
                : availability.quantityOrdered;
            addArticle(
              transfertArticleState,
              quantity,
              availability.platform.id,
              selectedWishlistArticleIdState
            );
          }
        );
      }
    }

    setSelectedWishlistArticleIdState(undefined);
    setShowSuggestedQuantityFormState(false);
  };

  const onSuggestedQuantityCancel = () => {
    setSelectedWishlistArticleIdState(undefined);
    setShowSuggestedQuantityFormState(false);
  };

  const onAllRefreshAvailability = useCallback(() => {
    values.basketContent.forEach((wishlist: IWishlist) => {
      wishlist.wishlistArticles.forEach((item: IWishlistArticle) => {
        onRefreshAvailability(item, wishlist.platform.id);
      });
    });
  }, [onRefreshAvailability, values.basketContent]);

  const onSuggestedQuantity = (
    wishlistArticle: IWishlistArticle,
    platform: IPlatform
  ) => {
    wishlistArticle.article.loading = true;
    // forceUpdate();

    articleService
      .articleAvailability(
        wishlistArticle.article.id,
        wishlistArticle.quantity,
        undefined,
        wishlistArticle.id
      )
      .then((result: IAvailability[]) => {
        if (result !== undefined) {
          wishlistArticle.article.quantity = wishlistArticle.quantity;
          wishlistArticle.article.moreAvailabilitiesShow = false;
          let moreAvailabilities: IAvailability[] = [];

          result.forEach((availability: IAvailability) => {
            if (availability.platform.id === platform.id) {
              wishlistArticle.article.availability = availability;

              if (availability.state !== "AVAILABLE") {
                wishlistArticle.article.moreAvailabilitiesShow = true;
              }
            } else if (availability.platform.id !== platform.id) {
              moreAvailabilities.push(availability);
            }
          });

          wishlistArticle.article.moreAvailabilities = moreAvailabilities;
          wishlistArticle.article.loading = false;

          setTransfertArticleState(wishlistArticle.article);
          setSelectedWishlistArticleIdState(wishlistArticle.id);
          setShowSuggestedQuantityFormState(!showSuggestedQuantityFormState);
        } else {
          wishlistArticle.article.loading = false;
          // forceUpdate();
          notification.error({
            message: "Panier",
            description:
              "Impossible de récupérer les informations des disponibilités des plateformes.",
          });
        }
      });
  };

  const initInitialValues = useCallback(() => {
    values.basketContent.forEach((wishlist: IWishlist) => {
      form.setFieldsValue({
        ["reference" + wishlist.id]: wishlist.reference,
        ["reference2" + wishlist.id]: wishlist.reference2,
        ["shippingMethodId" + wishlist.id]: wishlist.shippingMethod.id,
      });
    });
  }, [form, values.basketContent]);

  useEffect(() => {
    if (
      values.wishlistTotal.articleCount > 0 &&
      values.wishlistTotal.articleCount <= 20
    ) {
      onAllRefreshAvailability();
    }

    findParameterValueByName("SHOPPING_CART_TERMS_OF_SALES").then(
      (value: string) => {
        setTermsOfSalesState(value);
      }
    );

    initInitialValues();
  }, [
    initInitialValues,
    onAllRefreshAvailability,
    values.wishlistTotal.articleCount,
  ]);

  return (
    <MarketParameterContext.Consumer>
      {(parameters) => (
        <div>
          {values.basketContent.length === 0 && (
            <Text type="warning">Votre panier est vide.</Text>
          )}

          {values.basketContent.length > 0 && (
            <Form
              form={form}
              {...FlybyLayoutForm}
              onFinish={onSubmit}
              // onPressEnter={preventDefault()}
              // initialValues={initialValuesState}
            >
              <Row
                style={{
                  position: "sticky",
                  top: -20,
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <Col span={24}>
                  <div
                    style={{
                      float: "right",
                      margin: 10,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        onNextStep(stepConf?.prevAction.prevStep);
                      }}
                      hidden={!stepConf?.prevAction.enable}
                      style={{ marginRight: 5 }}
                    >
                      <LeftOutlined />
                      {stepConf?.prevAction.text}
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={updatingState}
                      disabled={values.wishlistTotal.totalQuantitySelected <= 0}
                      hidden={!stepConf?.nextAction.enable}
                    >
                      {stepConf?.nextAction.text}
                      <RightOutlined />
                    </Button>
                  </div>
                </Col>
              </Row>

              {stepConf !== undefined && (
                <SaleOrderResume
                  keyname={"headerResume"}
                  wishlists={values.basketContent}
                  wishlistTotal={values.wishlistTotal}
                  stepConf={stepConf}
                  onChangeReference={onChangeReference}
                />
              )}

              <div style={{ fontSize: "0.95em" }}>
                <Tabs tabBarStyle={{ margin: 2 }}>
                  {values.basketContent.map((wishlist: IWishlist) => (
                    <TabPane tab={wishlist.name} key={wishlist.id.toString()}>
                      <div
                        style={{
                          borderLeft: "solid",
                          borderLeftColor: "#e8e8e8",
                          borderLeftWidth: "1px",
                          borderRight: "solid",
                          borderRightColor: "#e8e8e8",
                          borderRightWidth: "1px",
                        }}
                      >
                        <ShoppingCartHeader
                          onRefreshWishlist={onRefreshWishlist}
                          onAllRefreshAvailability={onAllRefreshAvailability}
                          wishlistTotal={values.wishlistTotal}
                          parameters={parameters}
                        />

                        <div key={wishlist.id}>
                          <ShoppingCartPlatformHeader
                            wishlist={wishlist}
                            listShippingMethods={values.listShippingMethods}
                            showCutoff={showCutoff}
                            onChangeReference={onChangeReference}
                            onChangeReference2={onChangeReference2}
                            onChangeShippingMethod={onChangeShippingMethod}
                            onAllRefreshAvailability={onAllRefreshAvailability}
                            onDeleteWishlist={onDeleteWishlist}
                            onSelectAllItems={onSelectAllItems}
                          />

                          {wishlist.wishlistArticles.length === 0 && (
                            <Row
                              style={{
                                padding: 5,
                                borderBottom: "solid",
                                borderBottomColor: "#e8e8e8",
                                borderBottomWidth: "1px",
                              }}
                            >
                              <Col span={24} style={{ paddingTop: 5 }}>
                                Aucun article pour cette plateforme.
                              </Col>
                            </Row>
                          )}

                          <ShoppingCartPlatformArticlesList
                            wishlist={wishlist}
                            onSelectItem={onSelectItem}
                            onRefreshAvailability={onRefreshAvailability}
                            onSuggestedQuantity={onSuggestedQuantity}
                            onChangeQuantity={onChangeQuantity}
                            deleteArticle={deleteArticle}
                            parameters={parameters}
                          />
                        </div>
                      </div>
                    </TabPane>
                  ))}
                </Tabs>

                {stepConf !== undefined && (
                  <div style={{ marginTop: 10 }}>
                    <SaleOrderResume
                      keyname={"headerResume"}
                      wishlists={values.basketContent}
                      wishlistTotal={values.wishlistTotal}
                      stepConf={stepConf}
                      onChangeReference={undefined}
                    />
                  </div>
                )}

                {termsOfSalesState && (
                  <div
                    style={{
                      textAlign: "right",
                      marginBottom: 10,
                      marginTop: 10,
                      width: "100%",
                    }}
                  >
                    <Form.Item
                      style={{ padding: 0, margin: 0 }}
                      name="termsOfSales"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          transform: (value) => value || undefined,
                          type: "boolean",
                          message: "Vous devez accepter les CGV.",
                        },
                      ]}
                    >
                      <Checkbox>{termsOfSalesState}</Checkbox>
                    </Form.Item>
                  </div>
                )}

                <Modal
                  title="Notre suggestion de commande"
                  open={showSuggestedQuantityFormState}
                  okText={"Valider"}
                  cancelText={"Annuler"}
                  onOk={onSuggestedQuantityOk}
                  onCancel={onSuggestedQuantityCancel}
                  destroyOnClose={true}
                  bodyStyle={{
                    height: 320,
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                  width={800}
                >
                  <SuggestedQuantityForm item={transfertArticleState} />
                </Modal>
              </div>
            </Form>
          )}
        </div>
      )}
    </MarketParameterContext.Consumer>
  );
};

export default Step1;

// import React from "react";
// import "../../App.css";
// import { RightOutlined, LeftOutlined } from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
// import {
//   Form,
//   Row,
//   Col,
//   Button,
//   Modal,
//   Typography,
//   notification,
//   Tabs,
//   Checkbox,
// } from "antd";
// import IWishlist from "../home/IWishlist";
// import IWishlistArticle from "../home/IWishlistArticles";
// import IPlatform from "../home/IPlatform";
// import ISalesOrder from "./ISalesOrder";
// import LoginService from "../login/LoginService";
// import ISession from "../myaccount/ISession";
// import IOrderPlatforms from "./IOrderPlatforms";
// import IAvailability from "../home/IAvailability";
// import IArticle from "../home/IArticle";
// import SuggestedQuantityForm from "../home/SuggestedQuantityForm";
// import ArticleService from "../home/ArticleService";
// import ShippingMethodService from "./ShippingMethodService";
// import WhishlistService from "../home/WishlistService";
// import FlybyLayoutForm from "../../Utils/FlybyLayoutForm";
// import {
//   ShoppingCartHeader,
//   SaleOrderResume,
//   ShoppingCartPlatformHeader,
//   ShoppingCartPlatformArticlesList,
// } from "./ShoppingCartUtils";
// import IStep from "./IStep";
// import ParameterService from "../../Utils/ParameterService";
// import { MarketParameterContext } from "../../Utils/MarketParameterContext";
// import { IShippingMethod } from "./IShippingMethod";

// const { Text } = Typography;
// const { TabPane } = Tabs;

// class Step1 extends React.Component<
//   {
//     stepConf: IStep | undefined;
//     values: any;
//     onRefreshAvailability: any;
//     onChangeQuantity: any;
//     onDeleteWishlist: any;
//     onSelectAllItems: any;
//     deleteArticle: any;
//     onSelectItem: any;
//     onRefreshWishlist: any;
//     onUpdateWhishlist: any;
//     addArticle: any;
//     onNextStep: any;
//     updateSalesOrder: any;
//     showCutoff: boolean;
//   },
//   {
//     showSuggestedQuantityForm: boolean;
//     transfertArticle: IArticle | undefined;
//     selectedWishlistArticleId: number | undefined;
//     updating: boolean;
//     termsOfSales: string | undefined;
//   }
// > {
//   private loginService: LoginService = new LoginService();
//   private articleService: ArticleService = new ArticleService();
//   private shippingMethodService: ShippingMethodService =
//     new ShippingMethodService();
//   private wishlistService: WhishlistService = new WhishlistService();
//   private parameterService: ParameterService = new ParameterService();

//   private timer: any = null;

//   constructor(props: {
//     stepConf: IStep | undefined;
//     values: any;
//     onRefreshAvailability: any;
//     onChangeQuantity: any;
//     onDeleteWishlist: any;
//     onSelectAllItems: any;
//     showCutoff: boolean;
//     deleteArticle: any;
//     onSelectItem: any;
//     onRefreshWishlist: any;
//     onUpdateWhishlist: any;
//     addArticle: any;
//     onNextStep: any;
//     updateSalesOrder: any;
//   }) {
//     super(props);

//     this.state = {
//       showSuggestedQuantityForm: false,
//       transfertArticle: undefined,
//       selectedWishlistArticleId: undefined,
//       updating: false,
//       termsOfSales: undefined,
//     };
//   }

//   componentDidMount() {
//     if (
//       this.props.values.wishlistTotal.articleCount > 0 &&
//       this.props.values.wishlistTotal.articleCount <= 20
//     ) {
//       this.onAllRefreshAvailability();
//     }

//     this.parameterService
//       .findValue("SHOPPING_CART_TERMS_OF_SALES")
//       .then((value: string) => {
//         this.setState({
//           termsOfSales: value,
//         });
//       });
//   }

//   render() {
//     let initialValues = {};

//     this.props.values.basketContent.forEach((wishlist: IWishlist) => {
//       initialValues["reference" + wishlist.id] = wishlist.reference;
//       initialValues["reference2" + wishlist.id] = wishlist.reference2;
//       initialValues["shippingMethodId" + wishlist.id] =
//         wishlist.shippingMethod.name;
//     });

//     return (
//       <MarketParameterContext.Consumer>
//         {(parameters) => (
//           <div>
//             {this.props.values.basketContent.length === 0 && (
//               <Text type="warning">Votre panier est vide.</Text>
//             )}

//             {this.props.values.basketContent.length > 0 && (
//               <>
//                 <Form
//                   {...FlybyLayoutForm}
//                   onFinish={this.onSubmit}
//                   onKeyDown={(e) =>
//                     e.keyCode === 13 ? e.preventDefault() : ""
//                   }
//                   initialValues={initialValues}
//                 >
//                   <Row>
//                     <Col span={24}>
//                       <div
//                         style={{
//                           float: "right",
//                           marginBottom: 10,
//                           marginTop: 10,
//                         }}
//                       >
//                         <Button
//                           type="primary"
//                           size="large"
//                           onClick={() =>
//                             this.props.onNextStep(
//                               this.props.stepConf?.prevAction.prevStep
//                             )
//                           }
//                           hidden={!this.props.stepConf?.prevAction.enable}
//                           style={{ marginRight: 5 }}
//                         >
//                           <LeftOutlined />
//                           {this.props.stepConf?.prevAction.text}
//                         </Button>
//                         <Button
//                           type="primary"
//                           size="large"
//                           htmlType="submit"
//                           loading={this.state.updating}
//                           disabled={
//                             this.props.values.wishlistTotal
//                               .totalQuantitySelected <= 0
//                           }
//                           hidden={!this.props.stepConf?.nextAction.enable}
//                         >
//                           {this.props.stepConf?.nextAction.text}
//                           <RightOutlined />
//                         </Button>
//                       </div>
//                     </Col>
//                   </Row>

//                   {this.props.stepConf !== undefined && (
//                     <SaleOrderResume
//                       keyname={"headerResume"}
//                       wishlists={this.props.values.basketContent}
//                       wishlistTotal={this.props.values.wishlistTotal}
//                       stepConf={this.props.stepConf}
//                       onChangeReference={this.onChangeReference}
//                     />
//                   )}

//                   <div style={{ fontSize: "0.95em" }}>
//                     <Tabs tabBarStyle={{ margin: 2 }}>
//                       {this.props.values.basketContent.map(
//                         (wishlist: IWishlist) => (
//                           <TabPane
//                             tab={wishlist.name}
//                             key={wishlist.id.toString()}
//                           >
//                             <div
//                               style={{
//                                 borderLeft: "solid",
//                                 borderLeftColor: "#e8e8e8",
//                                 borderLeftWidth: "1px",
//                                 borderRight: "solid",
//                                 borderRightColor: "#e8e8e8",
//                                 borderRightWidth: "1px",
//                               }}
//                             >
//                               <ShoppingCartHeader
//                                 onRefreshWishlist={this.onRefreshWishlist}
//                                 onAllRefreshAvailability={
//                                   this.onAllRefreshAvailability
//                                 }
//                                 wishlistTotal={this.props.values.wishlistTotal}
//                                 parameters={parameters}
//                               />

//                               <div key={wishlist.id}>
//                                 <ShoppingCartPlatformHeader
//                                   wishlist={wishlist}
//                                   listShippingMethods={
//                                     this.props.values.listShippingMethods
//                                   }
//                                   showCutoff={this.props.showCutoff}
//                                   onChangeReference={this.onChangeReference}
//                                   onChangeReference2={this.onChangeReference2}
//                                   onChangeShippingMethod={
//                                     this.onChangeShippingMethod
//                                   }
//                                   onAllRefreshAvailability={
//                                     this.onAllRefreshAvailability
//                                   }
//                                   onDeleteWishlist={this.onDeleteWishlist}
//                                   onSelectAllItems={this.onSelectAllItems}
//                                 />

//                                 {wishlist.wishlistArticles.length === 0 && (
//                                   <Row
//                                     style={{
//                                       padding: 5,
//                                       borderBottom: "solid",
//                                       borderBottomColor: "#e8e8e8",
//                                       borderBottomWidth: "1px",
//                                     }}
//                                   >
//                                     <Col span={24} style={{ paddingTop: 5 }}>
//                                       Aucun article pour cette plateforme.
//                                     </Col>
//                                   </Row>
//                                 )}

//                                 <ShoppingCartPlatformArticlesList
//                                   wishlist={wishlist}
//                                   onSelectItem={this.onSelectItem}
//                                   onRefreshAvailability={
//                                     this.onRefreshAvailability
//                                   }
//                                   onSuggestedQuantity={this.onSuggestedQuantity}
//                                   onChangeQuantity={this.onChangeQuantity}
//                                   deleteArticle={this.deleteArticle}
//                                   parameters={parameters}
//                                 />
//                               </div>
//                             </div>
//                           </TabPane>
//                         )
//                       )}
//                     </Tabs>

//                     {this.props.stepConf !== undefined && (
//                       <div style={{ marginTop: 10 }}>
//                         <SaleOrderResume
//                           keyname={"headerResume"}
//                           wishlists={this.props.values.basketContent}
//                           wishlistTotal={this.props.values.wishlistTotal}
//                           stepConf={this.props.stepConf}
//                           onChangeReference={undefined}
//                         />
//                       </div>
//                     )}

//                     {this.state.termsOfSales && (
//                       <div
//                         style={{
//                           textAlign: "right",
//                           marginBottom: 10,
//                           marginTop: 10,
//                           width: "100%",
//                         }}
//                       >
//                         <Form.Item
//                           style={{ padding: 0, margin: 0 }}
//                           name="termsOfSales"
//                           valuePropName="checked"
//                           rules={[
//                             {
//                               required: true,
//                               transform: (value) => value || undefined,
//                               type: "boolean",
//                               message: "Vous devez accepter les CGV.",
//                             },
//                           ]}
//                         >
//                           <Checkbox>{this.state.termsOfSales}</Checkbox>
//                         </Form.Item>
//                       </div>
//                     )}

//                     <div
//                       style={{
//                         float: "right",
//                         marginBottom: 10,
//                         marginTop: 10,
//                       }}
//                     >
//                       <Button
//                         type="primary"
//                         size="large"
//                         onClick={() =>
//                           this.props.onNextStep(
//                             this.props.stepConf?.prevAction.prevStep
//                           )
//                         }
//                         hidden={!this.props.stepConf?.prevAction.enable}
//                         style={{ marginRight: 5 }}
//                       >
//                         <LeftOutlined />
//                         {this.props.stepConf?.prevAction.text}
//                       </Button>
//                       <Button
//                         type="primary"
//                         size="large"
//                         htmlType="submit"
//                         loading={this.state.updating}
//                         disabled={
//                           this.props.values.wishlistTotal
//                             .totalQuantitySelected <= 0
//                         }
//                         hidden={!this.props.stepConf?.nextAction.enable}
//                       >
//                         {this.props.stepConf?.nextAction.text}
//                         <RightOutlined />
//                       </Button>
//                     </div>

//                     <Modal
//                       title="Notre suggestion de commande"
//                       visible={this.state.showSuggestedQuantityForm}
//                       okText={"Valider"}
//                       cancelText={"Annuler"}
//                       onOk={this.onSuggestedQuantityOk}
//                       onCancel={this.onSuggestedQuantityCancel}
//                       destroyOnClose={true}
//                       bodyStyle={{
//                         height: 320,
//                         overflowX: "hidden",
//                         overflowY: "auto",
//                       }}
//                       width={800}
//                     >
//                       <SuggestedQuantityForm
//                         item={this.state.transfertArticle}
//                       />
//                     </Modal>
//                   </div>
//                 </Form>
//               </>
//             )}
//           </div>
//         )}
//       </MarketParameterContext.Consumer>
//     );
//   }

//   private onSubmit = (values: any) => {
//     console.log("this.props.values. : ", this.props.values.termsOfSales);
//     ².log("values : ", values);
//     let orderPlatforms: IOrderPlatforms[] = [];
//     this.props.values.basketContent.forEach((wishlist: IWishlist) => {
//       if (
//         wishlist.wishlistArticles.findIndex(
//           (item: any) => item.selected === true
//         ) > -1
//       ) {
//         orderPlatforms.push({
//           wishlist: {
//             id: wishlist.id,
//           },
//           reference: wishlist.reference,
//           reference2: wishlist.reference2,
//         });
//       }
//     });

//     this.setState({
//       updating: true,
//     });

//     var promises = this.props.values.basketContent.map(
//       (wishlist: IWishlist) => {
//         return this.updateWhishlist(wishlist);
//       }
//     );

//     Promise.all(promises)
//       .then((results: any) => {
//         return this.loginService.session();
//       })
//       .then((session: ISession) => {
//         if (session !== undefined) {
//           let salesOrder: ISalesOrder = {
//             ...this.props.values.salesOrder,
//             deliveryCompanyName: session.customers[0].name,
//             deliveryAddress1: session.customers[0].deliveryAddress1,
//             deliveryAddress2: session.customers[0].deliveryAddress2,
//             deliveryZipCode: session.customers[0].deliveryZipCode,
//             deliveryCity: session.customers[0].deliveryCity,
//             billingCompanyName: session.customers[0].name,
//             billingAddress1: session.customers[0].billingAddress1,
//             billingAddress2: session.customers[0].billingAddress2,
//             billingZipCode: session.customers[0].billingZipCode,
//             billingCity: session.customers[0].billingCity,
//             paymentState: "ACCEPTED",
//             paymentMethod: "BUSINESS_ACCOUNT",
//             orderPlatforms: orderPlatforms,
//             termsOfSales: values.termsOfSales,
//           };
//           this.props.updateSalesOrder(salesOrder);
//           this.props.onRefreshWishlist();
//         }
//         return session;
//       })
//       .then((results: any) => {
//         this.setState({
//           updating: false,
//         });
//         this.props.onNextStep(this.props.stepConf?.nextAction.nextStep);
//       });
//   };

//   private onSuggestedQuantity = (
//     wishlistArticle: IWishlistArticle,
//     platform: IPlatform
//   ) => {
//     wishlistArticle.article.loading = true;
//     this.forceUpdate();

//     this.articleService
//       .articleAvailability(
//         wishlistArticle.article.id,
//         wishlistArticle.quantity,
//         undefined,
//         wishlistArticle.id
//       )
//       .then((result: IAvailability[]) => {
//         if (result !== undefined) {
//           wishlistArticle.article.quantity = wishlistArticle.quantity;
//           wishlistArticle.article.moreAvailabilitiesShow = false;
//           let moreAvailabilities: IAvailability[] = [];

//           result.forEach((availability: IAvailability) => {
//             if (availability.platform.id === platform.id) {
//               wishlistArticle.article.availability = availability;

//               if (availability.state !== "AVAILABLE") {
//                 wishlistArticle.article.moreAvailabilitiesShow = true;
//               }
//             } else if (availability.platform.id !== platform.id) {
//               moreAvailabilities.push(availability);
//             }
//           });

//           wishlistArticle.article.moreAvailabilities = moreAvailabilities;
//           wishlistArticle.article.loading = false;

//           this.setState({
//             transfertArticle: wishlistArticle.article,
//             selectedWishlistArticleId: wishlistArticle.id,
//             showSuggestedQuantityForm: !this.state.showSuggestedQuantityForm,
//           });
//         } else {
//           wishlistArticle.article.loading = false;
//           this.forceUpdate();
//           notification.error({
//             message: "Panier",
//             description:
//               "Impossible de récupérer les informations des disponibilités des plateformes.",
//           });
//         }
//       });
//   };

//   private onSuggestedQuantityOk = () => {
//     if (this.state.selectedWishlistArticleId !== undefined) {
//       if (
//         this.state.transfertArticle !== undefined &&
//         this.state.transfertArticle.availability !== undefined
//       ) {
//         let quantity =
//           this.state.transfertArticle.availability.quantityOrdered === undefined
//             ? this.state.transfertArticle.availability.suggestedQuantity
//             : this.state.transfertArticle.availability.quantityOrdered;
//         this.props.addArticle(
//           this.state.transfertArticle,
//           quantity,
//           this.state.transfertArticle.availability.platform.id,
//           this.state.selectedWishlistArticleId
//         );
//       }

//       if (
//         this.state.transfertArticle !== undefined &&
//         this.state.transfertArticle.moreAvailabilities !== undefined
//       ) {
//         this.state.transfertArticle.moreAvailabilities.forEach(
//           (availability: IAvailability) => {
//             let quantity =
//               availability.quantityOrdered === undefined
//                 ? 0
//                 : availability.quantityOrdered;
//             this.props.addArticle(
//               this.state.transfertArticle,
//               quantity,
//               availability.platform.id,
//               this.state.selectedWishlistArticleId
//             );
//           }
//         );
//       }
//     }
//     this.setState({
//       selectedWishlistArticleId: undefined,
//       showSuggestedQuantityForm: false,
//     });
//   };

//   private onSuggestedQuantityCancel = () => {
//     this.setState({
//       selectedWishlistArticleId: undefined,
//       showSuggestedQuantityForm: false,
//     });
//   };

//   private onSelectItem = (checkedValue: any, item: IWishlistArticle) => {
//     this.props.onSelectItem(checkedValue.target.checked, item);
//   };

//   private onAllRefreshAvailability = () => {
//     this.props.values.basketContent.forEach((wishlist: IWishlist) => {
//       wishlist.wishlistArticles.forEach((item: IWishlistArticle) => {
//         this.props.onRefreshAvailability(item, wishlist.platform.id);
//       });
//     });
//   };

//   private onSelectAllItems = (checkedValue: any, wishlist: IWishlist) => {
//     this.props.onSelectAllItems(checkedValue.target.checked, wishlist);
//   };

//   private onRefreshAvailability = (
//     item: IWishlistArticle,
//     platformId: number
//   ) => {
//     this.props.onRefreshAvailability(item, platformId);
//   };

//   private onChangeQuantity = (
//     value: any,
//     item: IWishlistArticle,
//     wishlistId: number
//   ) => {
//     this.props.onChangeQuantity(value, item, wishlistId);
//   };

//   private onChangeReference = (value: any, wishlist: IWishlist) => {
//     wishlist.reference = value.target.value;
//   };

//   private onChangeReference2 = (value: any, wishlist: IWishlist) => {
//     wishlist.reference2 = value.target.value;
//   };

//   private onChangeShippingMethod = (value: any, wishlist: IWishlist) => {
//     this.shippingMethodService
//       // Récupère la liste de des expéditions disponibles de la plateforme
//       .getShippingMethods(wishlist.platform.id)
//       .then((result: any) => {
//         // Remet l'objet entier (shippingMethod) qui correspond
//         // à la valeur sélectionnée dans le sélect, dans la wishlist
//         wishlist.shippingMethod = result.content.find(
//           (shippingMethod: IShippingMethod) => shippingMethod.id === value
//         );

//         clearTimeout(this.timer);
//         this.props.onUpdateWhishlist(wishlist);

//         this.timer = setTimeout(() => {
//           this.props.values.basketContent.forEach((wish: IWishlist) => {
//             if (wish.id === wishlist.id) {
//               wish.wishlistArticles.forEach((item: IWishlistArticle) => {
//                 this.props.onRefreshAvailability(item, wish.platform.id);
//               });
//             }
//           });
//         }, 1000);
//       });
//   };

//   // private onChangeShippingMethod2 = (value: any) => {};

//   private updateWhishlist = (wishlist: IWishlist) => {
//     let wishlistUpdate = {
//       id: wishlist.id,
//       name: wishlist.name,
//       reference: wishlist.reference,
//       reference2: wishlist.reference2,
//       platform: {
//         id: wishlist.platform.id,
//       },
//       shippingMethod: {
//         id: wishlist.shippingMethod.id,
//       },
//     };

//     return this.wishlistService.updateWhishlist(wishlistUpdate);
//   };

//   private onDeleteWishlist = (wishlistId: number) => {
//     this.props.onDeleteWishlist(wishlistId);
//   };

//   private deleteArticle = (wishlistArticleId: number) => {
//     this.props.deleteArticle(wishlistArticleId);
//   };

//   private onRefreshWishlist = () => {
//     this.props.onRefreshWishlist();
//   };
// }

// export default Step1;
