import React from 'react';
import '../../../App.css';
import { Form, Skeleton, Select, Button, Col, Row } from 'antd';

import { withBasket } from '../../../Utils/BasketProvider';
import ITecdocSearch from '../model/ITecdocSearch';
import IFilter from '../model/Ifilter';
import TecdocService from '../TecdocService';
import FlybyUtils from '../../../Utils/FlybyUtils';
import IKeyEntry from '../model/IKeyEntry';
import TecdocUtils from '../TecdocUtils';

const { Option } = Select;

class TecdocVehicleListFilter extends React.Component<
  {
    tecdocSearch: ITecdocSearch,
    onFilters: any,
  },
  {
    filtersList: any[],
    dataLoading: boolean,
  }>{

  private tecdocService: TecdocService = new TecdocService();
  private tecdocUtils: TecdocUtils = new TecdocUtils();
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private fuelType: IKeyEntry[] | undefined;
  private bodyType: IKeyEntry[] | undefined;

  private selectValue = ((filtersList: any[] | undefined, label: string, name: string) =>
    <div>
      <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>{label} :</span>
      <Form.Item name={name} style={{ width: '100%' }}>
        <Select mode="multiple" allowClear={true} disabled={filtersList === undefined || filtersList.length <= 0}>
          {filtersList}
        </Select>
      </Form.Item>
    </div>
  );

  constructor(props: { tecdocSearch: ITecdocSearch, setTecdocSearch: any, onFilters: any }) {
    super(props);
    this.state = {
      filtersList: [],
      dataLoading: true,
    };
  }

  componentDidMount() {
    if(this.props.tecdocSearch.query !== undefined){
      let queryType = this.props.tecdocSearch.queryType;
      let query = '';
      switch(queryType){
        case "mineType":
          query = this.props.tecdocSearch.query.mineType;
          break;
        case "motorCode":
          query = this.props.tecdocSearch.query.motorCode;
          break;
      }
      this.loadData(queryType, query);
    }    
  }

  componentWillReceiveProps(newProps: { tecdocSearch: ITecdocSearch, setTecdocSearch: any, onFilters: any }) {
    if (this.props.tecdocSearch.query !== undefined && this.props.tecdocSearch.query.motorCode !== ''
      && newProps.tecdocSearch.query !== undefined && newProps.tecdocSearch.query.motorCode !== ''
      && this.props.tecdocSearch.query.motorCode !== newProps.tecdocSearch.query.motorCode) {
        let queryType = newProps.tecdocSearch.queryType;
        let query = '';
        switch(queryType){
          case "mineType":
            query = newProps.tecdocSearch.query.mineType;          
            break;
          case "motorCode":
            query = newProps.tecdocSearch.query.motorCode;
            break;
        }      
        this.loadData(queryType, query);
    }
  }

  render() {

    return (
      <Skeleton loading={this.state.dataLoading} active>
        <Form size={"middle"} name='search' onFinish={this.onFinish} className="ant-advanced-search-form" style={{ padding: 10, marginBottom: 10 }} >
          <Row gutter={6}>
            <Col span={5}>
              {this.selectValue(this.state.filtersList['Bjvon'], "Depuis le", "Bjvon")}
            </Col>
            <Col span={5}>
              {this.selectValue(this.state.filtersList['Bjbis'], "Jusqu'à", "Bjbis")}
            </Col>
            <Col span={5}>
              {this.selectValue(this.state.filtersList['PS'], "Ch.", "PS")}
            </Col>
            <Col span={5}>
              {this.selectValue(this.state.filtersList['KW'], "Kw", "KW")}
            </Col>
            <Col span={4}>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={5}>
              {this.selectValue(this.state.filtersList['ccmTech'], "Cc", "ccmTech")}
            </Col>
            <Col span={5}>
              {this.selectValue(this.state.filtersList['ABS'], "ABS", "ABS")}
            </Col>
            <Col span={5}>
              {this.selectValue(this.state.filtersList['AufbauArt'], "Carrosserie", "AufbauArt")}
            </Col>
            <Col span={5}>
              {this.selectValue(this.state.filtersList['KrStoffArt'], "Carburant", "KrStoffArt")}
            </Col>
            <Col span={4}>
              <Form.Item style={{ paddingTop: 21 }} name="submit">
                <Button type="primary" htmlType="submit">Filtrer</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    );
  }



  private onFinish = (values: any) => {
    let filters = this.flybyUtils.createFilters(values);
    this.props.onFilters(filters);
  }

  private loadFiltersList = (queryType: string, query: string, key: string, keyEntry: IKeyEntry[] | undefined) => {

      switch(queryType){
        case "mineType":
          return this.tecdocService.findVehicleTypesFilterByMineType(query, key)
          .then((filters: any) => {
            return this.setData(filters, key, keyEntry);
          });
        case "motorCode":
          return this.tecdocService.findVehicleTypesFilterByMCode(query, key)
          .then((filters: any) => {
            return this.setData(filters, key, keyEntry);
          });
      }        

      return;
  }

  private setData = (filters: any,  key: string, keyEntry: IKeyEntry[] | undefined) => {
    if (filters !== undefined) {
      let filtersList: any[] = [];
      filters.forEach((filter: IFilter) => {
        if (filter !== null) {
          let value = "";

          switch (key) {
            case "Bjvon":
            case "Bjbis":
              value = this.flybyUtils.convertNumberToDateMMYYYY(Number(filter.value));
              break;
            case "AufbauArt":
              value = this.tecdocUtils.getKeyType(keyEntry, Number(filter.value));
              break;
            case "KrStoffArt":
              value = this.tecdocUtils.getKeyType(keyEntry, Number(filter.value));
              break;
            case "ABS":
              value = this.tecdocUtils.getYesNo(Number(filter.value));
              break;
            default:
              value = filter.value;
              break;
          }

          filtersList.push(<Option key={filter.key} value={filter.key}>{value}</Option>);
        }
      });
      return filtersList;
    }
    else {
      return undefined;
    }
  }

  private loadData = (queryType: string, query: string) => {
    let promise1 = this.tecdocService.findKeyEntries(182, 200, 0);
    let promise2 = this.tecdocService.findKeyEntries(86, 200, 0);

    Promise.all([promise1, promise2]).then((values) => {
      this.fuelType = values[0].content;
      this.bodyType = values[1].content;


      let promise3 = this.loadFiltersList(queryType, query, 'Bjvon', undefined);
      let promise4 = this.loadFiltersList(queryType, query, 'Bjbis', undefined);
      let promise5 = this.loadFiltersList(queryType, query, 'PS', undefined);
      let promise6 = this.loadFiltersList(queryType, query, 'KW', undefined);
      let promise7 = this.loadFiltersList(queryType, query, 'ccmTech', undefined);
      let promise8 = this.loadFiltersList(queryType, query, 'AufbauArt', this.bodyType);
      let promise9 = this.loadFiltersList(queryType, query, 'KrStoffArt', this.fuelType);
      let promise10 = this.loadFiltersList(queryType, query, 'ABS', undefined);

      Promise.all([promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10]).then((values) => {
        let filtersList: any[] = [];
        filtersList['Bjvon'] = values[0];
        filtersList['Bjbis'] = values[1];
        filtersList['PS'] = values[2];
        filtersList['KW'] = values[3];
        filtersList['ccmTech'] = values[4];
        filtersList['AufbauArt'] = values[5];
        filtersList['KrStoffArt'] = values[6];
        filtersList['ABS'] = values[7];

        this.setState({
          filtersList: filtersList,
          dataLoading: false
        });
      });

    });
  }

}

export default withBasket(TecdocVehicleListFilter);     