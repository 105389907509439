import React from 'react';
import '../../App.css';
import { Col, Row } from 'antd';

import FlybyUtils from '../../Utils/FlybyUtils';
import IDocumentHistoryReturn from './IDocumentHistoryReturn';
import IDocumentHistoryDetailReturn from './IDocumentHistoryDetailReturn';

class ReturnRequestDetails extends React.Component<
    {
        documentHistoryReturns: IDocumentHistoryReturn[],
        documentHistoryDetail: IDocumentHistoryDetailReturn
    }, {}>{

    private flybyUtils: FlybyUtils = new FlybyUtils();

    render() {
        return (
            <div>

                {this.props.documentHistoryReturns.length > 0 &&
                    <div style={{ fontSize: '0.95em', border: 'solid', borderColor: '#e8e8e8', borderWidth: '1px' }}>
                        <Row style={{ fontWeight: "bold", background: "#ECECEC", padding: 5 }}>
                            <Col span={2} style={{ paddingLeft: 5 }}>Date demande</Col>
                            <Col span={5} style={{ paddingLeft: 5 }}>Etat</Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>Motif</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Qté demandée</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Qté acceptée</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Qté refusée</Col>
                        </Row>

                        {this.props.documentHistoryReturns.map((line: IDocumentHistoryReturn) =>

                            <Row key={'line' + line.id} style={{ padding: 5, borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px' }}>
                                <Col span={2} style={{ paddingLeft: 5, paddingTop: 6 }}>{this.flybyUtils.formatJsonDateDDMMYYYY(line.requestDate)}</Col>
                                <Col span={5} style={{ paddingLeft: 5, paddingTop: 6 }}>{this.flybyUtils.getStateReturnText(line.state)}</Col>
                                <Col span={11} style={{ paddingLeft: 5, paddingTop: 6 }}>{line.documentHistoryReturnReason.description}</Col>
                                <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.requestQuantity, 2))}</Col>
                                <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.approvalQuantity, 2))}</Col>
                                <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.rejectionQuantity, 2))}</Col>
                            </Row>

                        )}
                    </div>
                }
                {this.props.documentHistoryReturns.length === 0 &&
                    <div>
                        Aucune demande de retour enregistrée.
                    </div>
                }

            </div>
        );
    }

}

export default ReturnRequestDetails;        