import React from 'react';
import '../../../App.css';
import { Descriptions, Row, Col } from 'antd';
import { BasketContext } from '../../../Utils/BasketProvider';


class TecdocVehicleInfo extends React.Component<{}, {}>{


    render() {
        return (
            <BasketContext.Consumer>
                {(contextValues) => contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.ktypNr !== undefined &&
                    <Descriptions column={2} layout="vertical" size="small" bordered className="custom-description">
                        <Descriptions.Item label="Marque" span={2}>
                            <Row align="middle">
                                <Col span={5} >
                                    <img src={'/tecdoc-api/manufacturers/' + contextValues.tecdocSearch.vehicle.herNr + '/logo'}
                                        title={contextValues.tecdocSearch.vehicle.herNrDescription}
                                        alt={contextValues.tecdocSearch.vehicle.herNrDescription}
                                        style={{ width: '100%'}} />
                                </Col>
                                <Col span={19} style={{paddingLeft: 5}}>
                                    {contextValues.tecdocSearch.vehicle.herNrDescription}
                                </Col>
                            </Row>
                        </Descriptions.Item>
                        <Descriptions.Item label="Modèle"><div style={{width: '95%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{contextValues.tecdocSearch.vehicle.kmodNrDescription}</div></Descriptions.Item>
                        <Descriptions.Item label="Type"><div style={{width: '95%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{contextValues.tecdocSearch.vehicle.ktypNrDescription}</div></Descriptions.Item>
                        <Descriptions.Item label="Couleur"><div style={{width: '95%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{contextValues.tecdocSearch.vehicle.color !== undefined ? contextValues.tecdocSearch.vehicle.color : ''}</div></Descriptions.Item>
                        <Descriptions.Item label="Immatriculation">{contextValues.tecdocSearch.vehicle.plateNumber !== undefined ? contextValues.tecdocSearch.vehicle.plateNumber : ''}</Descriptions.Item>
                        <Descriptions.Item label="Type mine"><div style={{width: '95%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{contextValues.tecdocSearch.vehicle.mineType !== undefined ? contextValues.tecdocSearch.vehicle.mineType : ''}</div></Descriptions.Item>
                        <Descriptions.Item label="Type VIN"><div style={{width: '95%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{contextValues.tecdocSearch.vehicle.vin !== undefined ? contextValues.tecdocSearch.vehicle.vin : ''}</div></Descriptions.Item>
                    </Descriptions>

                }
            </BasketContext.Consumer>
        );
    }

}

export default TecdocVehicleInfo;     