import React, { useEffect } from 'react';
import { useMarketParameter } from './MarketParameterContext';

const Title = (): React.ReactElement => {
  const { TITLE, FAVICO } = useMarketParameter();

  useEffect(() => {

    if (TITLE) {
      document.title = TITLE;
    }

    if (FAVICO) {
      var link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = FAVICO;
    }

  }, [TITLE, FAVICO]);

  return (
    <></>
  );
};

export default Title;
