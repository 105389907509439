import React from "react";
import { Skeleton, Statistic } from "antd";
import IArticle from "../IArticle";
import { BasketContext } from "../../../Utils/BasketProvider";
import { numberFormat } from "../../../Utils/FlybyUtilsFunctional";

interface ArticlePriceProps {
  item: IArticle;
  loading: boolean;
}

const ArticlePrice = (props: ArticlePriceProps): JSX.Element => {
  const { item, loading } = props;

  return (
    <BasketContext.Consumer>
      {(basket) => (
        <Skeleton loading={loading} active>
          {basket.userRole !== "ROLE_GUEST" &&
            item.availability !== undefined && (
              <>
                {/* // <Skeleton loading={item.loading} active> */}
                <Statistic
                  title="Montant net"
                  value={numberFormat(item.availability.netAmount, 2)}
                  suffix="€ HT"
                />
                {item.availability.netAmount <
                  item.availability.netAmountInclTaxes && (
                  <span
                    style={{ fontSize: 14, color: "#bfbfbf", marginTop: 25 }}
                  >
                    {numberFormat(item.availability.netAmountInclTaxes, 2)} €
                    TTC
                  </span>
                )}
                {item.availability.consignmentPrice > 0 && (
                  <Statistic
                    title="Montant consigne net"
                    style={{ marginTop: 10 }}
                    value={numberFormat(
                      item.availability.consignmentAmountExclTaxes,
                      2
                    )}
                    suffix="€ HT"
                  />
                )}
                {item.availability.consignmentPrice > 0 &&
                  item.availability.consignmentAmountInclTaxes >
                    item.availability.consignmentAmountExclTaxes && (
                    <span
                      style={{
                        fontSize: 14,
                        color: "#bfbfbf",
                        marginTop: 25,
                      }}
                    >
                      {numberFormat(
                        item.availability.consignmentAmountInclTaxes,
                        2
                      )}{" "}
                      € TTC
                    </span>
                  )}
              </>
            )}
        </Skeleton>
      )}
    </BasketContext.Consumer>
  );
};

export default ArticlePrice;
// class ArticlePrice extends React.Component<
//   { item: IArticle; loading: boolean },
//   {}
// > {
//   private flybyUtils: FlybyUtils = new FlybyUtils();

//   render() {
//     return (
//       <BasketContext.Consumer>
//         {(basket) => (
//           <>
//             {basket.userRole !== "ROLE_GUEST" &&
//               this.props.item.availability !== undefined && (
//                 <Skeleton loading={this.props.item.loading} active>
//                   <Statistic
//                     title="Montant net"
//                     value={this.flybyUtils.numberFormat(
//                       this.props.item.availability.netAmount,
//                       2
//                     )}
//                     suffix="€ HT"
//                   />
//                   {this.props.item.availability.netAmount <
//                     this.props.item.availability.netAmountInclTaxes && (
//                     <span
//                       style={{ fontSize: 14, color: "#bfbfbf", marginTop: 25 }}
//                     >
//                       {this.flybyUtils.numberFormat(
//                         this.props.item.availability.netAmountInclTaxes,
//                         2
//                       )}{" "}
//                       € TTC
//                     </span>
//                   )}
//                   {this.props.item.availability.consignmentPrice > 0 && (
//                     <Statistic
//                       title="Montant consigne net"
//                       style={{ marginTop: 10 }}
//                       value={this.flybyUtils.numberFormat(
//                         this.props.item.availability.consignmentAmountExclTaxes,
//                         2
//                       )}
//                       suffix="€ HT"
//                     />
//                   )}
//                   {this.props.item.availability.consignmentPrice > 0 &&
//                     this.props.item.availability.consignmentAmountInclTaxes >
//                       this.props.item.availability
//                         .consignmentAmountExclTaxes && (
//                       <span
//                         style={{
//                           fontSize: 14,
//                           color: "#bfbfbf",
//                           marginTop: 25,
//                         }}
//                       >
//                         {this.flybyUtils.numberFormat(
//                           this.props.item.availability
//                             .consignmentAmountInclTaxes,
//                           2
//                         )}{" "}
//                         € TTC
//                       </span>
//                     )}
//                 </Skeleton>
//               )}
//           </>
//         )}
//       </BasketContext.Consumer>
//     );
//   }
// }

// export default ArticlePrice;
