import { Row, Col, Divider, Typography, Tag } from "antd";
import IConfirmedSalesOrder from "./IConfirmedSalesOrder";
import FlybyUtils from "../../Utils/FlybyUtils";
import IOrderPlatforms from "./IOrderPlatforms";
import IOrderPlatformDetails from "./IOrderPlatformDetails";
import { WarningOutlined } from "@ant-design/icons";
import { DefaultParameter } from "../../Utils/MarketParameterContext";
import IStep from "./IStep";

const flybyUtils: FlybyUtils = new FlybyUtils();
const { Text } = Typography;
const showPlatformList: boolean =
  sessionStorage.getItem("showPlatformList") === "false" ? false : true;

export const ConfirmedSalesOrderViewPlatformItem = (props: {
  item: IOrderPlatformDetails;
  parameters: DefaultParameter;
}) => {
  return (
    <div key={props.item.id} style={{ padding: 5, width: "100%" }}>
      <Row key={"item" + props.item.id}>
        <Col span={3} style={{ paddingTop: 6 }}>
          {props.item.article.reference}
        </Col>
        <Col span={4} style={{ paddingLeft: 5, paddingTop: 6 }}>
          {props.item.article.name}
        </Col>
        <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}>
          {props.parameters.SHOW_MANUFACTURER
            ? props.item.article.manufacturer.name
            : ""}
        </Col>
        <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}>
          <Tag
            color={flybyUtils.getAvailabilityColor(
              props.item.state,
              props.item.locallyAvailable,
              undefined,
              0,
              false
            )}
          >
            {flybyUtils.getAvailabilityText(
              props.item.state,
              undefined,
              0,
              false,
              undefined
            )}
          </Tag>
          <br />
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {props.item.quantity}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(props.item.grossUnitPrice, 2)}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(props.item.discount, 2)}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(props.item.netUnitPrice, 2)}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(props.item.netAmount, 2)}
        </Col>
        <Col span={1} style={{ paddingLeft: 5, textAlign: "right" }}></Col>
      </Row>

      <Row
        key={"availabilityDescription" + props.item.id}
        style={
          props.item.consignmentPrice > 0
            ? { padding: 5 }
            : {
                padding: 5,
                borderBottom: "solid",
                borderBottomColor: "#e8e8e8",
                borderBottomWidth: "1px",
              }
        }
      >
        <Col span={3} style={{ paddingTop: 6 }}></Col>
        <Col
          span={10}
          style={{
            paddingLeft: 5,
            paddingTop: 6,
            borderTop: "dashed",
            borderTopColor: "#e8e8e8",
            borderTopWidth: "1px",
          }}
        >
          <Text strong>EAN(s) : </Text>
          {flybyUtils.getFirstEan(props.item.article.eans)}
          <br />
          {showPlatformList && props.item.locallyAvailable && (
            <span>
              <Text strong>Provenance : </Text>
              {props.item.availabilityDescription}
              <br />
            </span>
          )}
          {showPlatformList && !props.item.locallyAvailable && (
            <span>
              <Text strong>Provenance : </Text>
              <Text strong type="danger">
                <WarningOutlined /> Autre plateforme
              </Text>
              <br />
            </span>
          )}
        </Col>
        <Col
          span={11}
          style={{
            paddingLeft: 5,
            paddingTop: 6,
            borderTop: "dashed",
            borderTopColor: "#e8e8e8",
            borderTopWidth: "1px",
          }}
        ></Col>
      </Row>

      {props.item.consignmentPrice > 0 && (
        <Row
          key={"consignment" + props.item.id}
          style={{
            padding: 5,
            borderBottom: "solid",
            borderBottomColor: "#e8e8e8",
            borderBottomWidth: "1px",
          }}
        >
          <Col span={3} style={{ paddingTop: 6 }}></Col>
          <Col
            span={4}
            style={{
              paddingLeft: 5,
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          >
            Consigne
          </Col>
          <Col
            span={3}
            style={{
              paddingLeft: 5,
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
          <Col
            span={3}
            style={{
              paddingLeft: 5,
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          >
            {props.item.quantity}
          </Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          >
            {flybyUtils.numberFormat(props.item.consignmentPrice, 2)}
          </Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          >
            {flybyUtils.numberFormat(props.item.consignmentAmountExclTaxes, 2)}
          </Col>
          <Col
            span={1}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
        </Row>
      )}
    </div>
  );
};

export const ConfirmedSalesOrderViewPlatformHeader = (props: {
  orderPlatforms: IOrderPlatforms;
}) => {
  return (
    <Row
      style={{
        fontWeight: "bold",
        background: "#f2f2f2",
        padding: 5,
        borderBottom: "solid",
        borderBottomColor: "#e8e8e8",
        borderBottomWidth: "1px",
      }}
    >
      <Col span={19} style={{ paddingTop: 6 }}>
        <Text strong>Plateforme : </Text>
        {props.orderPlatforms.platform !== undefined
          ? props.orderPlatforms.platform.name
          : ""}
        <br />
        <Text strong>Votre numéro commande : </Text>
        {props.orderPlatforms.reference}
        <br />
        <Text strong>Commentaire : </Text>
        {props.orderPlatforms.reference2}
        <br />
        <Text strong>Mode d'expédition : </Text>
        {props.orderPlatforms.shippingMethod !== undefined
          ? props.orderPlatforms.shippingMethod.name
          : ""}
        <br />
        <Text strong>Statut : </Text>
        <Tag
          color={flybyUtils.getAvailabilityColor(
            props.orderPlatforms.state,
            true,
            null,
            0,
            false
          )}
        >
          {flybyUtils.getStateOrderText(props.orderPlatforms.state)}
        </Tag>
      </Col>
      <Col span={5} style={{ textAlign: "right" }}></Col>
    </Row>
  );
};

export const ConfirmedSalesOrderViewFooter = (props: {
  stepConf: IStep;
  confirmOrder: IConfirmedSalesOrder;
}) => {
  return (
    <>
      <Row
        style={{
          fontWeight: "bold",
          background: "#ECECEC",
          padding: 5,
          borderBottom: "solid",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: "1px",
        }}
      >
        <Col span={3} style={{ paddingTop: 5 }}>
          TOTAL
        </Col>
        <Col span={4} style={{ paddingLeft: 5, paddingTop: 6 }}></Col>
        <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}></Col>
        <Col
          span={3}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        ></Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          Quantité
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          Total € HT
        </Col>
        {props.stepConf.option.shippingCost && (
          <Col
            span={2}
            style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
          >
            Frais de port
          </Col>
        )}
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          TVA
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          Total € TTC
        </Col>
      </Row>
      <Row
        style={{
          // fontWeight: "bold",
          // background: "#ECECEC",
          padding: 5,
          // borderBottom: "solid",
          // borderBottomColor: "#e8e8e8",
          // borderBottomWidth: "1px",
        }}
      >
        <Col span={3} style={{ paddingTop: 5 }}></Col>
        <Col span={4} style={{ paddingLeft: 5, paddingTop: 6 }}></Col>
        <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}></Col>
        <Col
          span={3}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        ></Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {props.confirmOrder.totalQuantity}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(props.confirmOrder.totalAmountExclTaxes, 2)}
        </Col>
        {props.stepConf.option.shippingCost && (
          <Col
            span={2}
            style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
          >
            {flybyUtils.numberFormat(
              props.confirmOrder.totalShippingCostsExclTaxes,
              2
            )}
          </Col>
        )}
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(
            props.confirmOrder.totalPaymentTaxesAmount,
            2
          )}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(
            props.confirmOrder.totalPaymentAmountInclTaxes,
            2
          )}
        </Col>
      </Row>
    </>
  );
};

export const ConfirmedSalesOrderViewHeader = (props: {
  parameters: DefaultParameter;
}) => {
  return (
    <Row style={{ fontWeight: "bold", background: "#ECECEC", padding: 5 }}>
      <Col span={3} style={{}}>
        Référence
      </Col>
      <Col span={4} style={{ paddingLeft: 5 }}>
        Libellé
      </Col>
      <Col span={3} style={{ paddingLeft: 5 }}>
        {props.parameters.SHOW_MANUFACTURER ? "Fabricant" : ""}
      </Col>
      <Col span={3} style={{ paddingLeft: 5 }}>
        Délai
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Quantité
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Prix tarif € HT
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Remise %
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Prix net € HT
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Montant € HT
      </Col>
      <Col span={1} style={{ paddingLeft: 5, textAlign: "right" }}></Col>
    </Row>
  );
};

export const ConfirmedSalesOrderViewAddress = (props: {
  confirmOrder: IConfirmedSalesOrder;
}) => {
  return (
    <Row style={{ padding: 5 }}>
      <Col span={11}>
        <Divider orientation="left">Adresse de livraison</Divider>
        {props.confirmOrder.deliveryCompanyName}
        <br />
        {props.confirmOrder.deliveryAddress1}
        <br />
        {props.confirmOrder.deliveryAddress2}
        <br />
        {props.confirmOrder.deliveryZipCode}
        <br />
        {props.confirmOrder.deliveryCity}
        <br />
      </Col>
      <Col span={2}></Col>
      <Col span={11}>
        <Divider orientation="left">Adresse de facturation</Divider>
        {props.confirmOrder.billingCompanyName}
        <br />
        {props.confirmOrder.billingAddress1}
        <br />
        {props.confirmOrder.billingAddress2}
        <br />
        {props.confirmOrder.billingZipCode}
        <br />
        {props.confirmOrder.billingCity}
        <br />
      </Col>
    </Row>
  );
};
