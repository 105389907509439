// import Sider from "antd/lib/layout/Sider";
import Wishlist from "../Wishlist";
import { useEffect, useState } from "react";
import { Layout } from "antd";

/**
 * Dans ce composant, soit la valeur pour l'attribut "Collapsed"
 * vient du context quand celui-ci est chargé, soit il vient du menu
 * quand on choisi manuellement de replier ou déplier le sider.
 * Les deux valeurs sont transmisent par les props
 */

const { Sider } = Layout;

interface CustomizedSiderProps {
  showBasketDefault: boolean | undefined;
  showBasket: boolean | undefined;
}

const CustomizedSider = ({
  showBasketDefault,
  showBasket,
}: CustomizedSiderProps): JSX.Element => {
  const [showWishlistState, setShowWishlistState] = useState<
    boolean | undefined
  >(showBasketDefault ? showBasketDefault : undefined);

  useEffect(() => {
    if (showBasketDefault !== undefined) {
      setShowWishlistState(!showBasketDefault);
    }
    if (showBasket !== undefined) {
      setShowWishlistState(showBasket);
    }
  }, [showBasket, showBasketDefault]);

  return (
    <Sider
      collapsed={showWishlistState}
      collapsedWidth={0}
      style={{
        background: "#fff",
      }}
      width={250}
    >
      <Wishlist />
    </Sider>
  );
};

export default CustomizedSider;
