import Modal from "antd/lib/modal/Modal";
import React from "react";
import {
  Viewer,
  Worker,
  LocalizationMap,
  LocalizationContext,
  ThemeContext,
} from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import fr_FR from "./PdfViewerTranslation/fr_FR.json";

import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

const PdfViewer = (props: {
  fileString: string;
  urlType?: boolean;
  visible: boolean;
  fileBlob?: Blob | MediaSource;
  onClosePrint: () => void;
}): React.ReactElement => {
  const { visible, fileBlob, fileString, urlType, onClosePrint } = props;
  const [currentTheme, setCurrentTheme] = React.useState("light");
  const [l10n, setL10n] = React.useState(fr_FR as any as LocalizationMap);

  const localizationContext = { l10n, setL10n };
  const themeContext = { currentTheme, setCurrentTheme };

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return (
    <Modal
      destroyOnClose
      closable={false}
      width={850}
      open={visible}
      okButtonProps={{ disabled: true, hidden: true }}
      bodyStyle={{ margin: 0, padding: 0 }}
      onCancel={onClosePrint}
      cancelButtonProps={{ type: "primary" }}
      cancelText="Annuler"
      style={{ top: 20 }}
    >
      <ThemeContext.Provider value={themeContext}>
        <LocalizationContext.Provider value={localizationContext}>
          <Worker workerUrl="pdf.worker.min.js">
            <div
              style={{
                height: "800px",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "#eeeeee",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  padding: "4px",
                }}
              >
                <Toolbar />
              </div>
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                }}
              >
                <Viewer
                  fileUrl={
                    urlType
                      ? fileString.toString()
                      : fileBlob
                      ? URL.createObjectURL(fileBlob)
                      : ""
                  }
                  plugins={[toolbarPluginInstance]}
                />
              </div>
            </div>
          </Worker>
        </LocalizationContext.Provider>
      </ThemeContext.Provider>
    </Modal>
  );
};

export default PdfViewer;
