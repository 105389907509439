import React, { useCallback, useEffect, useRef, useState } from "react";
import LoginForm from "./LoginForm";
import { Modal } from "antd";
import OpenAccount from "./OpenAccount";
import Footer from "../Footer";
import { getPublicHtmlPage } from "../../Utils/ParameterServiceFunctional";
import { useMarketParameter } from "../../Utils/MarketParameterContext";
import { login } from "./LoginServiceFunctional";

const defaultLoginTemplate = `
<style>
  #logoLogin {
    margin-top : 150px;
    width: 100%;
    text-align: center;
    display:block;
  }
  #logoLogin img {
    width: 150px;
    margin-bottom: 5px;
  }
  #loginContainer {
    background: white;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>
<div id='logoLogin'><img src='/api/market/logo' alt='logo' /></div>
`;
interface LoginProps {
  onLogin: (isLoging: boolean) => void;
}

const Login: React.FC<LoginProps> = (props: {
  onLogin: (isLoging: boolean) => void;
}) => {
  const { onLogin } = props;
  const [page, setPage] = useState<string>(defaultLoginTemplate);
  const [openModalAccount, setOpenModalAccount] = useState(false);
  const { TITLE, FAVICO } = useMarketParameter();

  const wrapper = useRef<HTMLDivElement>(null);

  const addEventListener = useCallback(
    (selector: string, listener: () => void) =>
      wrapper.current
        ?.querySelector(selector)
        ?.addEventListener("click", listener),
    // eslint-disable-next-line
    [wrapper.current]
  );

  const closeModalAccount = useCallback(() => {
    setOpenModalAccount(false);
  }, []);

  const loginToGuest = useCallback(() => {
    const loginInfos = {
      username: "GUEST",
      password: "3!Y$zZ96rdT($tM#",
    };

    login(loginInfos).then((success: boolean) => {
      if (success) {
        onLogin(true);
      }
    });
  }, [onLogin]);

  useEffect(() => {
    document.title = TITLE;
    var link: any = document.querySelector("link[rel~='icon']");

    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = FAVICO;

    getPublicHtmlPage("login-template.html").then((response: string) => {
      if (response !== "") {
        setPage(response);
      }
    });
  }, [FAVICO, TITLE]);

  useEffect(() => {
    addEventListener("#guestLogin", () => {
      loginToGuest();
    });

    addEventListener("#openAccount", () => {
      setOpenModalAccount(true);
    });
  }, [addEventListener, loginToGuest]);

  return (
    <div ref={wrapper}>
      <div dangerouslySetInnerHTML={{ __html: page }}></div>

      <LoginForm onLogin={onLogin} />
      <Footer />

      <Modal
        open={openModalAccount}
        destroyOnClose
        closable={false}
        title="Demande d'ouverture d'un compte"
        style={{ top: 20 }}
        width={450}
        bodyStyle={{ height: 650 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <OpenAccount closeModal={closeModalAccount} />
      </Modal>
    </div>
  );
};

export default Login;
