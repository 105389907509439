import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "../../../App.css";
import { Descriptions, Row, Col, Card, Image, Button } from "antd";

import { withBasket, BasketContext } from "../../../Utils/BasketProvider";
import ICatalogSearch from "../model/ICatalogSearch";
import IVehicleNode from "../model/IVehicleNode";
import IVehicleType from "../model/IVehicleType";
import CatalogService from "../CatalogService";

interface CatalogVehicleInfoProps {
  catalogSearch: ICatalogSearch;
}

const CatalogVehicleInfo = ({
  catalogSearch,
}: CatalogVehicleInfoProps): React.ReactElement => {
  const basketContext = useContext(BasketContext);

  const catalogService = useMemo(() => {
    return new CatalogService();
  }, []);
  // const catalogService: CatalogService = new CatalogService();

  const [, setDataLoadingState] = useState<boolean>(false);
  const [manufacturerState, setManufacturerState] = useState<
    IVehicleNode | undefined
  >(undefined);
  const [vehicleModelState, setVehicleModelState] = useState<
    IVehicleNode | undefined
  >(undefined);
  const [vehicleTypeState, setVehicleTypeState] = useState<
    IVehicleType | undefined
  >(undefined);

  const onCancelLevel2 = useCallback(() => {
    let catalogSearch: ICatalogSearch = {
      ...basketContext.catalogSearch,
      level2Id: undefined,
      level2Name: undefined,
      level3Id: undefined,
      level3Name: undefined,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      modelYear: undefined,
    };
    basketContext.setCatalogSearch(catalogSearch);
    basketContext.setSelectedArticle(undefined);
  }, [basketContext]);

  const onCancelLevel3 = useCallback(() => {
    let catalogSearch: ICatalogSearch = {
      ...basketContext.catalogSearch,
      level3Id: undefined,
      level3Name: undefined,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      modelYear: undefined,
    };
    basketContext.setCatalogSearch(catalogSearch);
    basketContext.setSelectedArticle(undefined);
  }, [basketContext]);

  const onCancelYear = useCallback(() => {
    let catalogSearch: ICatalogSearch = {
      ...basketContext.catalogSearch,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      modelYear: undefined,
    };
    basketContext.setCatalogSearch(catalogSearch);
    basketContext.setSelectedArticle(undefined);
  }, [basketContext]);

  const onCancelLevel5 = useCallback(() => {
    let catalogSearch: ICatalogSearch = {
      ...basketContext.catalogSearch,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      /*modelYear: undefined*/
    };
    basketContext.setCatalogSearch(catalogSearch);
    basketContext.setSelectedArticle(undefined);
  }, [basketContext]);

  const loadVehicleType = useCallback(
    (vehicleTypeId: number) => {
      catalogService.findVehicleTypeById(vehicleTypeId).then((result: any) => {
        if (result !== undefined) {
          setDataLoadingState(false);
          setVehicleTypeState(result);
        }
      });
    },
    [catalogService]
  );

  const loadVehicleNode = useCallback(
    (type: string, vehicleNodeId: number) => {
      catalogService.findVehicleNodeById(vehicleNodeId).then((result: any) => {
        if (result !== undefined) {
          switch (type) {
            case "manufacturer":
              setDataLoadingState(false);
              setManufacturerState(result);
              break;
            case "vehicleModel":
              setDataLoadingState(false);
              setVehicleModelState(result);
              break;
          }
        }
      });
    },
    [catalogService]
  );

  useEffect(() => {
    if (catalogSearch.level1Id !== undefined) {
      loadVehicleNode("manufacturer", catalogSearch.level1Id);
    }
    if (catalogSearch.level2Id !== undefined) {
      loadVehicleNode("vehicleModel", catalogSearch.level2Id);
    }
    if (catalogSearch.level3Id !== undefined) {
      loadVehicleType(catalogSearch.level3Id);
    }
  }, [
    catalogSearch.level1Id,
    catalogSearch.level2Id,
    catalogSearch.level3Id,
    loadVehicleNode,
    loadVehicleType,
  ]);

  return (
    <BasketContext.Consumer>
      {(contextValues) => (
        <Card
          size="small"
          title={
            contextValues.catalogSearch.queryType === "vehicleNode"
              ? "Véhicule sélectionné"
              : "Sélection"
          }
          // style={{ marginTop: 5 }}
          bordered={true}
          headStyle={{ background: "rgb(236, 236, 236)" }}
        >
          <div
            style={{
              marginLeft: -13,
              marginRight: -13,
              marginBottom: -13,
              marginTop: -13,
            }}
          >
            <Descriptions
              column={2}
              layout="vertical"
              size="small"
              bordered
              className="custom-description"
            >
              {manufacturerState !== undefined &&
                contextValues.catalogSearch.level1Id !== undefined && (
                  <Descriptions.Item
                    label={
                      contextValues.catalogSearch.queryType === "vehicleNode"
                        ? "Marque"
                        : "Type"
                    }
                    span={2}
                  >
                    <Row align="middle">
                      <Col span={5}>
                        <Image
                          src={
                            "/api/public/catalog/" + manufacturerState.imageName
                          }
                          alt={manufacturerState.description}
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col span={19} style={{ paddingLeft: 5 }}>
                        <Button
                          onClick={onCancelLevel2}
                          type={"link"}
                          style={{ margin: 0, padding: 0 }}
                        >
                          {manufacturerState?.description}
                        </Button>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                )}
              {vehicleModelState !== undefined &&
                contextValues.catalogSearch.level2Id !== undefined && (
                  <Descriptions.Item
                    label={
                      contextValues.catalogSearch.queryType === "vehicleNode"
                        ? "Modèle"
                        : "Marque"
                    }
                    span={2}
                  >
                    <Row align="middle">
                      <Col span={5}>
                        {vehicleModelState.imageName !== null &&
                          contextValues.catalogSearch.level2Id !==
                            undefined && (
                            <Image
                              src={
                                "/api/public/catalog/" +
                                vehicleModelState.imageName
                              }
                              alt={vehicleModelState.description}
                              style={{ width: "100%" }}
                            />
                          )}
                      </Col>
                      <Col span={19} style={{ paddingLeft: 5 }}>
                        <Button
                          onClick={onCancelLevel3}
                          type={"link"}
                          style={{ margin: 0, padding: 0 }}
                        >
                          {vehicleModelState.description}
                        </Button>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                )}
              {vehicleTypeState !== undefined &&
                contextValues.catalogSearch.level3Id !== undefined && (
                  <Descriptions.Item
                    label={
                      contextValues.catalogSearch.queryType === "vehicleNode"
                        ? "Type"
                        : "Modèle"
                    }
                    span={2}
                  >
                    <div
                      style={{
                        width: "95%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Button
                        onClick={onCancelYear}
                        type={"link"}
                        style={{ margin: 0, padding: 0 }}
                      >
                        {vehicleTypeState.description}
                      </Button>
                    </div>
                  </Descriptions.Item>
                )}
              {contextValues.catalogSearch.plateNumber !== undefined && (
                <Descriptions.Item label="Immatriculation" span={2}>
                  <div
                    style={{
                      width: "95%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {contextValues.catalogSearch.plateNumber}
                  </div>
                </Descriptions.Item>
              )}
              {contextValues.catalogSearch.modelYear !== undefined && (
                <Descriptions.Item label="Année" span={2}>
                  <Button
                    onClick={onCancelLevel5}
                    type={"link"}
                    style={{ margin: 0, padding: 0 }}
                  >
                    {contextValues.catalogSearch.modelYear}
                  </Button>
                </Descriptions.Item>
              )}
            </Descriptions>
          </div>
        </Card>
      )}
    </BasketContext.Consumer>
  );
};

export default withBasket(CatalogVehicleInfo);

// import React from "react";
// import "../../../App.css";
// import { Descriptions, Row, Col, Card, Image } from "antd";

// import { withBasket, BasketContext } from "../../../Utils/BasketProvider";
// import ICatalogSearch from "../model/ICatalogSearch";
// import IVehicleNode from "../model/IVehicleNode";
// import IVehicleType from "../model/IVehicleType";
// import CatalogService from "../CatalogService";

// class CatalogVehicleInfo extends React.Component<
//   {
//     catalogSearch: ICatalogSearch;
//   },
//   {
//     dataLoading: boolean;
//     manufacturer: IVehicleNode | undefined;
//     vehicleModel: IVehicleNode | undefined;
//     vehicleType: IVehicleType | undefined;
//   }
// > {
//   private catalogService: CatalogService = new CatalogService();

//   constructor(props: { catalogSearch: ICatalogSearch }) {
//     super(props);

//     this.state = {
//       dataLoading: true,
//       manufacturer: undefined,
//       vehicleModel: undefined,
//       vehicleType: undefined,
//     };
//   }

//   componentDidMount() {
//     if (this.props.catalogSearch.level1Id !== undefined) {
//       this.loadVehicleNode("manufacturer", this.props.catalogSearch.level1Id);
//     }

//     if (this.props.catalogSearch.level2Id !== undefined) {
//       this.loadVehicleNode("vehicleModel", this.props.catalogSearch.level2Id);
//     }

//     if (this.props.catalogSearch.level3Id !== undefined) {
//       this.loadVehicleType(this.props.catalogSearch.level3Id);
//     }
//   }

//   componentWillReceiveProps(newProps: { catalogSearch: ICatalogSearch }) {
//     if (
//       newProps.catalogSearch.level1Id !== undefined &&
//       newProps.catalogSearch.level1Id !== this.props.catalogSearch.level1Id
//     ) {
//       this.loadVehicleNode("manufacturer", newProps.catalogSearch.level1Id);
//     }

//     if (
//       newProps.catalogSearch.level2Id !== undefined &&
//       newProps.catalogSearch.level2Id !== this.props.catalogSearch.level2Id
//     ) {
//       this.loadVehicleNode("vehicleModel", newProps.catalogSearch.level2Id);
//     }

//     if (
//       newProps.catalogSearch.level3Id !== undefined &&
//       newProps.catalogSearch.level3Id !== this.props.catalogSearch.level3Id
//     ) {
//       this.loadVehicleType(newProps.catalogSearch.level3Id);
//     }
//   }

//   render() {
//     return (
//       <BasketContext.Consumer>
//         {(contextValues) => (
//           <Card
//             size="small"
//             title={
//               contextValues.catalogSearch.queryType === "vehicleNode"
//                 ? "Véhicule sélectionné"
//                 : "Sélection"
//             }
//             // style={{ marginTop: 5 }}
//             bordered={true}
//             headStyle={{ background: "rgb(236, 236, 236)" }}
//           >
//             <div
//               style={{
//                 marginLeft: -13,
//                 marginRight: -13,
//                 marginBottom: -13,
//                 marginTop: -13,
//               }}
//             >
//               <Descriptions
//                 column={2}
//                 layout="vertical"
//                 size="small"
//                 bordered
//                 className="custom-description"
//               >
//                 {this.state.manufacturer !== undefined &&
//                   contextValues.catalogSearch.level1Id !== undefined && (
//                     <Descriptions.Item
//                       label={
//                         contextValues.catalogSearch.queryType === "vehicleNode"
//                           ? "Marque"
//                           : "Type"
//                       }
//                       span={2}
//                     >
//                       <Row align="middle">
//                         <Col span={5}>
//                           <Image
//                             src={
//                               "/api/public/catalog/" +
//                               this.state.manufacturer.imageName
//                             }
//                             alt={this.state.manufacturer.description}
//                             style={{ width: "100%" }}
//                           />
//                         </Col>
//                         <Col span={19} style={{ paddingLeft: 5 }}>
//                           {this.state.manufacturer?.description}
//                         </Col>
//                       </Row>
//                     </Descriptions.Item>
//                   )}
//                 {this.state.vehicleModel !== undefined &&
//                   contextValues.catalogSearch.level2Id !== undefined && (
//                     <Descriptions.Item
//                       label={
//                         contextValues.catalogSearch.queryType === "vehicleNode"
//                           ? "Modèle"
//                           : "Marque"
//                       }
//                       span={2}
//                     >
//                       <Row align="middle">
//                         <Col span={5}>
//                           {this.state.vehicleModel.imageName !== null &&
//                             contextValues.catalogSearch.level2Id !==
//                               undefined && (
//                               <Image
//                                 src={
//                                   "/api/public/catalog/" +
//                                   this.state.vehicleModel.imageName
//                                 }
//                                 alt={this.state.vehicleModel.description}
//                                 style={{ width: "100%" }}
//                               />
//                             )}
//                         </Col>
//                         <Col span={19} style={{ paddingLeft: 5 }}>
//                           {this.state.vehicleModel.description}
//                         </Col>
//                       </Row>
//                     </Descriptions.Item>
//                   )}
//                 {this.state.vehicleType !== undefined &&
//                   contextValues.catalogSearch.level3Id !== undefined && (
//                     <Descriptions.Item
//                       label={
//                         contextValues.catalogSearch.queryType === "vehicleNode"
//                           ? "Type"
//                           : "Modèle"
//                       }
//                       span={2}
//                     >
//                       <div
//                         style={{
//                           width: "95%",
//                           overflow: "hidden",
//                           textOverflow: "ellipsis",
//                         }}
//                       >
//                         {this.state.vehicleType.description}
//                       </div>
//                     </Descriptions.Item>
//                   )}
//                 {contextValues.catalogSearch.plateNumber !== undefined && (
//                   <Descriptions.Item label="Immatriculation" span={2}>
//                     <div
//                       style={{
//                         width: "95%",
//                         overflow: "hidden",
//                         textOverflow: "ellipsis",
//                       }}
//                     >
//                       {contextValues.catalogSearch.plateNumber}
//                     </div>
//                   </Descriptions.Item>
//                 )}
//                 {contextValues.catalogSearch.modelYear !== undefined && (
//                   <Descriptions.Item label="Année" span={2}>
//                     <div
//                       style={{
//                         width: "95%",
//                         overflow: "hidden",
//                         textOverflow: "ellipsis",
//                       }}
//                     >
//                       {contextValues.catalogSearch.modelYear}
//                     </div>
//                   </Descriptions.Item>
//                 )}
//               </Descriptions>
//             </div>
//           </Card>
//         )}
//       </BasketContext.Consumer>
//     );
//   }

//   private loadVehicleType = (vehicleTypeId: number) => {
//     this.catalogService
//       .findVehicleTypeById(vehicleTypeId)
//       .then((result: any) => {
//         if (result !== undefined) {
//           this.setState({
//             dataLoading: false,
//             vehicleType: result,
//           });
//         }
//       });
//   };

//   private loadVehicleNode = (type: string, vehicleNodeId: number) => {
//     this.catalogService
//       .findVehicleNodeById(vehicleNodeId)
//       .then((result: any) => {
//         if (result !== undefined) {
//           switch (type) {
//             case "manufacturer":
//               this.setState({
//                 dataLoading: false,
//                 manufacturer: result,
//               });
//               break;
//             case "vehicleModel":
//               this.setState({
//                 dataLoading: false,
//                 vehicleModel: result,
//               });
//               break;
//           }
//         }
//       });
//   };
// }

// export default withBasket(CatalogVehicleInfo);
