interface ICriterion {

  id: number,
  code: string,
  description: string,
  type: Type,
  format: Format,
  decimal: number,
  unit: string,
  values: string[] | null | undefined
}

export default ICriterion;

export enum Format {
  STRING = 'STRING', NUMBER = 'NUMBER', IMAGE = 'IMAGE', URL = 'URL', PRINCIPAL_IMAGE = 'PRINCIPAL_IMAGE'
}

export enum Type {
  VEHICLE_TYPE = 'VEHICLE_TYPE', ARTICLE = 'ARTICLE'
}

