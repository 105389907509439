import IFamily from "../containers/catalog/model/IFamily";


export default interface TreeData {
  key: string;
  value: string;
  title: string;
  children: TreeData[];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function filterTreeNode(searchValue: string, node: any) {
  return node.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
}

export function formatFamily(data: IFamily[]): TreeData[] {
  const treeData: TreeData[] = [];

  data.forEach((family: IFamily) => {
    if (
      family.children !== null &&
      family.children !== undefined &&
      family.children.length > 0
    ) {
      const node: TreeData = {
        key: family.id.toString(),
        value: family.id.toString(),
        title: family.name,
        children: formatFamily(family.children),
      };
      treeData.push(node);
    } else {
      const node: TreeData = {
        key: family.id.toString(),
        value: family.id.toString(),
        title: family.name,
        children: [],
      };
      treeData.push(node);
    }
  });

  return treeData;
}
