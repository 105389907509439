import React from 'react';
import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import { Table, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import ICatalogSearch from '../model/ICatalogSearch';
import CatalogService from '../CatalogService';
import IData from '../../../Utils/IData';
import FlybyUtils from '../../../Utils/FlybyUtils';
import IVehicleType from '../model/IVehicleType';

class CatalogVehicleType extends React.Component<
  {
    catalogSearch: ICatalogSearch,
    setCatalogSearch: any,
  },
  {
    dataLoading: boolean,
    data: IData
  }>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private catalogService: CatalogService = new CatalogService();

  private columns: ColumnProps<IVehicleType>[] = [
    {
      title: 'Libellé',
      dataIndex: 'description',
      render: (text: string, record: IVehicleType) => <Button onClick={() => this.onSearch(record)} type={"link"}>{record.description}</Button>,
    },
  ];

  constructor(props: { catalogSearch: ICatalogSearch, setCatalogSearch: any }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: this.flybyUtils.getEmptyData(),
    };
  }

  componentDidMount() {


    if (this.props.catalogSearch.queryType === 'vehicleNode') {
      this.columns.push({
        title: 'Depuis le',
        dataIndex: 'startDate',
        render: (text: string, record: IVehicleType) => <div>{this.flybyUtils.convertNumberToDateMMYYYY(record.startDate)}</div>,
        width: 120,
        align: "right",
      },
        {
          title: 'Jusqu\'à',
          dataIndex: 'endDate',
          render: (text: string, record: IVehicleType) => <div>{this.flybyUtils.convertNumberToDateMMYYYY(record.endDate)}</div>,
          width: 120,
          align: "right",
        }
      );

    }

    if ((this.props.catalogSearch.queryType === 'vehicleNode' || this.props.catalogSearch.queryType === 'characteristic') && this.props.catalogSearch.level2Id !== undefined) {
      this.loadVehicleType(this.props.catalogSearch.level2Id, this.flybyUtils.getDefaultPageSize(), 0);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => contextValues.catalogSearch.level2Id !== undefined &&
          <Table rowKey="id" loading={this.state.dataLoading} columns={this.columns} dataSource={this.state.data.content}
            onChange={this.onChange} pagination={{ current: (this.state.data.number + 1), pageSize: this.state.data.size, total: this.state.data.totalElements }}
            style={{ border: '1px solid #e8e8e8', borderWidth: '1px 1px 1px 1px' }} size="middle" />
        }
      </BasketContext.Consumer>
    );
  }

  private onSearch = (item: IVehicleType) => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level3Id: item.id,
      level3Name: item.description
    }
    this.props.setCatalogSearch(catalogSearch);
  }

  private onChange = (pagination: any, filters: Record<never, string[]>, sorter: any, extra: any) => {
    if (this.props.catalogSearch.level2Id !== undefined) {
      pagination = this.flybyUtils.getCurrentPage(pagination);
      this.loadVehicleType(this.props.catalogSearch.level2Id, pagination.pageSize, pagination.current);
    }
  }

  private loadVehicleType = (parentId: number, pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    let sort = 'startDate,asc';
    if (this.props.catalogSearch.queryType === 'characteristic') {
      sort = 'description,asc'
    }

    this.catalogService.findVehicleTypeByVehiculeNode(undefined, parentId, pageSize, page, sort)
      .then((result: any) => {
        if (result !== undefined) {
          this.setState({
            dataLoading: false,
            data: result,
          });

        }
        else {
          this.setState({
            dataLoading: false
          });
        }
      });
  }

}

export default withBasket(CatalogVehicleType);