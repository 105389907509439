import React from "react";
import "../../App.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Row, Col } from "antd";

import SalesOrderPreview from "./SalesOrderPreview";
import IStep from "./IStep";
import { MarketParameterContext } from "../../Utils/MarketParameterContext";

class Step2 extends React.Component<
  {
    stepConf: IStep | undefined;
    values: any;
    onNextStep: any;
  },
  {}
> {
  render() {
    return (
      <MarketParameterContext.Consumer>
        {(parameters) => (
          <div>
            <Row
              style={{
                position: "sticky",
                top: -20,
                backgroundColor: "#fff",
                zIndex: 1,
              }}
            >
              <Col span={24}>
                <div style={{ float: "right", margin: 10 }}>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() =>
                      this.props.onNextStep(
                        this.props.stepConf?.prevAction.prevStep
                      )
                    }
                    hidden={!this.props.stepConf?.prevAction.enable}
                    style={{ marginRight: 5 }}
                  >
                    <LeftOutlined />
                    {this.props.stepConf?.prevAction.text}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() =>
                      this.props.onNextStep(
                        this.props.stepConf?.nextAction.nextStep
                      )
                    }
                    hidden={!this.props.stepConf?.nextAction.enable}
                    disabled={
                      this.props.values.wishlistTotal.totalQuantitySelected <= 0
                    }
                  >
                    {this.props.stepConf?.nextAction.text}
                    <RightOutlined />
                  </Button>
                </div>
              </Col>
            </Row>

            <SalesOrderPreview
              values={this.props.values}
              showAddress={false}
              stepConf={this.props.stepConf}
              parameters={parameters}
            />
          </div>
        )}
      </MarketParameterContext.Consumer>
    );
  }
}

export default Step2;
