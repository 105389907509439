/**
 * Composant functional utilisé en doublon
 * d'un composant écrit en classe (WishlistService.tsx)
 */

import IArticle from "./IArticle";
import IPlatform from "./IPlatform";
import IWishlistArticle from "./IWishlistArticles";

export const findByCustomerId = async (): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch("/api/market/wishlists?customer=" + customerId + "&sort=name", {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findArticleByWishlistIdService = async (
  wishlistId: any
): Promise<IPlatform> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch(
    "/api/market/wishlists/" +
      wishlistId +
      "?customer=" +
      customerId +
      "&sort=reference",
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const wishlistTotalService = async (): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch("/api/market/wishlistTotal?customer=" + customerId, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const addWhishlistService = async (
  platformId: string,
  name: string
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  let data = {
    name: name,
    customer: {
      id: customerId,
    },
    platform: {
      id: platformId,
    },
  };

  return fetch("/api/market/wishlists?customer=" + customerId, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const updateWhishlistService = async (whislist: any): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/wishlists/" + whislist.id + "?customer=" + customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "PUT",
      body: JSON.stringify(whislist),
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const deleteWishlistService = async (
  wishlistId: number
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/wishlists/" + wishlistId + "?customer=" + customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "DELETE",
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const addArticleWhishlistService = async (
  item: IArticle,
  quantity: number,
  platformId: number,
  sourceWishlistArticleId: number | undefined
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  let data = {
    article: {
      id: item.id,
    },
    quantity: quantity,
  };

  let uri =
    "/api/market/wishlistArticles?customer=" +
    customerId +
    "&platform=" +
    platformId;
  if (sourceWishlistArticleId !== undefined) {
    uri += "&sourceWishlistArticle=" + sourceWishlistArticleId;
  }

  return fetch(uri, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const deleteArticleWishlistService = async (
  wishlistArticleId: number
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/wishlistArticles/" +
      wishlistArticleId +
      "?customer=" +
      customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "DELETE",
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const updateArticleWhishlistService = async (
  item: IWishlistArticle,
  wishlistId: any,
  quantity: number
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  let data = {
    id: item.id,
    wishlist: {
      id: wishlistId,
    },
    article: {
      id: item.article.id,
    },
    quantity: quantity,
  };

  return fetch(
    "/api/market/wishlistArticles/" + item.id + "?customer=" + customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "PUT",
      body: JSON.stringify(data),
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};
