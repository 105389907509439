import React from "react";
import { withBasket, BasketContext } from "../../../Utils/BasketProvider";
import { List, Card, Col, Image, Row } from "antd";

import ICatalogSearch from "../model/ICatalogSearch";
import CatalogService from "../CatalogService";
import IVehicleNode from "../model/IVehicleNode";

class CatalogVehicleNode extends React.Component<
  {
    catalogSearch: ICatalogSearch;
    setCatalogSearch: any;
  },
  {
    dataLoading: boolean;
    data: IVehicleNode[];
  }
> {
  private catalogService: CatalogService = new CatalogService();

  constructor(props: { catalogSearch: ICatalogSearch; setCatalogSearch: any }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: [],
    };
  }

  componentDidMount() {
    if (
      (this.props.catalogSearch.queryType === "vehicleNode" ||
        this.props.catalogSearch.queryType === "characteristic") &&
      this.props.catalogSearch.level1Id !== undefined
    ) {
      this.loadVehicleNode(0, this.props.catalogSearch.level1Id, 200, 0);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) =>
          contextValues.catalogSearch.level2Id === undefined && (
            <List
              loading={this.state.dataLoading}
              grid={{ gutter: 16, column: 4 }}
              dataSource={this.state.data}
              renderItem={(item: IVehicleNode) => (
                <List.Item>
                  <Card
                    bodyStyle={{ padding: 10 }}
                    hoverable
                    onClick={() => this.onSearch(item)}
                  >
                    <Row align="middle">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={12}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {item.imageName !== null && (
                          <Image
                            src={"/api/public/catalog/" + item.imageName}
                            alt={item.description}
                            style={{
                              width: 100,
                              maxHeight: 60,
                            }}
                          />
                        )}
                        {item.imageName === null && (
                          <Image
                            src={"/api/public/placeholder.jpg"}
                            alt={item.description}
                            style={{ width: 100, maxHeight: 60 }}
                          />
                        )}
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={12}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {item.description}
                      </Col>
                    </Row>
                  </Card>
                </List.Item>
              )}
            />
          )
        }
      </BasketContext.Consumer>
    );
  }

  private onSearch = (item: IVehicleNode) => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level2Id: item.id,
      level2Name: item.description,
    };
    this.props.setCatalogSearch(catalogSearch);
  };

  private loadVehicleNode = (
    deep: number,
    parentId: number | undefined,
    pageSize: number | undefined,
    page: number | undefined
  ) => {
    this.setState({
      dataLoading: true,
    });

    this.catalogService
      .findVehicleNode(
        undefined,
        parentId,
        undefined,
        false,
        pageSize,
        page,
        "rank"
      )
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            dataLoading: false,
            data: result.content,
          });
        } else {
          this.setState({
            dataLoading: false,
          });
        }
      });
  };
}

export default withBasket(CatalogVehicleNode);
