import { notification } from 'antd';

import ISearchStructure from './model/ISearchStructure';
import IArticle from './model/IArticle';
import IFilter from './model/Ifilter';
import ICustomProfil from './model/ICustomProfil';
import ITecdocSearch from './model/ITecdocSearch';
import IVehicle from './model/IVehicle';
import IVehicleType from './model/IVehicleType';

class TecdocService {

  private accessToken = sessionStorage.getItem("tecdocUid");
  private country: string = 'fr';
  private language: string = 'fr';
  private baseUri: string = '/tecdoc-api';

  public async findManufacturerHighlight(pageSize: number | undefined, page: number | undefined): Promise<any> {

    return fetch(this.baseUri + '/manufacturers/highlight?country=' + this.country + '&language=' + this.language
      + '&size=' + pageSize
      + '&page=' + page
      + '&sort=rank,asc', {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findManufacturer(pageSize: number | undefined, page: number | undefined, sort: string): Promise<any> {

    return fetch(this.baseUri + '/manufacturers?country=' + this.country
      + '&language=' + this.language
      + '&size=' + pageSize
      + '&page=' + page
      + '&sort=' + sort, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findTecdocVehicleModelSerie(herNr: number, pageSize: number | undefined, page: number | undefined): Promise<any> {

    return fetch(this.baseUri + '/manufacturers/' + herNr + '/vehicleModelSeries?country=' + this.country
      + '&language=' + this.language
      + '&size=' + pageSize
      + '&page=' + page + "&sort=bez,ASC", {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findTecdocVehicleType(kmodNr: number, filters: string, pageSize: number | undefined, page: number | undefined): Promise<any> {

    return fetch(this.baseUri + '/vehicleModelSeries/' + kmodNr + '/vehicleTypes?country=' + this.country
      + '&language=' + this.language
      + '&filters=' + filters
      + '&size=' + pageSize + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findKeyEntries(tabnr: number, pageSize: number | undefined, page: number | undefined): Promise<any> {

    return fetch(this.baseUri + '/keyEntries?country=' + this.country + '&language=' + this.language + '&tabnr=' + tabnr + '&size=' + pageSize + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findSearchStructure(nodeId: number): Promise<any> {

    return fetch(this.baseUri + '/searchStructures/' + nodeId + '?country=' + this.country + '&language=' + this.language, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findSearchStructures(nodeParentId: number | null, stufe: number, loadChilds: boolean, pageSize: number | undefined, page: number | undefined): Promise<any> {

    let uri = '&size=' + pageSize + '&page=' + page + '&sort=bez,asc&stufe=' + stufe + '&loadchilds=' + loadChilds;
    nodeParentId != null ? uri += '&nodeparentid=' + nodeParentId : uri += '&nodeparentid=';


    return fetch(this.baseUri + '/searchStructures?country=' + this.country + '&language=' + this.language + uri, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findSearchStructuresCustom(nodeParentId: number | null, stufe: number, pageSize: number | undefined, page: number | undefined): Promise<any> {

    let uri = '&size=' + pageSize + '&page=' + page + '&sort=bez,asc&stufe=' + stufe;
    nodeParentId != null ? uri += '&nodeparentid=' + nodeParentId : uri += '&nodeparentid=';


    return fetch(this.baseUri + '/searchStructures/custom?country=' + this.country + '&language=' + this.language + uri, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findSearchStructureWithRoot(nodeId: number): Promise<ISearchStructure> {


    return fetch(this.baseUri + '/searchStructures/withRoot/' + nodeId + '?country=' + this.country + '&language=' + this.language + "&sort=bez,asc", {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticles(nodeId: number, vknzielNr: number, pageSize: number | undefined, page: number | undefined): Promise<ISearchStructure> {

    return fetch(this.baseUri + '/articles?country=' + this.country + '&language=' + this.language + '&nodeid='
      + nodeId + '&vknzielnr='
      + vknzielNr + '&size='
      + pageSize + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticlesByDlNr(nodeId: number, vknzielNr: number, dlNr: string, pageSize: number | undefined, page: number | undefined): Promise<ISearchStructure> {

    return fetch(this.baseUri + '/articles/byDlNr?country=' + this.country + '&language=' + this.language
      + '&nodeid=' + nodeId
      + '&vknzielnr=' + vknzielNr
      + '&dlnr=' + dlNr
      + '&size=' + pageSize + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticlesByDlNrAndKey(nodeId: number, vknzielNr: number, dlNr: string, key: string, pageSize: number | undefined, page: number | undefined): Promise<ISearchStructure> {

    return fetch(this.baseUri + '/articles/byDlNrAndKey?country=' + this.country + '&language=' + this.language
      + '&nodeid=' + nodeId
      + '&vknzielnr=' + vknzielNr
      + '&dlnr=' + dlNr
      + '&key=' + key
      + '&size=' + pageSize + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleInformations(cleanReference: string, dlNr: number): Promise<IArticle> {

    return fetch(this.baseUri + '/articles/' + cleanReference + '/informations?country=' + this.country + '&language=' + this.language
      + '&dlnr=' + dlNr, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleByEanNr(eanNr: string): Promise<IArticle> {

    return fetch(this.baseUri + '/articles/byEanNr/' + eanNr + '?country=' + this.country + '&language=' + this.language, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        return undefined;
      })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleByCleanReference(cleanReference: string, dlNr: number): Promise<IArticle> {



    return fetch(this.baseUri + '/articles/byCleanReference/' + cleanReference
      + '?country=' + this.country
      + '&language=' + this.language
      + '&dlNr=' + dlNr, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        return undefined;
      })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findCustomSuppliersByNodeIdAndVknZielNr(nodeId: number, vknzielNr: number, pageSize: number | undefined, page: number | undefined): Promise<ISearchStructure> {

    return fetch(this.baseUri + '/customSuppliers/byNodeIdAndVknZielNr?country=' + this.country
      + '&nodeid=' + nodeId
      + '&vknzielnr=' + vknzielNr
      + '&size=' + pageSize + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findCriterionByNodeIdAndVknZielNr(nodeId: number, vknzielNr: number, pageSize: number | undefined, page: number | undefined): Promise<ISearchStructure> {

    return fetch(this.baseUri + '/criterion/byNodeIdAndVknZiel?country=' + this.country + '&language=' + this.language
      + '&nodeid=' + nodeId
      + '&vknzielnr=' + vknzielNr
      + '&size=' + pageSize + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findVehicleTypesFilter(kmodNr: number, key: string): Promise<IFilter> {

    return fetch(this.baseUri + '/filters/vehicleTypes/' + kmodNr + '?country=' + this.country + '&language=' + this.language + '&key=' + key, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findVehicleTypesFilterByMCode(mCode: string, key: string): Promise<IFilter> {

    return fetch(this.baseUri + '/filters/vehicleTypes/byMcode/' + mCode + '?country=' + this.country + '&language=' + this.language + '&key=' + key, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findVehicleTypesFilterByMineType(mCode: string, key: string): Promise<IFilter> {

    return fetch(this.baseUri + '/filters/vehicleTypes/byMineType/' + mCode + '?country=' + this.country + '&language=' + this.language + '&key=' + key, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findVehicleModelSeriesFilter(herNr: number, key: string): Promise<IFilter> {

    return fetch(this.baseUri + '/filters/vehicleModelSeries/' + herNr + '?country=' + this.country + '&language=' + this.language + '&key=' + key, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findByUid(uid: string): Promise<ICustomProfil> {

    return fetch(this.baseUri + '/customProfils/byUid/' + uid, {
      headers: {
        'Authorization': 'Bearer ' + uid,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findByVehicleByPlateNumber(plateNumber: string): Promise<IVehicle> {

    return fetch(this.baseUri + '/catdata/vehicles?country=' + this.country + '&language=' + this.language + '&plateNumber=' + plateNumber, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findByVehicleByMotorCode(motorCode: string, filters: string, herNr: string, pageSize: number | undefined, page: number | undefined): Promise<IVehicleType[]> {

    return fetch(this.baseUri + '/engines/' + motorCode + '/vehicleType?country=' + this.country + '&language=' + this.language
      + '&filters=' + filters
      + '&hernr=' + herNr
      + '&size=' + pageSize + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findByVehicleByMineType(mineType: string, filters: string, herNr: string, pageSize: number | undefined, page: number | undefined): Promise<IVehicleType[]> {

    return fetch(this.baseUri + '/customMineTypeMasterdata/' + mineType + '/vehicleType?country=' + this.country + '&language=' + this.language
      + '&filters=' + filters
      + '&hernr=' + herNr
      + '&size=' + pageSize + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async searchVehicle(query: any, queryType: string): Promise<ITecdocSearch> {

    let tecdocSearch: ITecdocSearch = {
      queryType: '',
      query: {
        plateNumber: '',
        mineType: '',
        motorCode: '',
        vin: '',
      },
      vehicle: undefined,
      searchStructure: undefined
    };

    tecdocSearch.queryType = queryType;

    if (tecdocSearch !== undefined && tecdocSearch.query !== undefined) {
      switch (queryType) {
        case "plateNumber":
          tecdocSearch.query.plateNumber = query.toUpperCase();
          tecdocSearch.query.mineType = "";
          tecdocSearch.query.motorCode = "";
          tecdocSearch.query.vin = "";

          let vehicle: IVehicle = await this.searchVehicleByPlateNumber(query);

          if (vehicle === undefined || vehicle === null) {
            notification.error({ message: 'Tecdoc', description: 'Véhicule non trouvé.' });
            tecdocSearch.queryType = "";
            tecdocSearch.query.plateNumber = "";
          }
          else {
            tecdocSearch.vehicle = vehicle;
          }

          break;
        case "mineType":
          tecdocSearch.query.plateNumber = "";
          tecdocSearch.query.mineType = query.toUpperCase();
          tecdocSearch.query.motorCode = "";
          tecdocSearch.query.vin = "";
          break;
        case "motorCode":
          tecdocSearch.query.plateNumber = "";
          tecdocSearch.query.mineType = "";
          tecdocSearch.query.motorCode = query.toUpperCase();
          tecdocSearch.query.vin = "";
          break;
        case "vin":
          tecdocSearch.query.plateNumber = "";
          tecdocSearch.query.mineType = "";
          tecdocSearch.query.motorCode = "";
          tecdocSearch.query.vin = query.toUpperCase();
          break;
        default:
          tecdocSearch.query.plateNumber = "";
          tecdocSearch.query.mineType = "";
          tecdocSearch.query.motorCode = "";
          tecdocSearch.query.vin = "";
          break;
      }
    }

    return tecdocSearch;
  }

  public async searchVehicleByPlateNumber(plateNumber: string): Promise<IVehicle> {
    const vehicle = await this.findByVehicleByPlateNumber(plateNumber);
    return vehicle;
  }

}

export default TecdocService;