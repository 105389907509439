import React from 'react';
import '../../../App.css';
import { Form, Button, Select } from 'antd';

import { BasketContext, withBasket } from '../../../Utils/BasketProvider';
import TecdocService from '../TecdocService';
import ITecdocSearch from '../model/ITecdocSearch';
import ICustomSupplier from '../model/ICustomSupplier';
import ICriteria from '../model/ICriteria';

const { Option, OptGroup } = Select;

class TecdocArticleSearch extends React.Component<
  {
    tecdocSearch: ITecdocSearch,
    onFilter: any
  },
  {
    manufacturersList: [],
    criterionList: [],
    criterionFamillyList: [],
    dataLoading: boolean,
  }>{

  private tecdocService: TecdocService = new TecdocService();

  constructor(props: { tecdocSearch: ITecdocSearch, onFilter: any }) {
    super(props);

    this.state = {
      manufacturersList: [],
      criterionList: [],
      criterionFamillyList: [],
      dataLoading: true,
    };
  }

  componentDidMount() {
    if (this.props.tecdocSearch.vehicle !== undefined && this.props.tecdocSearch.searchStructure !== undefined && this.props.tecdocSearch.vehicle.ktypNr !== undefined) {

      this.setState({
        dataLoading: true,
      });

      let promise1 = this.loadManufacturers(this.props.tecdocSearch.searchStructure.nodeId, this.props.tecdocSearch.vehicle.ktypNr, 50, 0);
      let promise2 = this.loadCriterion(this.props.tecdocSearch.searchStructure.nodeId, this.props.tecdocSearch.vehicle.ktypNr, 50, 0);

      Promise.all([promise1, promise2,]).then((values) => {

        let criterionFamillyList: any = []
        values[1].forEach((criteria: ICriteria) => {
          if (!criterionFamillyList.find((element: string) => element === criteria.bez)) {
            criterionFamillyList.push(criteria.bez);
          }
        });


        this.setState({
          manufacturersList: values[0],
          criterionFamillyList: criterionFamillyList,
          criterionList: values[1],
          dataLoading: false
        });
      });
    }
  }

  render() {

    return (
      <BasketContext.Consumer>
        {(value) =>
          <Form layout={"inline"} onFinish={this.onFilter}>
            <Form.Item name="suppliers">
              <Select placeholder="Fabricants" mode="multiple" style={{ width: 300 }} loading={this.state.dataLoading}>
                {this.state.manufacturersList.map((customSupplier: ICustomSupplier) =>
                  <Option key={customSupplier.id} value={customSupplier.dlnr}>{customSupplier.name}</Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item name="criterion">
              <Select placeholder="Critères" mode="multiple" style={{ width: 300 }} loading={this.state.dataLoading}>
                {this.state.criterionFamillyList.map((familly: string) =>
                  <OptGroup key='highlight' label={<span style={{ color: '#000000', fontWeight: 'bold' }}>{familly}</span>} >
                    {this.state.criterionList.map((criteria: ICriteria) => criteria.bez === familly &&
                      <Option key={criteria.key} value={criteria.key}>{criteria.bezKeyEntry}</Option>
                    )}
                  </OptGroup>
                )}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Filtrer</Button>
            </Form.Item>
          </Form>
        }
      </BasketContext.Consumer>
    );
  }

  private onFilter = (values: any) => {
    this.props.onFilter(values);
  }

  private loadManufacturers = (nodeId: number, vknzielNr: number, pageSize: number | undefined, current: number | undefined) => {

    return this.tecdocService.findCustomSuppliersByNodeIdAndVknZielNr(nodeId, vknzielNr, pageSize, current)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          return result.content;
        }
        else {
          return undefined;
        }
      });
  }

  private loadCriterion = (nodeId: number, vknzielNr: number, pageSize: number | undefined, current: number | undefined) => {

    return this.tecdocService.findCriterionByNodeIdAndVknZielNr(nodeId, vknzielNr, pageSize, current)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          return result.content;
        }
        else {
          return undefined;
        }

      });
  }
}

export default withBasket(TecdocArticleSearch);