import IStep from './IStep';
import ParameterService from '../../Utils/ParameterService';



export const ProgressConf = (): Promise<IStep[]> => {
  let dafaultStep = [
    {
      key: 1,
      enable: true,
      title: "Valider ma commande",
      prevAction: {
        text: "Retour",
        enable: false,
        prevStep: 0
      },
      nextAction: {
        text: "Valider ma commande",
        enable: true,
        nextStep: 4
      },
      option: {
        shippingCost: false,
        AmountInclTaxes: false
      }
    },
    {
      key: 2,
      enable: false,
      title: "Valider les articles",
      prevAction: {
        text: "Retour",
        enable: true,
        prevStep: 1
      },
      nextAction: {
        text: "Suivant",
        enable: true,
        nextStep: 3
      },
      option: {
        shippingCost: false,
        AmountInclTaxes: false
      }
    },
    {
      key: 3,
      enable: false,
      title: "Adresses",
      prevAction: {
        text: "Précédent",
        enable: true,
        prevStep: 2
      },
      nextAction: {
        text: "Suivant",
        enable: true,
        nextStep: 4
      },
      option: {
        shippingCost: false,
        AmountInclTaxes: false
      }
    },
    {
      key: 4,
      enable: true,
      title: "Validation de commande",
      prevAction: {
        text: "Retour",
        enable: true,
        prevStep: 1
      },
      nextAction: {
        text: "Valider",
        enable: true,
        nextStep: 6
      },
      option: {
        shippingCost: false,
        AmountInclTaxes: false
      }
    },
    {
      key: 5,
      enable: false,
      title: "Paiement",
      prevAction: {
        text: "Précédent",
        enable: true,
        prevStep: 4
      },
      nextAction: {
        text: "Suivant",
        enable: true,
        nextStep: 6
      },
      option: {
        shippingCost: false,
        AmountInclTaxes: false
      }
    },
    {
      key: 6,
      enable: true,
      title: "Confirmation de commande",
      prevAction: {
        text: "Retour au panier",
        enable: true,
        prevStep: 1
      },
      nextAction: {
        text: "Suivant",
        enable: false,
        nextStep: 0
      },
      option: {
        shippingCost: false,
        AmountInclTaxes: false
      }
    }
  ];


  const parameterService: ParameterService = new ParameterService();

  return parameterService.findValue("SHOPPING_CART_CONFIGURATION")
    .then((value: string) => {
      if (value !== undefined) {
        return JSON.parse(value);
      }
      else {
        return dafaultStep;
      }
    });

}





/* AllMakes ACR
[
  {
    key: 1,
    enable: true,
    title: "Panier" ,
    prevAction: {
      text : "Retour",
      enable: false,
      prevStep : 0
    },
    nextAction: {
      text : "Suivant",
      enable: true,
      nextStep: 2
    },
      option:{
        shippingCost: true,
        AmountInclTaxes: true
      }
  },
  {
    key: 2,
    enable: true,
    title: "Valider les articles" ,
    prevAction: {
      text : "Précédent",
      enable: true,
      prevStep : 1
    },
    nextAction: {
      text : "Suivant",
      enable: true,
      nextStep : 3
    },
      option:{
        shippingCost: true,
        AmountInclTaxes: true
      }
  },
  {
    key: 3,
    enable: true,
    title: "Adresses" ,
    prevAction: {
      text : "Précédent",
      enable: true,
      prevStep : 2
    },
    nextAction: {
      text : "Suivant",
      enable: true,
      nextStep : 4
    },
      option:{
        shippingCost: true,
        AmountInclTaxes: true
      }
  },
  {
    key: 4,
    enable: true,
    title: "Récapitulatif" ,
    prevAction: {
      text : "Précédent",
      enable: true,
      prevStep : 3
    },
    nextAction: {
      text : "Suivant",
      enable: true,
      nextStep : 5
    },
      option:{
        shippingCost: true,
        AmountInclTaxes: true
      }
  },
  {
    key: 5,
    enable: true,
    title: "Paiement" ,
    prevAction: {
      text : "Précédent",
      enable: true,
      prevStep : 4
    },
    nextAction: {
      text : "Suivant",
      enable: true,
      nextStep : 6
    },
      option:{
        shippingCost: true,
        AmountInclTaxes: true
      }
  },
  {
    key: 6,
    enable: true,
    title: "Confirmation" ,
    prevAction: {
      text : "Précédent",
      enable: true,
      prevStep : 1
    },
    nextAction: {
      text : "Suivant",
      enable: false,
      nextStep : 0
    },
      option:{
        shippingCost: true,
        AmountInclTaxes: true
      }
  }
]
*/


/* IDLP
[
  {
    key: 1,
    enable: true,
    title: "Valider ma commande" ,
    prevAction: {
      text : "Retour",
      enable: false,
      prevStep : 0
    },
    nextAction: {
      text : "Valider ma commande",
      enable: true,
      nextStep: 4
    },
      option:{
        shippingCost: false,
        AmountInclTaxes: false
      }
  },
  {
    key: 2,
    enable: false,
    title: "Valider les articles" ,
    prevAction: {
      text : "Retour",
      enable: true,
      prevStep : 1
    },
    nextAction: {
      text : "Suivant",
      enable: true,
      nextStep : 3
    },
      option:{
        shippingCost: false,
        AmountInclTaxes: false
      }
  },
  {
    key: 3,
    enable: false,
    title: "Adresses" ,
    prevAction: {
      text : "Précédent",
      enable: true,
      prevStep : 2
    },
    nextAction: {
      text : "Suivant",
      enable: true,
      nextStep : 4
    },
      option:{
        shippingCost: false,
        AmountInclTaxes: false
      }
  },
  {
    key: 4,
    enable: true,
    title: "Validation de commande" ,
    prevAction: {
      text : "Retour",
      enable: true,
      prevStep : 1
    },
    nextAction: {
      text : "Valider",
      enable: true,
      nextStep : 6
    },
      option:{
        shippingCost: false,
        AmountInclTaxes: false
      }
  },
  {
    key: 5,
    enable: false,
    title: "Paiement" ,
    prevAction: {
      text : "Précédent",
      enable: true,
      prevStep : 4
    },
    nextAction: {
      text : "Suivant",
      enable: true,
      nextStep : 6
    },
      option:{
        shippingCost: false,
        AmountInclTaxes: false
      }
  },
  {
    key: 6,
    enable: true,
    title: "Confirmation de commande" ,
    prevAction: {
      text : "Retour au panier",
      enable: true,
      prevStep : 1
    },
    nextAction: {
      text : "Suivant",
      enable: false,
      nextStep : 0
    },
      option:{
        shippingCost: false,
        AmountInclTaxes: false
      }
  }
]
*/