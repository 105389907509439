import React from 'react';
import '../../App.css';
import { Col, Row, Spin } from 'antd';

import FlybyUtils from '../../Utils/FlybyUtils';
import DocumentHistoryService from './DocumentHistoryService';
import IDocumentHistoryDetail from './IDocumentHistoryDetail';

class DocumentHistoryDetails extends React.Component<
    {
        documentHistoryId: number,
        documentType: string,
    },
    {
        data: IDocumentHistoryDetail[],
        dataLoading: boolean,
        showForm: boolean,
        reasonList: any,
        selectedLine?: IDocumentHistoryDetail,
    }>{

    private flybyUtils: FlybyUtils = new FlybyUtils();
    private documentHistoryService: DocumentHistoryService = new DocumentHistoryService();


    constructor(props: { documentHistoryId: number, documentType: string }) {
        super(props);

        this.state = {
            data: [],
            dataLoading: true,
            showForm: false,
            reasonList: [],
            selectedLine: undefined,
        };
    }

    componentDidMount() {
        this.searchDocumentHistoryLine();
    }

    render() {
        return (
            <div>
                <Spin spinning={this.state.dataLoading}>
                    <div style={{ fontSize: '0.95em', border: 'solid', borderColor: '#e8e8e8', borderWidth: '1px' }}>
                        <Row style={{ fontWeight: "bold", background: "#ECECEC", padding: 5 }}>
                            <Col span={2} style={{ paddingLeft: 5 }}>Référence</Col>
                            <Col span={2} style={{ paddingLeft: 5 }}>Fabricant</Col>
                            <Col span={6} style={{ paddingLeft: 5 }}>Libellé</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Qté commandée</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Qté livrée</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Qté RAL</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Prix unitaire</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Remise</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>TVA</Col>
                            <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Total TTC</Col>
                        </Row>

                        {this.state.data.map((line: IDocumentHistoryDetail) => line.description !== undefined && line.description.trim().length > 0 &&
                                <Row key={'linea' + line.id} style={{ padding: 5, borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px' }}>
                                    <Col span={2} style={{ paddingLeft: 5, paddingTop: 6 }}>{line.articleReference}</Col>
                                    <Col span={2} style={{ paddingLeft: 5, paddingTop: 6 }}>{line.externalManufacturerCode}</Col>
                                    <Col span={6} style={{ paddingLeft: 5, paddingTop: 6 }}>{line.description}</Col>
                                    <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.quantityOrdered, 2))}</Col>
                                    <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.quantityDelivered, 2))}</Col>
                                    <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.quantityBackordered, 2))}</Col>
                                    <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.netUnitPrice, 2))}</Col>
                                    <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.discount, 2))}</Col>
                                    <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.vatAmount, 2))}</Col>
                                    <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(line.netAmountInclTaxes, 2))}</Col>
                                </Row>
                        )}
                    </div>
                </Spin>
            </div>
        );
    }

    private searchDocumentHistoryLine = () => {
        this.setState({
            dataLoading: true,
        });

        this.documentHistoryService.getDocumentHistoryDetails(this.props.documentHistoryId, 300, 0)
            .then((data: any) => {
                if (data !== undefined) {

                    this.setState({
                        data: data.content.sort((a: any, b: any) => { return a.lineNumber - b.lineNumber }),
                        dataLoading: false
                    });
                }
            });
    }

}

export default DocumentHistoryDetails;        