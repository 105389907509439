import React, { useEffect, useState } from "react";
import "../../App.css";
import {
  Form,
  Table,
  Button,
  Input,
  Typography,
  InputNumber,
  Modal,
  Tooltip,
  Select,
  notification,
  Radio,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import { Link } from "react-router-dom";
import FlybyUtils from "../../Utils/FlybyUtils";
import DocumentHistoryService from "./DocumentHistoryService";
import IData from "../../Utils/IData";
import IDocumentHistoryReturnableArticles from "./IDocumentHistoryReturnableArticles";
import ReturnRequestDetails from "./ReturnRequestDetails";
import IDocumentHistoryReturnReason from "./IDocumentHistoryReturnReason";
import FlybyLayoutForm from "../../Utils/FlybyLayoutForm";
import { RollbackOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const RadioGroup = Radio.Group;
const { Text } = Typography;
const Option = Select.Option;

interface ReturnFormProps {
  visible: boolean;
  onCancel: any;
  onCreate: any;
  reasonList: any;
  line: IDocumentHistoryReturnableArticles;
  confirmLoading: boolean;
}

const ReturnForm: React.FC<ReturnFormProps> = ({
  visible,
  onCancel,
  onCreate,
  line,
  confirmLoading,
}) => {
  const selectLine = line;
  const [form] = Form.useForm();
  const flybyUtils: FlybyUtils = new FlybyUtils();
  const documentHistoryService: DocumentHistoryService =
    new DocumentHistoryService();
  const [reasonList, setReasonList] = useState([]);

  useEffect(() => {
    const loadDataReasons = (line: IDocumentHistoryReturnableArticles) => {
      documentHistoryService
        .getDocumentHistoryReturnReason(line.documentHistoryDetail.id)
        .then((data: any) => {
          if (data !== undefined && data.content !== undefined) {
            let reasonList: any = [];
            let reasonIdSelected: string | undefined = undefined;
            let idx = 0;

            data.content.forEach((reason: IDocumentHistoryReturnReason) => {
              reasonList.push(
                <Option value={reason.id.toString()} key={reason.id.toString()}>
                  {reason.description}
                </Option>
              );
              if (idx === 0) {
                reasonIdSelected = reason.id.toString();
              }
              idx++;
            });

            setReasonList(reasonList);

            if (reasonList.length === 1 && reasonIdSelected !== undefined) {
              form.setFieldsValue({ reasonId: reasonIdSelected });
            }
          }
        });
    };

    loadDataReasons(selectLine);

    // eslint-disable-next-line
  }, [selectLine, form, visible]);

  return (
    <Modal
      destroyOnClose
      open={visible}
      title="Demande de retour"
      okText="Valider"
      cancelText="Annuler"
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then((values) => {
          form.resetFields();
          onCreate(values);
        });
      }}
      confirmLoading={confirmLoading}
    >
      {line !== undefined && (
        <div>
          <div style={{ marginBottom: 15 }}>
            <Text strong>Article :</Text>{" "}
            {line.documentHistoryDetail.description}
            <br />
            <Text strong>Référence :</Text>{" "}
            {line.documentHistoryDetail.articleReference}
            <br />
            <Text strong>Quantité commandée :</Text>{" "}
            {flybyUtils.numberFormat(
              line.documentHistoryDetail.quantityOrdered,
              2
            )}
            <br />
          </div>

          <Form
            {...FlybyLayoutForm}
            form={form}
            layout="vertical"
            initialValues={{ quantity: 1 }}
          >
            <Form.Item
              label="Motif de retour"
              name="reasonId"
              rules={[{ required: true, message: "Le motif est obligatoire." }]}
            >
              <Select placeholder="Motif de retour" style={{ width: "100%" }}>
                {reasonList}
              </Select>
            </Form.Item>
            <Form.Item
              label="Quantité"
              name="quantity"
              rules={[
                { required: true, message: "La quantité est obligatoire." },
              ]}
            >
              <InputNumber min={1} max={line.quantityReturnable} />
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
};

class ReturnRequest extends React.Component<
  {
    externalRequest: boolean;
  },
  {
    data: IData;
    dataLoading: boolean;
    queryText: string;
    queryType: string;
    showForm: boolean;
    reasonList: any;
    selectedLine?: IDocumentHistoryReturnableArticles;
    pagination: any;
    confirmLoading: boolean;
  }
> {
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private documentHistoryService: DocumentHistoryService =
    new DocumentHistoryService();

  private columns: ColumnProps<IDocumentHistoryReturnableArticles>[] = [
    {
      title: "N° document",
      dataIndex: "documentHistoryDetail.documentHistory.number",
      render: (text: string, record: any) =>
        record.documentHistoryDetail.documentHistory.number,
      width: 80,
    },
    {
      title: "Plateforme",
      dataIndex: "plateforme",
      render: (text: string, record: any) => (
        <div>
          {record.documentHistoryDetail.documentHistory.platform !== null &&
          record.documentHistoryDetail.documentHistory.platform.name !== null
            ? record.documentHistoryDetail.documentHistory.platform.name
            : ""}
        </div>
      ),
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 100,
      render: (text: string, record: any) =>
        this.flybyUtils.formatJsonDateDDMMYYYY(
          record.documentHistoryDetail.documentHistory.date
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text: string, record: any) => (
        <div>
          {this.flybyUtils.getDocumentHistoryType(
            record.documentHistoryDetail.documentHistory.type
          )}
        </div>
      ),
      width: 100,
    },
    {
      title: "Référence",
      dataIndex: "documentHistoryDetail.articleReference",
      render: (text: string, record: any) =>
        record.documentHistoryDetail.articleReference,
      width: 100,
    },
    {
      title: "Libellé",
      dataIndex: "documentHistoryDetail.description",
      render: (text: string, record: any) =>
        record.documentHistoryDetail.description,
      width: 200,
    },
    {
      title: "Qté livrée",
      dataIndex: "quantityDelivered",
      width: 80,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.numberFormat(
            record.documentHistoryDetail.quantityDelivered,
            2
          )}
        </div>
      ),
      align: "right",
    },
    {
      title: "Qté déjà Reprise",
      dataIndex: "quantityAlreadyReturned",
      width: 80,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.numberFormat(record.quantityAlreadyReturned, 2)}
        </div>
      ),
      align: "right",
    },
    {
      title: "Qté reste à reprendre",
      dataIndex: "quantityReturnable",
      width: 80,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.numberFormat(record.quantityReturnable, 2)}
        </div>
      ),
      align: "right",
    },
    {
      title: "",
      width: 50,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          <Tooltip title="Demander un retour de marchandise">
            <Button
              onClick={() => this.onReturn(record)}
              disabled={
                !record.returnable ||
                (record.documentHistoryDetail.documentHistory.platform !==
                  null &&
                  !record.documentHistoryDetail.documentHistory.platform
                    .returnAccepted) ||
                record.documentHistoryDetail.documentHistory.platform === null
              }
            >
              <RollbackOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  constructor(props: { externalRequest: boolean }) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: false,
      queryText: "",
      queryType: "ARTICLE",
      showForm: false,
      reasonList: [],
      selectedLine: undefined,
      pagination: undefined,
      confirmLoading: false,
    };
  }

  render() {
    return (
      <div>
        <h3>Nouvelle demande de retour</h3>

        <Form
          layout="inline"
          style={{ marginTop: 15, marginBottom: 20 }}
          onFinish={this.onSubmit}
          initialValues={{ text: "", type: "ARTICLE" }}
        >
          {!this.props.externalRequest && (
            <Form.Item>
              <Link to="/returns">
                <Button type="link">
                  <ArrowLeftOutlined />
                </Button>
              </Link>
            </Form.Item>
          )}
          <Form.Item
            name="text"
            rules={[{ required: true, message: "Le champs est obligatoire." }]}
          >
            <Input
              placeholder="Référence, n° de document"
              style={{ width: 300 }}
            />
          </Form.Item>
          <Form.Item name="type">
            <RadioGroup>
              <Radio value={"ARTICLE"}>Retour pièce</Radio>
              <Radio value={"CONSIGNEE"}>Retour consigne</Radio>
            </RadioGroup>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Rechercher
            </Button>
          </Form.Item>
        </Form>

        <Table
          rowKey="id"
          loading={this.state.dataLoading}
          columns={this.columns}
          dataSource={this.state.data.content}
          onChange={this.onChange}
          pagination={{
            current: this.state.data.number + 1,
            pageSize: this.state.data.size,
            total: this.state.data.totalElements,
          }}
          expandedRowRender={(
            record: IDocumentHistoryReturnableArticles,
            index: number,
            indent: number,
            expanded: boolean
          ) => (
            <div>
              {expanded &&
                record.documentHistoryDetail.documentHistory.platform !==
                  null &&
                record.documentHistoryDetail.documentHistory.platform
                  .returnAccepted && (
                  <ReturnRequestDetails
                    documentHistoryReturns={record.documentHistoryReturns}
                    documentHistoryDetail={record.documentHistoryDetail}
                  />
                )}
              {expanded &&
                ((record.documentHistoryDetail.documentHistory.platform !==
                  null &&
                  !record.documentHistoryDetail.documentHistory.platform
                    .returnAccepted) ||
                  record.documentHistoryDetail.documentHistory.platform ===
                    null) && (
                  <Text type="warning">
                    La demande de retour n'est pas disponible pour cette
                    plateforme, veuillez contacter le service clientèle.
                  </Text>
                )}
            </div>
          )}
          style={{
            border: "1px solid #e8e8e8",
            borderWidth: "1px 1px 1px 1px",
          }}
          size="middle"
        />

        {this.state.selectedLine !== undefined && (
          <ReturnForm
            visible={this.state.showForm}
            onCancel={this.onCancelReturn}
            onCreate={this.onValidReturn}
            reasonList={this.state.reasonList}
            line={this.state.selectedLine}
            confirmLoading={this.state.confirmLoading}
          />
        )}
      </div>
    );
  }

  private onChange = (
    pagination: any,
    filters: Record<never, string[]>,
    sorter: any,
    extra: any
  ) => {
    pagination = this.flybyUtils.getCurrentPage(pagination);

    this.searchReturn(
      this.state.queryText,
      this.state.queryType,
      pagination,
      true
    );
  };

  private searchReturn = (
    query: string,
    type: string,
    pagination: any | undefined,
    fromOnChange: boolean
  ) => {
    if (!this.state.dataLoading) {
      this.setState({
        dataLoading: true,
      });
    }

    let currentPagination = pagination;
    if (!fromOnChange && this.state.pagination !== undefined) {
      currentPagination = this.state.pagination;
    }

    this.documentHistoryService
      .getDocumentHistoryReturnableArticles(
        query,
        type,
        currentPagination.pageSize,
        currentPagination.current,
        "documentHistory.date,DESC"
      )
      .then((data: any) => {
        if (data !== undefined) {
          this.setState({
            data: data,
            dataLoading: false,
            pagination: currentPagination,
          });
        }
      });
  };

  private onSubmit = (values: any) => {
    let currentPagination = {
      pageSize: 20,
      current: 0,
    };

    this.setState({
      dataLoading: true,
      queryText: values.text,
      queryType: values.type,
      pagination: currentPagination,
    });

    this.searchReturn(values.text, values.type, currentPagination, false);
  };

  private onReturn = (line: IDocumentHistoryReturnableArticles) => {
    this.setState({
      showForm: true,
      selectedLine: line,
    });
  };

  private onCancelReturn = () => {
    this.setState({ showForm: false });
  };

  private onValidReturn = (values: any) => {
    this.setState({
      confirmLoading: true,
    });

    if (this.state.selectedLine !== undefined) {
      this.documentHistoryService
        .addDocumentHistoryReturn(
          values.reasonId,
          this.state.selectedLine.id,
          values.quantity
        )
        .then((response: any) => {
          if (response.status !== 201) {
            notification.error({
              message: "Demande de retour",
              description: "Impossible de créer la demande de retour.",
            });
          } else {
            notification.info({
              message: "Demande de retour",
              description: "La demande de retour vient être envoyée.",
              duration: 5,
            });
          }

          this.searchReturn(
            this.state.queryText,
            this.state.queryType,
            this.state.pagination,
            false
          );
          this.setState({
            confirmLoading: false,
            showForm: false,
          });
        });
    } else {
      this.setState({
        confirmLoading: false,
      });
    }
  };
}

export default ReturnRequest;
