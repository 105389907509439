import React, { useCallback, useEffect, useState } from "react";
import "../../App.css";
import "@ant-design/compatible/assets/index.css";
import {
  Form,
  Table,
  DatePicker,
  Radio,
  Button,
  Input,
  Select,
  Row,
  Col,
  Spin,
  RadioChangeEvent,
} from "antd";
import { ColumnProps, TablePaginationConfig } from "antd/lib/table";
import { Store } from "antd/lib/form/interface";
import moment, { Moment } from "moment";
import "moment/locale/fr";
import IData from "../../Utils/IData";
import IDocumentHistory from "./IDocumentHistory";
import DocumentHistoryDetails from "./DocumentHistoryDetails";
import FlybyLayoutForm from "../../Utils/FlybyLayoutForm";
import ICustomerPlatform from "./ICustomerPlatform";
import { FilePdfOutlined } from "@ant-design/icons";
import PdfViewer from "../../Utils/PdfViewer";
import {
  formatJsonDateDDMMYYYY,
  getCurrentPage,
  getDocumentHistoryType,
  hideZeroValue,
  numberFormat,
} from "../../Utils/FlybyUtilsFunctional";
import {
  documentHistoryGetPdf,
  documentHistorySearch,
} from "./DocumentHistoryServiceFunctional";
import { getSession } from "../login/LoginServiceFunctional";

const DocumentHistory = (): React.ReactElement => {
  const [data, setData] = useState<IData>();
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [queryDate, setQueryDate] = useState<Moment>(moment());
  // const [queryType, setQueryType] = useState<string>("ORDER");
  const [queryType, setQueryType] = useState<string>();
  const [queryText, setQueryText] = useState<string>("");
  const [queryPlatformId, setQueryPlatformId] = useState<string>("");
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [pdfFile, setPdfFile] = useState<Blob | undefined>(undefined);

  // setData(getEmptyData());

  moment.locale("fr");

  const [customerPlatforms, setCustomerPlatforms] = useState<
    ICustomerPlatform[]
  >([]);
  // const accessToken = sessionStorage.getItem("accessToken");
  // getSession(accessToken).then((data: any) => {
  //   setCustomerPlatforms(data.customerPlatforms);
  // });

  const showPlatformList: boolean =
    sessionStorage.getItem("showPlatformList") === "false" ? false : true;

  const RadioGroup = Radio.Group;
  const Option = Select.Option;
  const monthFormat = "MM/YYYY";
  const [form] = Form.useForm();

  const onGetPdf = useCallback((record: IDocumentHistory): void => {
    setPdfLoading(true);
    documentHistoryGetPdf(record.id)
      .then((data) => {
        setPdfLoading(false);
        setPdfFile(data);
      })
      .catch((err: any) => {
        console.log(err);
        setPdfLoading(false);
      });
  }, []);

  const columns: ColumnProps<IDocumentHistory>[] = [
    {
      title: "N° document",
      dataIndex: "number",
      width: 80,
    },
    {
      title: "Plateforme",
      dataIndex: "plateforme",
      render: (text: string, record: any) => (
        <div>
          {record.platform !== null && record.platform.name !== null
            ? record.platform.name
            : ""}
        </div>
      ),
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text: string, record: any) => (
        <div>{getDocumentHistoryType(record.type)}</div>
      ),
      width: 100,
    },
    {
      title: "Référence",
      dataIndex: "reference",
      width: 200,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 100,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatJsonDateDDMMYYYY(record.date)}
        </div>
      ),
      align: "right",
    },
    {
      title: "Total HT",
      dataIndex: "totalAmountExclTaxes",
      width: 80,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {hideZeroValue(numberFormat(record.totalAmountExclTaxes, 2))}
        </div>
      ),
      align: "right",
    },
    {
      title: "Total TTC",
      dataIndex: "totalAmountInclTaxes",
      width: 80,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {hideZeroValue(numberFormat(record.totalAmountInclTaxes, 2))}
        </div>
      ),
      align: "right",
    },
    {
      title: queryType === "ORDER" ? "Statut" : "",
      width: 100,
      render: (text: string, record: IDocumentHistory) => (
        <div style={{ textAlign: "right" }}>
          {queryType === "ORDER" && record.flag === "6" && (
            <div>Commande expédiée</div>
          )}
          {queryType === "ORDER" && record.flag !== "6" && (
            <div>En cours de traitement</div>
          )}
          {queryType !== "ORDER" && (
            <Button onClick={() => onGetPdf(record)}>
              <FilePdfOutlined />
              PDF
            </Button>
          )}
        </div>
      ),
    },
  ];

  // const searchDocumentHistory = useCallback(
  //   async (
  //     query: string,
  //     platformId: string,
  //     pageSize: number | undefined,
  //     current: number | undefined,
  //     sort: string,
  //     date: Moment,
  //     type: string
  //   ): Promise<IData | undefined> => {
  //     setDataLoading(true);

  //     await documentHistorySearch(
  //       query,
  //       platformId,
  //       pageSize,
  //       current,
  //       sort,
  //       date,
  //       type
  //     ).then((data: any) => {
  //       if (data) {
  //         setData(data);
  //         setDataLoading(false);
  //         return data;
  //       }
  //     });
  //     setDataLoading(false);
  //     return undefined;
  //   },
  //   []
  // );

  const onSubmit = useCallback((values: Store): void => {
    let date = values.type !== "ORDER" ? values.date : undefined;
    setQueryDate(date);
    setQueryText(values.text);
    setQueryType(values.type);
    setQueryPlatformId(values.platform);
  }, []);

  const onChangeRadioGroup = useCallback(
    (checked: boolean): void => {
      form.validateFields().then((values) => {
        // setQueryText(values.text);
        onSubmit(values);
      });
    },
    [form, onSubmit]
  );

  const onChange = useCallback(
    async (
      pagination: TablePaginationConfig,
      filters: Record<never, string[]>,
      sorter: any,
      extra: any
    ): Promise<void> => {
      pagination = getCurrentPage(pagination);
      try {
        if (queryType) {
          setDataLoading(true);
          const loadedData = await documentHistorySearch(
            queryText,
            queryPlatformId,
            pagination.pageSize,
            pagination.current,
            "date,desc",
            queryDate,
            queryType
          );
          if (loadedData) setData(loadedData);
        }
      } finally {
        setDataLoading(false);
      }
    },
    [queryDate, queryPlatformId, queryText, queryType]
  );

  const onClosePdf = useCallback(() => {
    setPdfFile(undefined);
  }, []);

  const getDocumentHistory = useCallback(async (): Promise<void> => {
    try {
      setDataLoading(true);
      const loadedData = await documentHistorySearch(
        queryText,
        queryPlatformId,
        20,
        0,
        "date,desc",
        queryDate,
        queryType ? queryType : "ORDER"
      );
      if (loadedData) setData(loadedData);
    } finally {
      setDataLoading(false);
    }
  }, [queryDate, queryPlatformId, queryText, queryType]);

  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
      getSession(accessToken)
        .then((data: any) => {
          setCustomerPlatforms(data.customerPlatforms);
        })
        .catch((error: any) => {
          console.error("Error getting customer platforms:", error);
        });
    }
  }, []);

  useEffect(() => {
    getDocumentHistory();
  }, [customerPlatforms, getDocumentHistory]);

  // render() {
  return (
    <div>
      <h3>Mes documents</h3>
      <Spin spinning={pdfLoading}>
        <Form
          {...FlybyLayoutForm}
          form={form}
          layout="inline"
          style={{ marginTop: 15, marginBottom: 20 }}
          onFinish={onSubmit}
          initialValues={{
            type: "ORDER",
            date: null,
            text: "",
            platform: "",
          }}
        >
          <Row gutter={8} style={{ marginTop: 5 }}>
            {showPlatformList && (
              <Col span={6}>
                <Form.Item name="platform">
                  <Select
                    placeholder="Plateforme"
                    style={{ minWidth: 180, width: "100%" }}
                    allowClear
                    dropdownMatchSelectWidth={370}
                  >
                    {customerPlatforms.map(
                      (customerPlatform: ICustomerPlatform) => (
                        <Option
                          value={customerPlatform.platform.id}
                          key={customerPlatform.platform.id.toString()}
                        >
                          {customerPlatform.platform.name}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={18}>
              <Form.Item name="type">
                {/* <RadioGroup onChange={console.log("change")}> */}
                <RadioGroup
                  onChange={(radio: RadioChangeEvent) =>
                    onChangeRadioGroup(radio.target.value)
                  }
                >
                  <Radio value={"ORDER"}>En cours</Radio>
                  <Radio value={"INVOICE"}>Factures</Radio>
                  <Radio value={"CREDIT_NOTE"}>Avoirs</Radio>
                  <Radio value={"RETURN"}>Accords de retour</Radio>
                  <Radio value={"DELIVERY_NOTE"}>Bons de livraison</Radio>
                </RadioGroup>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} style={{ marginTop: 5 }}>
            <Col span={8}>
              <Form.Item name="date">
                <DatePicker
                  picker="month"
                  style={{ width: "100%" }}
                  placeholder="Date du document"
                  format={monthFormat}
                  allowClear={true}
                  // disabled={queryType === "ORDER"}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="text">
                <Input
                  placeholder="Référence, n° de document, fabricant ...."
                  style={{ minWidth: 200, width: "100%" }}
                  // disabled={queryType === "ORDER"}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Rechercher
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Table
          rowKey="id"
          loading={dataLoading}
          columns={columns}
          dataSource={data?.content}
          onChange={onChange}
          pagination={{
            current: data?.number ? data.number + 1 : 1,
            pageSize: data?.size,
            total: data?.totalElements,
          }}
          expandedRowRender={(
            record: IDocumentHistory,
            index: number,
            indent: number,
            expanded: boolean
          ) => (
            <div>
              {expanded && (
                <DocumentHistoryDetails
                  documentHistoryId={record.id}
                  documentType={record.type}
                />
              )}
            </div>
          )}
          style={{
            border: "1px solid #e8e8e8",
            borderWidth: "1px 1px 1px 1px",
          }}
          size="middle"
        />
      </Spin>
      {pdfFile && (
        <PdfViewer
          fileBlob={pdfFile}
          fileString=""
          urlType={false}
          visible={!!pdfFile}
          onClosePrint={onClosePdf}
        />
      )}
    </div>
  );
};
export default DocumentHistory;
