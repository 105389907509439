import React from 'react';
import '../../../App.css';
import { Button, Tag, Skeleton } from 'antd';

import TecdocService from '../TecdocService';
import IData from '../../../Utils/IData';
import FlybyUtils from '../../../Utils/FlybyUtils';
import ITecdocSearch from '../model/ITecdocSearch';
import Table, { ColumnProps } from 'antd/lib/table';
import IVehicleType from '../model/IVehicleType';
import TecdocUtils from '../TecdocUtils';
import IKeyEntry from '../model/IKeyEntry';
import { withBasket } from '../../../Utils/BasketProvider';
import TecdocVehicleListFilter from './TecdocVehicleListFilter';
import IManufacturer from '../model/IManufacturer';

const { CheckableTag } = Tag;

class TecdocVehicleList extends React.Component<
  {
    tecdocSearch: ITecdocSearch,
    setTecdocSearch: any
  },
  {
    data: IData,
    dataLoading: boolean,
    filters: string,
    selectedTags: number[],
    manufactuerList: IManufacturer[],
    query: string,
    queryType: string
  }>{

  private tecdocService: TecdocService = new TecdocService();
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private tecdocUtils: TecdocUtils = new TecdocUtils();
  private fuelType: IKeyEntry[] | undefined;
  private bodyType: IKeyEntry[] | undefined;

  private columns: ColumnProps<IVehicleType>[] = [
    {
      title: 'KtypNr',
      dataIndex: 'ktypNr',
      width: 80,
    },
    {
      title: 'Constructeur',
      dataIndex: 'description',
      render: (text: string, record: IVehicleType) =>
        <div>
          {record.vehicleModelSerie.manufacturer != null &&
            <Button onClick={() => this.onSelectVehicleType(record)} type={"link"} style={{ margin: 0, padding: 0 }}>
              {record.vehicleModelSerie.manufacturer.bez}
            </Button>
          }
        </div>,
    },
    {
      title: 'Modèle',
      dataIndex: 'description',
      render: (text: string, record: IVehicleType) =>
        <div>
          <Button onClick={() => this.onSelectVehicleType(record)} type={"link"} style={{ margin: 0, padding: 0 }}>
            {record.vehicleModelSerie.bez} - {record.bez}
          </Button>
        </div>,
    },
    {
      title: 'Depuis le',
      dataIndex: 'bjvon',
      render: (text: string, record: IVehicleType) => <div>{this.flybyUtils.convertNumberToDateMMYYYY(record.bjvon)}</div>,
      width: 120,
      align: "right",
    },
    {
      title: 'Jusqu\'à',
      dataIndex: 'bjbis',
      render: (text: string, record: IVehicleType) => <div>{this.flybyUtils.convertNumberToDateMMYYYY(record.bjbis)}</div>,
      width: 120,
      align: "right",
    },
    {
      title: 'Ch.',
      dataIndex: 'ps',
      width: 80,
      align: "right",
    },
    {
      title: 'Kw',
      dataIndex: 'kw',
      width: 80,
      align: "right",
    },
    {
      title: 'Cc',
      dataIndex: 'ccmTech',
      width: 80,
      align: "right",
    },
    {
      title: 'ABS',
      dataIndex: 'abs',
      width: 80,
      render: (text: string, record: any) => <div>{this.tecdocUtils.getYesNo(record.abs)}</div>,
    },
    {
      title: 'Carrosserie',
      dataIndex: 'aufbauArt',
      render: (text: string, record: any) => <div>{this.tecdocUtils.getKeyType(this.bodyType, record.aufbauArt)}</div>,
      width: 120
    },
    {
      title: 'Carburant',
      dataIndex: 'krStoffArt',
      render: (text: string, record: any) => <div>{this.tecdocUtils.getKeyType(this.fuelType, record.krStoffArt)}</div>,
      width: 120
    },
    {
      title: 'Code moteur',
      dataIndex: '',
      render: (text: string, record: any) => <div>{this.tecdocUtils.getMotorCode(record.engineNumberVehicleTypes)}</div>,
      width: 120,
    }
  ];

  constructor(props: { tecdocSearch: ITecdocSearch, setTecdocSearch: any }) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
      filters: '',
      selectedTags: [],
      manufactuerList: [],
      query: '',
      queryType: '',
    };
  }

  componentDidMount() {
    if(this.props.tecdocSearch.query !== undefined){
      let queryType = this.props.tecdocSearch.queryType;
      let query = '';
      switch(queryType){
        case "mineType":
          query = this.props.tecdocSearch.query.mineType;
          break;
        case "motorCode":
          query = this.props.tecdocSearch.query.motorCode;
          break;
      }
      this.loadData(queryType, query);
    }

  }

  componentWillReceiveProps(newProps: { tecdocSearch: ITecdocSearch, setTecdocSearch: any }) {
    if ((
          (this.props.tecdocSearch?.query?.motorCode !== newProps.tecdocSearch?.query?.motorCode) ||
          (this.props.tecdocSearch?.query?.mineType !== newProps.tecdocSearch?.query?.mineType)
        )
      && newProps.tecdocSearch.query !== undefined) {
      let queryType = newProps.tecdocSearch.queryType;
      let query = '';
      switch(queryType){
        case "mineType":
          query = newProps.tecdocSearch.query.mineType;          
          break;
        case "motorCode":
          query = newProps.tecdocSearch.query.motorCode;
          break;
      }    
      this.loadData(queryType, query);
    }
  }

  render() {

    const { selectedTags } = this.state;

    return (
      <div>
        <TecdocVehicleListFilter onFilters={this.onFilters} />

        <div style={{ padding: 10, marginBottom: 10 }} className="ant-advanced-search-form">
          <Skeleton loading={this.state.dataLoading} active>
            {this.state.manufactuerList.map(tag => (
              <CheckableTag
                key={tag.herNr}
                checked={selectedTags.indexOf(tag.herNr) > -1}
                onChange={(checked: boolean) => this.handleChange(tag.herNr, checked)}
                style={{ border: "#1890ff", borderWidth: "1px", borderStyle: "solid", paddingLeft: 5, paddingRight: 5 }}>
                {tag.bez}
              </CheckableTag>
            ))}
          </Skeleton>
        </div>

        <Table rowKey="ktypNr" loading={this.state.dataLoading} columns={this.columns} dataSource={this.state.data.content}
          onChange={this.onChange} pagination={{ current: (this.state.data.number + 1), pageSize: this.state.data.size, total: this.state.data.totalElements }}
          style={{ border: '1px solid #e8e8e8', borderWidth: '1px 1px 1px 1px' }} size="middle" />
      </div>
    );
  }

  private handleChange(tag: any, checked: boolean) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);

      this.searchTecdocVehicleType(false, this.state.queryType,this.state.query, this.state.filters, nextSelectedTags, 50, 0);
  }

  private onSelectVehicleType = (vehicleType: IVehicleType) => {

    let tecdocSearch = this.props.tecdocSearch;

    tecdocSearch = {
      ...tecdocSearch,
      queryType: 'vehicle',
      vehicle: {
        herNr: vehicleType.vehicleModelSerie.manufacturer.herNr,
        herNrDescription: vehicleType.vehicleModelSerie.manufacturer.bez,
        kmodNr: vehicleType.vehicleModelSerie.kmodNr,
        kmodNrDescription: vehicleType.vehicleModelSerie.bez,
        ktypNr: vehicleType.ktypNr,
        ktypNrDescription: vehicleType.bez
      },
      searchStructure: undefined
    }

    this.props.setTecdocSearch(tecdocSearch);

  }

  private onChange = (pagination: any, filters: Record<never, string[]>, sorter: any, extra: any) => {
      pagination = this.flybyUtils.getCurrentPage(pagination);
      this.searchTecdocVehicleType(false, this.state.queryType,this.state.query, this.state.filters, this.state.selectedTags, pagination.pageSize, pagination.current);
  }

  private onFilters = (filters: string) => {
      this.searchTecdocVehicleType(false, this.state.queryType,this.state.query, filters, this.state.selectedTags, this.flybyUtils.getDefaultPageSize(), 0);
  }

  private searchTecdocVehicleType = (loadData: boolean, queryType: string, query: string, filters: string, selectedTags: number[], pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    let herNr = '';
    if (selectedTags.length > 0) {
      herNr = selectedTags.join(",");
    }

    switch (queryType) {
      case "mineType":
        this.tecdocService.findByVehicleByMineType(query, filters, herNr, pageSize, page)
        .then((result: any) => {
          this.setData(loadData, queryType, query, result, filters, selectedTags);
        });  
        break;
      case "motorCode":
        this.tecdocService.findByVehicleByMotorCode(query, filters, herNr, pageSize, page)
        .then((result: any) => {
          this.setData(loadData, queryType, query, result, filters, selectedTags);
        });        
        break;
    }


  }

  private setData = (loadData: boolean, queryType: string, query: string, result: any, filters: string, selectedTags: number[], ) => {
    if (result !== undefined && result.content !== undefined) {

      if (loadData) {
        let manufactuerList: IManufacturer[] = [];
        let selectedTags: number[] = [];

        result.content.forEach((element: IVehicleType) => {
          let idx = manufactuerList.findIndex((record: IManufacturer) => record.herNr === element.vehicleModelSerie.manufacturer.herNr);
          if (idx === -1) {
            manufactuerList.push(element.vehicleModelSerie.manufacturer);
            selectedTags.push(element.vehicleModelSerie.manufacturer.herNr);
          }
        });

        manufactuerList.sort((a: IManufacturer, b: IManufacturer) => a.bez.localeCompare(b.bez));

        this.setState({
          data: result,
          dataLoading: false,
          filters: filters,
          manufactuerList: manufactuerList,
          selectedTags: selectedTags,
          query: query,
          queryType: queryType
        });
      }
      else {

        this.setState({
          data: result,
          dataLoading: false,
          filters: filters,
          selectedTags: selectedTags,
          query: query,
          queryType: queryType          
        });
      }

    }
    else {
      this.setState({
        dataLoading: false,
        filters: filters,
        manufactuerList: [],
        selectedTags: [],
        query: query,
        queryType: queryType        
      });
    }
  }

  private loadData = (queryType: string, query: string) => {
    let promise1 = this.tecdocService.findKeyEntries(182, 200, 0);
    let promise2 = this.tecdocService.findKeyEntries(86, 200, 0);

    Promise.all([promise1, promise2]).then((result: any) => {
      this.fuelType = result[0].content;
      this.bodyType = result[1].content;
      if (this.props.tecdocSearch.query !== undefined) {
        this.searchTecdocVehicleType(true, queryType, query, this.state.filters, [], 50, 0);
      }
    });
  }
}

export default withBasket(TecdocVehicleList);     