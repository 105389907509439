import React from "react";
import { useMarketParameter } from "./MarketParameterContext";

const HeaderLogo = (): React.ReactElement => {
  const { APP_LOGO, APP_LOGO_URL, SHOW_MENU_LOGO } = useMarketParameter();

  return (
    <>
      {APP_LOGO && SHOW_MENU_LOGO && (
        <a href={APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img
            src={"/api/public/" + APP_LOGO}
            style={{
              padding: 5,
              maxHeight: "100%",
              maxWidth: "100%",
            }}
            alt="IN Concept"
          />
        </a>
      )}
    </>
  );
};

export default HeaderLogo;
