
class DocumentService {

  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");

  public async search(platform: number | undefined, family: number | undefined, manufacturer: number | undefined, article: number | undefined, 
      highlight: boolean | undefined, documentFamily: number | undefined, size: number | undefined, page: number | undefined, sort: string): Promise<any> {

    let url = '?customer=' + this.customerId + '&size=' + size + '&page=' + page + '&sort=' + sort;
    if(platform){
      url += '&platform=' + platform;
    }
    if(family){
      url += '&family=' + family;
    }
    if(manufacturer){
      url += '&manufacturer=' + manufacturer;
    }
    if(article){
      url += '&article=' + article;
    }
    if(highlight){
      url += '&highlight=' + highlight;
    }
    if(documentFamily){
      url += '&documentFamily=' + documentFamily;
    }
       

    return fetch('/api/market/documents' + url, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  

}

export default DocumentService;