import React from "react";
import "../../App.css";
import { LeftOutlined, FrownOutlined } from "@ant-design/icons";
import { Button, Spin, notification, Typography, Row, Col } from "antd";
import ConfirmedSalesOrderView from "./ConfirmedSalesOrderView";
import IStep from "./IStep";
import { PaymentMethod } from "../../Utils/PaymentUtils";
import { MarketParameterContext } from "../../Utils/MarketParameterContext";

import IConfirmedSalesOrder from "./IConfirmedSalesOrder";
import SalesOrderService from "./SalesOrderService";
import ParameterService from "../../Utils/ParameterService";
// import ISalesOrder from "./ISalesOrder";

const { Title } = Typography;

// interface Step6Props {
//   stepConf: IStep | undefined;
//   values: any;
//   onNextStep: any;
//   refreshWishlist: () => void;
//   refreshWishlistTotal: () => void;
//   clearSalesOrder: () => void;
// }

// const Step6 = ({
//   stepConf,
//   values,
//   onNextStep,
//   refreshWishlist,
//   refreshWishlistTotal,
//   clearSalesOrder,
// }: Step6Props): React.ReactElement => {
//   const [dataLoadingState, setLoadingState] = useState<boolean>(false);
//   const [confirmOrderState, setConfirmOrderState] = useState<any>(); //ISalesOrder
//   const [errorState, setErrorState] = useState<boolean>(false);
//   const [paymentOrderState, setPaymentOrderState] = useState<string>();

//   const findCustomerOrder = useCallback(async () => {
//     try {
//       const CUSTOMER_PAYMENT_ORDER = await findValueCustomer(
//         "CUSTOMER_PAYMENT_ORDER"
//       );
//       if (CUSTOMER_PAYMENT_ORDER !== undefined) {
//         setPaymentOrderState(CUSTOMER_PAYMENT_ORDER);
//       } else {
//         setPaymentOrderState("DISABLED");
//       }
//     } catch {
//       return;
//     }
//   }, []);

//   const onConfirmSalesOrder = useCallback(() => {
//     setLoadingState(true);
//     setErrorState(false);

//     console.log("this.props.values : ", values);
//     console.log("this.state.paymentOrder : ", paymentOrderState);

//     // if (this.state.payementOrder) {
//     if (
//       paymentOrderState &&
//       values.salesOrder &&
//       values.salesOrder.paymentMethod === PaymentMethod.CREDIT_CARD
//     ) {
//       setConfirmOrderState(values.salesOrder);
//       setLoadingState(false);
//       console.log("values.salesOrder 1111 : ", values.salesOrder);
//       refreshWishlist();
//       refreshWishlistTotal();
//       clearSalesOrder();
//       // } else {
//       //   this.setState({
//       //     dataLoading: false,
//       //     error: true,
//       //   });
//       //   notification.error({
//       //     message: "Erreur de commande",
//       //     description:
//       //       "Impossible de valider la commande. Contacter votre fournisseur",
//       //     duration: 15,
//       //   });
//       // }
//     } else {
//       console.log("values.salesOrder 2222 : ", values.salesOrder);
//       postSaleOrder(values.salesOrder)
//         .then((response: any) => {
//           if (response.status === 201) {
//             return response.json();
//           } else {
//             notification.error({
//               message: "Commande",
//               description: "Impossible de valider la commande.",
//               duration: 15,
//             });
//             return undefined;
//           }
//         })
//         .then((result: any) => {
//           if (result === undefined) {
//             setConfirmOrderState(undefined);
//             setLoadingState(false);
//           } else {
//             setConfirmOrderState(result);
//             setLoadingState(false);
//             refreshWishlist();
//             refreshWishlistTotal();
//             clearSalesOrder();
//           }
//         })
//         .catch((err: any) => {
//           setConfirmOrderState(undefined);
//           setLoadingState(false);
//           setErrorState(true);
//           notification.error({
//             message: "Commande",
//             description: "Impossible de valider la commande.",
//             duration: 15,
//           });
//         });
//     }
//   }, [
//     clearSalesOrder,
//     paymentOrderState,
//     refreshWishlist,
//     refreshWishlistTotal,
//     values,
//   ]);

//   useEffect(() => {
//     findCustomerOrder();
//     onConfirmSalesOrder();
//   }, [findCustomerOrder, onConfirmSalesOrder]);

//   return (
//     <MarketParameterContext.Consumer>
//       {(parameters) => (
//         <Spin spinning={dataLoadingState}>
//           {confirmOrderState === undefined && (
//             <div>
//               <Row
//                 style={{
//                   position: "sticky",
//                   top: -20,
//                   backgroundColor: "#fff",
//                   zIndex: 1,
//                 }}
//               >
//                 <Col span={24}>
//                   <div
//                     style={{
//                       float: "right",
//                       margin: 10,
//                     }}
//                   >
//                     <Button
//                       type="primary"
//                       size="large"
//                       onClick={() => onNextStep(1)}
//                       style={{ marginRight: 5 }}
//                     >
//                       <LeftOutlined />
//                       Retour au panier
//                     </Button>
//                   </div>
//                 </Col>
//               </Row>
//               {errorState && (
//                 <div
//                   style={{ marginTop: 100, marginBottom: 100, fontSize: 24 }}
//                 >
//                   Commande en cours de traitement...
//                 </div>
//               )}
//               {errorState && (
//                 <div
//                   style={{ marginTop: 100, marginBottom: 100, fontSize: 24 }}
//                 >
//                   <FrownOutlined /> Impossible de valider la commande.
//                 </div>
//               )}
//               {/* <div
//                   style={{ float: "right", marginBottom: 10, marginTop: 10 }}
//                 >
//                   <Button
//                     type="primary"
//                     size="large"
//                     onClick={() => this.props.onNextStep(1)}
//                     style={{ marginRight: 5 }}
//                   >
//                     <LeftOutlined />
//                     Retour au panier
//                   </Button>
//                 </div> */}
//             </div>
//           )}

//           {confirmOrderState !== undefined && (
//             <div>
//               <Row
//                 style={{
//                   position: "sticky",
//                   top: -20,
//                   backgroundColor: "#fff",
//                   zIndex: 1,
//                 }}
//               >
//                 <Col span={24}>
//                   <div
//                     style={{
//                       float: "right",
//                       margin: 10,
//                     }}
//                   >
//                     <Button
//                       type="primary"
//                       size="large"
//                       onClick={() => onNextStep(stepConf?.prevAction.prevStep)}
//                       hidden={stepConf?.prevAction.enable}
//                       style={{ marginRight: 5 }}
//                     >
//                       <LeftOutlined />
//                       {stepConf?.prevAction.text}
//                     </Button>
//                     <Button
//                       type="primary"
//                       size="large"
//                       onClick={() => onNextStep(stepConf?.nextAction.nextStep)}
//                       hidden={stepConf?.nextAction.enable}
//                       style={{ marginRight: 5 }}
//                     >
//                       <LeftOutlined />
//                       {stepConf?.nextAction.text}
//                     </Button>
//                   </div>
//                 </Col>
//               </Row>

//               <Title level={4}>Votre commande est enregistrée.</Title>
//               <p>Les prochaines étapes : </p>
//               <ul>
//                 {paymentOrderState && <li>Vérification du paiement</li>}
//                 <li>Traitement de commande</li>
//                 <li>Expédition</li>
//               </ul>
//               <br />
//               <br />

//               <ConfirmedSalesOrderView
//                 confirmOrder={confirmOrderState}
//                 parameters={parameters}
//                 stepConf={stepConf}
//               />
//             </div>
//           )}
//         </Spin>
//       )}
//     </MarketParameterContext.Consumer>
//   );
// };

// export default Step6;
class Step6Class extends React.Component<
  {
    stepConf: IStep | undefined;
    values: any;
    onNextStep: any;
    refreshWishlist: any;
    refreshWishlistTotal: any;
    clearSalesOrder: any;
  },
  {
    confirmOrder: IConfirmedSalesOrder | undefined;
    dataLoading: boolean;
    payementOrder: boolean;
    error: boolean;
  }
> {
  private salesOrderService: SalesOrderService = new SalesOrderService();
  private parameterService: ParameterService = new ParameterService();

  constructor(props: {
    stepConf: IStep | undefined;
    values: any;
    onNextStep: any;
    refreshWishlist: any;
    refreshWishlistTotal: any;
    clearSalesOrder: any;
  }) {
    super(props);

    this.state = {
      confirmOrder: undefined,
      dataLoading: false,
      payementOrder: false,
      error: false,
    };
  }

  componentDidMount() {
    this.parameterService.findValue("PAYMENT_ORDER").then((value: string) => {
      if (value !== undefined) {
        this.setState({
          payementOrder: JSON.parse(value),
        });
      } else {
        this.setState({
          payementOrder: false,
        });
      }

      this.onConfirmSalesOder();
    });
  }

  render() {
    return (
      <MarketParameterContext.Consumer>
        {(parameters) => (
          <Spin spinning={this.state.dataLoading}>
            {this.state.confirmOrder === undefined && (
              <div>
                <Row
                  style={{
                    position: "sticky",
                    top: -20,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  <Col span={24}>
                    <div
                      style={{
                        float: "right",
                        margin: 10,
                      }}
                    >
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => this.props.onNextStep(1)}
                        style={{ marginRight: 5 }}
                      >
                        <LeftOutlined />
                        Retour au panier
                      </Button>
                    </div>
                  </Col>
                </Row>
                {!this.state.error && (
                  <div
                    style={{ marginTop: 100, marginBottom: 100, fontSize: 24 }}
                  >
                    Commande en cours de traitement...
                  </div>
                )}
                {this.state.error && (
                  <div
                    style={{ marginTop: 100, marginBottom: 100, fontSize: 24 }}
                  >
                    <FrownOutlined /> Impossible de valider la commande.
                  </div>
                )}
                {/* <div
                  style={{ float: "right", marginBottom: 10, marginTop: 10 }}
                >
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => this.props.onNextStep(1)}
                    style={{ marginRight: 5 }}
                  >
                    <LeftOutlined />
                    Retour au panier
                  </Button>
                </div> */}
              </div>
            )}

            {this.state.confirmOrder !== undefined && (
              <div>
                <Row
                  style={{
                    position: "sticky",
                    top: -20,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  <Col span={24}>
                    <div
                      style={{
                        float: "right",
                        margin: 10,
                      }}
                    >
                      <Button
                        type="primary"
                        size="large"
                        onClick={() =>
                          this.props.onNextStep(
                            this.props.stepConf?.prevAction.prevStep
                          )
                        }
                        hidden={!this.props.stepConf?.prevAction.enable}
                        style={{ marginRight: 5 }}
                      >
                        <LeftOutlined />
                        {this.props.stepConf?.prevAction.text}
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        onClick={() =>
                          this.props.onNextStep(
                            this.props.stepConf?.nextAction.nextStep
                          )
                        }
                        hidden={!this.props.stepConf?.nextAction.enable}
                        style={{ marginRight: 5 }}
                      >
                        <LeftOutlined />
                        {this.props.stepConf?.nextAction.text}
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Title level={4}>Votre commande est enregistrée.</Title>
                <p>Les prochaines étapes : </p>
                <ul>
                  {this.state.payementOrder && (
                    <li>Vérification du paiement</li>
                  )}
                  <li>Traitement de commande</li>
                  <li>Expédition</li>
                </ul>
                <br />
                <br />

                <ConfirmedSalesOrderView
                  confirmOrder={this.state.confirmOrder}
                  parameters={parameters}
                  stepConf={this.props.stepConf}
                />
              </div>
            )}
          </Spin>
        )}
      </MarketParameterContext.Consumer>
    );
  }

  private onConfirmSalesOder = () => {
    this.setState({
      dataLoading: true,
      error: false,
    });

    // if (this.state.payementOrder) {
    if (
      this.state.payementOrder &&
      this.props.values.salesOrder &&
      this.props.values.salesOrder.paymentMethod === PaymentMethod.CREDIT_CARD
    ) {
      this.setState({
        confirmOrder: this.props.values.salesOrder,
        dataLoading: false,
      });
      console.log(
        "this.props.values.salesOrder : ",
        this.props.values.salesOrder
      );
      this.props.refreshWishlist();
      this.props.refreshWishlistTotal();
      this.props.clearSalesOrder();
      // } else {
      //   this.setState({
      //     dataLoading: false,
      //     error: true,
      //   });
      //   notification.error({
      //     message: "Erreur de commande",
      //     description:
      //       "Impossible de valider la commande. Contacter votre fournisseur",
      //     duration: 15,
      //   });
      // }
    } else {
      this.salesOrderService
        .postOrder(this.props.values.salesOrder)
        .then((response: any) => {
          if (response.status === 201) {
            return response.json();
          } else {
            notification.error({
              message: "Commande",
              description: "Impossible de valider la commande.",
              duration: 15,
            });
            return undefined;
          }
        })
        .then((result: any) => {
          if (result === undefined) {
            this.setState({
              confirmOrder: undefined,
              dataLoading: false,
            });
          } else {
            this.setState({
              confirmOrder: result,
              dataLoading: false,
            });
            this.props.refreshWishlist();
            this.props.refreshWishlistTotal();
            this.props.clearSalesOrder();
          }
        })
        .catch((err: any) => {
          this.setState({
            confirmOrder: undefined,
            dataLoading: false,
            error: true,
          });
          notification.error({
            message: "Commande",
            description: "Impossible de valider la commande.",
            duration: 15,
          });
        });
    }
  };
}

export default Step6Class;
