export enum ArticleCustomerPlatformQuantitativeCacheTypeEnum {
  DISCOUNT_PERCENT = "DISCOUNT_PERCENT",
  DISCOUNT_VALUE = "DISCOUNT_VALUE",
  FREE = "FREE",
  NET_PRICE = "NET_PRICE",
}

//  Contient les type d'application de remise (additionnelle ou se substituant)
export enum ArticleCustomerPlatformQuantitativeCacheApplicationTypeEnum {
  SUBSTITUTE = "SUBSTITUTE",
  ADDITIONAL = "ADDITIONAL",
}

interface IMarketArticleQuantitative {
  quantity?: number;
  type?: ArticleCustomerPlatformQuantitativeCacheTypeEnum;
  price?: number;
  discount?: number;
  freeQuantity?: number;
  freeArticleReference?: string;
  freeArticleDesignation?: string;
  applicationType?: ArticleCustomerPlatformQuantitativeCacheApplicationTypeEnum;
}

export default IMarketArticleQuantitative;
