import IRequestAccount from "./IRequestAccount";

class RequestAccountService {


  public async postRequest(requestAccount: IRequestAccount): Promise<any> {

    return fetch('/api/market/requestAccount', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: "POST",
      body: JSON.stringify(requestAccount)
    })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }


}

export default RequestAccountService;