import React, { useCallback, useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import { requestResetPassword } from "./LoginServiceFunctional";

interface ResetPasswordFormProps {
  onResetPassword: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props: {
  onResetPassword: () => void;
}) => {
  const { onResetPassword } = props;

  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    (values: any) => {
      setLoading(true);

      let url = window.location.protocol + "//" + window.location.host;

      requestResetPassword(url + "/recovery-identifier", values.email).then(
        (result: any) => {
          setLoading(false);
          onResetPassword();
        }
      );
    },
    [onResetPassword]
  );

  return (
    <div id="passwordContainer">
      <p>
        Vous pouvez modifier votre mot de passe pour des raisons de sécurité, ou
        le réinitialiser si vous l'avez oublié.
      </p>
      <p>
        Vous allez recevoir un email contenant les informations nécessaires pour
        réinitialiser votre mot de passe.
        <br />
        La réception de cet e-mail peut prendre quelques minutes.
        <br />
        Vérifiez dans votre dossier des courriers indésirables si vous n'avez
        pas déjà reçu cet e-mail.
      </p>
      <Form onFinish={onFinish} className="login-form">
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "L'email est obligatoire ou n'est pas valide.",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
            autoFocus={true}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            Valider
          </Button>
          <Button
            type="default"
            disabled={loading}
            className="login-form-button"
            style={{ marginTop: 5 }}
            onClick={onResetPassword}
          >
            Annuler
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
