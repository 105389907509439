import IEngineNumberVehicleType from "./model/IEngineNumberVehicleType";
import IKeyEntry from "./model/IKeyEntry";
import IEan from "./model/IEan";

class TecdocUtils {
  public getYesNo = (value: number): string => {
    if (value !== undefined) {
      switch (value) {
        case 0:
          return "Non";
        case 1:
          return "Oui";
        case 2:
          return "Option";
        default:
          return "Inconnu";
      }
    }
    return "";
  };

  public getKeyType = (
    fuelType: IKeyEntry[] | undefined,
    key: number
  ): string => {
    if (fuelType !== undefined) {
      let value = fuelType.find((e) => e.key === key);
      if (value !== undefined) {
        return value.bez;
      }
    }

    return "";
  };

  public getMotorCode = (
    engineNumberVehicleTypes: IEngineNumberVehicleType[]
  ): [] => {
    let items: any = [];
    engineNumberVehicleTypes.forEach(
      (engineNumberVehicleType: IEngineNumberVehicleType) => {
        items.push(
          <span key={engineNumberVehicleType.engine.mcode}>
            {engineNumberVehicleType.engine.mcode}
            <br />
          </span>
        );
      }
    );

    return items;
  };

  public getEans = (eans: IEan[]): string => {
    let items: string = "";
    if (eans !== undefined) {
      eans.forEach((ean: IEan) => {
        items += ean.eanNr + " ";
      });
    }
    return items;
  };
}

export default TecdocUtils;
