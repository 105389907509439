import { Card, Col, Image, List, Row } from "antd";
import { ColumnCount } from "antd/lib/list";
import React, { useCallback, useEffect, useState } from "react";
import PdfViewer from "../../../Utils/PdfViewer";
import DocumentService from "../../library/DocumentService";
import IDocument from "../../library/IDocument";

const CardsDocument = (props: {}): React.ReactElement => {
  const [data, setData] = useState<IDocument[]>([]);
  const [fetching, setFetching] = useState(false);
  const [pdfFile, setPdfFile] = useState(false);
  const [document, setDocument] = useState<IDocument>();

  const loadForm = useCallback(async (): Promise<void> => {
    setFetching(true);
    const documentService: DocumentService = new DocumentService();
    const documents = await documentService.search(
      undefined,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      30,
      0,
      "name,asc"
    );
    setData(documents.content);
    setFetching(false);
  }, []);

  const onClick = useCallback((document: IDocument): void => {
    if (document.url.toLocaleLowerCase().endsWith(".pdf")) {
      setDocument(document);
      setPdfFile(true);
    } else {
      window.open(document.url);
    }
  }, []);

  const onCloseReport = useCallback((): void => {
    setDocument(undefined);
    setPdfFile(false);
  }, []);

  const calculColumn = useCallback((): ColumnCount => {
    if (data.length <= 2) return 2;
    if (data.length <= 4) return 4;
    if (data.length <= 6) return 6;
    return 8;
  }, [data]);

  useEffect(() => {
    loadForm();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {data && data.length > 0 && (
        <Row
          gutter={24}
          justify="center"
          style={{
            marginTop: 25,
            marginBottom: 25,
          }}
        >
          <Col>
            <List
              loading={fetching}
              grid={{ gutter: 16, column: calculColumn() }}
              size={"small"}
              dataSource={data}
              renderItem={(item: IDocument) => (
                <List.Item>
                  <Card
                    bordered={false}
                    hoverable
                    bodyStyle={{ padding: 5 }}
                    onClick={() => onClick(item)}
                  >
                    <div style={{ textAlign: "center" }}>
                      {item.thumbnailUrl && (
                        <Image
                          src={item.thumbnailUrl}
                          alt={item.name}
                          style={{ height: 120 }}
                        />
                      )}
                      {!item.thumbnailUrl && (
                        <Image
                          src="/api/public/placeholder.jpg"
                          alt={item.name}
                          style={{ height: 120 }}
                        />
                      )}
                      <br />
                      {item.name}
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )}
      {document && (
        <PdfViewer
          fileString={"/api/market/documents/" + document.id + "/link"}
          urlType={true}
          visible={pdfFile}
          onClosePrint={onCloseReport}
        />
      )}
    </div>
  );
};

export default CardsDocument;
