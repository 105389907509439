import React from 'react';
import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import { List, Card} from 'antd';

import ICatalogSearch from '../model/ICatalogSearch';
import CatalogService from '../CatalogService';
import IVehicleType from '../model/IVehicleType';

import moment from "moment";
import "moment/locale/fr";
import Text from 'antd/lib/typography/Text';

class CatalogModelYear extends React.Component<
  {
    catalogSearch: ICatalogSearch,
    setCatalogSearch: any,
  },
  {
    dataLoading: boolean,
    data: number[],
  }>{

  private catalogService: CatalogService = new CatalogService();

  constructor(props: { catalogSearch: ICatalogSearch, setCatalogSearch: any }) {
    super(props);

    moment.locale("fr");

    this.state = {
      dataLoading: true,
      data: []
    };
  }

  componentDidMount() {
    if (this.props.catalogSearch.queryType === 'vehicleNode' && this.props.catalogSearch.level3Id !== undefined) {
      this.loadDateRange(this.props.catalogSearch.level3Id);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => contextValues.catalogSearch.level3Id !== undefined &&
          <>
          <Text strong>Sélectionnez l'année</Text>
          <List size="small" loading={this.state.dataLoading}
            style={{marginTop: 15}}
            grid={{ gutter: 16, column: 6 }}
            dataSource={this.state.data}
            renderItem={(date: number) => (
              <List.Item>
                <Card bodyStyle={{ padding: 15, textAlign: "center" }} hoverable onClick={() => this.onSearch(date)} >
                {date}
                </Card>
              </List.Item>
            )}
          />
          </>
        }
      </BasketContext.Consumer>
    );
  }

  private onSearch = (value: number) => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      modelYear: value      
    }
    this.props.setCatalogSearch(catalogSearch);
  }


  private loadDateRange = (value: number) => {

    this.setState({
      dataLoading: true,
    });

    this.catalogService.findVehicleTypeById(value)
    .then((vehicleType: IVehicleType) => {
      
      if(vehicleType !== undefined){
          let startYear = moment(vehicleType.startDate, "YYYYMMDD").year();
          let endYear = moment(vehicleType.endDate, "YYYYMMDD").year();
          let datesRange: number[] = [];
          for(let i = startYear; i <= endYear; i++){
            datesRange.push(i);
          }
          this.setState({
            data : datesRange,
            dataLoading: false
          });
      }
      else {
        this.setState({
          dataLoading: false
        });
      } 

    });

  } 


}

export default withBasket(CatalogModelYear);     