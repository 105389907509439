import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withBasket, BasketContext } from "../../../Utils/BasketProvider";
import { List, Image, Collapse, Row, Col, Button, Form, Input } from "antd";

import ICatalogSearch from "../model/ICatalogSearch";
import CatalogService from "../CatalogService";
import IFamily from "../model/IFamily";
import { SubFamilies } from "./CatalogUtils";
import { Store } from "antd/lib/form/interface";

interface CatalogVehicleTypeFamilyProps {
  catalogSearch: ICatalogSearch;
  setCatalogSearch: any;
}

// Composant qui requête et affiche les familles
// des documents pour le véhicule sélectionné
const CatalogVehicleTypeFamily = ({
  catalogSearch,
  setCatalogSearch,
}: CatalogVehicleTypeFamilyProps): React.ReactElement => {
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  const [dataLoadingState, setDataLoadingState] = useState<boolean>(false);
  const [dataState, setDataState] = useState<IFamily[]>([]);
  const [advancedSearchState, setAdvancedSearchState] = useState<string>();

  const catalogService = useMemo(() => {
    return new CatalogService();
  }, []);

  const loadFamily = useCallback(
    (
      vehiculeTypeId: number,
      modelYear: number | undefined,
      pageSize: number | undefined,
      page: number | undefined,
      query?: string
    ) => {
      setDataLoadingState(true);
      try {
        catalogService
          .findArticleFamilyByVehicleType(
            undefined,
            1,
            vehiculeTypeId,
            modelYear,
            pageSize,
            page,
            "name",
            query ?? undefined
          )
          .then((result: any) => {
            if (result !== undefined && result.content !== undefined) {
              setDataState(result.content);
              setDataLoadingState(false);
            } else {
              setDataLoadingState(false);
            }
          });
      } catch {
        setDataLoadingState(false);
      }
    },
    [catalogService]
  );

  const onSearch = useCallback(
    (
      queryType: string,
      parentId: number,
      parentName: string,
      childrenId: number | undefined,
      childrenName: string | undefined
    ) => {
      let newCatalogSearch: ICatalogSearch = {
        ...catalogSearch,
        level4Id: parentId,
        level4Name: parentName,
        level5Id: childrenId,
        level5Name: childrenName,
      };
      setCatalogSearch(newCatalogSearch);
    },
    [catalogSearch, setCatalogSearch]
  );

  const onSubmit = useCallback((values: Store) => {
    setDataLoadingState(true);
    setAdvancedSearchState(values.text);
    setDataLoadingState(false);
  }, []);

  // Si le champ de recherche avancée est vide,
  // la recherche se relance automatiquement
  const onAllowClear = useCallback((value: any) => {
    if (value === "") {
      setAdvancedSearchState(undefined);
    }
  }, []);

  useEffect(() => {
    if (
      catalogSearch.queryType === "vehicleNode" &&
      catalogSearch.level3Id !== undefined
    ) {
      loadFamily(
        catalogSearch.level3Id,
        catalogSearch.modelYear,
        200,
        0,
        advancedSearchState
      );
    }
  }, [
    advancedSearchState,
    catalogSearch.level3Id,
    catalogSearch.modelYear,
    catalogSearch.queryType,
    loadFamily,
  ]);

  return (
    <BasketContext.Consumer>
      {(contextValues) =>
        contextValues.catalogSearch.level3Id !== undefined && (
          <>
            <Form form={form} onFinish={onSubmit}>
              <Row gutter={8}>
                <Col span={8} style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <Form.Item name="text">
                    <Input
                      placeholder="Recherche avancée"
                      allowClear
                      onChange={(e) => onAllowClear(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Rechercher
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <List
              size="small"
              loading={dataLoadingState}
              grid={{ gutter: 8, column: 3 }}
              grid-auto-row
              dataSource={dataState}
              renderItem={(item: IFamily) => (
                <List.Item style={{ paddingLeft: 4, paddingRight: 4 }}>
                  <Collapse
                    collapsible="header"
                    // activeKey={
                    //   advancedSearchState !== undefined ? [item.id] : undefined
                    // }
                  >
                    <Panel
                      header={item.name}
                      key={item.id}
                      extra={
                        item.imageName !== null && (
                          <Image
                            src={"/api/public/catalog/" + item.imageName}
                            // src={"/catalog/accessoires.jpg"}
                            alt={item.name}
                            style={{ height: 30 }}
                          />
                        )
                      }
                    >
                      <SubFamilies
                        parent={item}
                        childrens={item.children}
                        onSearch={onSearch}
                      />
                    </Panel>
                  </Collapse>
                </List.Item>
              )}
            />
          </>
        )
      }
    </BasketContext.Consumer>
  );
};

export default withBasket(CatalogVehicleTypeFamily);
