import IManufacturer from './IManufacturer';


class ManufacturerService {

  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");

  public async search(size: number | undefined, page: number | undefined, sort: string): Promise<IManufacturer> {

    return fetch('/api/market/manufacturers?customer=' + this.customerId + '&size=' + size + '&page=' + page + '&sort=' + sort, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findManufacturerById(id: number): Promise<any> {

    return fetch('/api/market/manufacturers/' + id + '/masterDataSuppliers?customer=' + this.customerId, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

}

export default ManufacturerService;