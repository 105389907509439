import React from 'react';
import '../../../App.css';
import Table, { ColumnProps } from 'antd/lib/table';

import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import ITecdocSearch from '../model/ITecdocSearch';
import TecdocService from '../TecdocService';
import FlybyUtils from '../../../Utils/FlybyUtils';
import IVehicleModelSerie from '../../tecdoc/model/IVehicleModelSerie';
import IData from '../../../Utils/IData';
import { Button } from 'antd';


class TecdocModel extends React.Component<{
  tecdocSearch: ITecdocSearch,
  setTecdocSearch: any
},
  {
    data: IData
    dataLoading: boolean,
  }>{

  private tecdocService: TecdocService = new TecdocService();
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private herNr: number | undefined;

  private columns: ColumnProps<IVehicleModelSerie>[] = [
    {
      title: 'KmodNr',
      dataIndex: 'kmodNr',
      width: 80,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text: string, record: any) => <Button onClick={() => this.onSelectVehicleModelSerie(record)} type={"link"} style={{ margin: 0, padding: 0 }}>{record.bez}</Button>,
    },
    {
      title: 'Depuis le',
      dataIndex: 'bjvon',
      render: (text: string, record: any) => <div>{this.flybyUtils.convertNumberToDateMMYYYY(record.bjvon)}</div>,
      width: 120,
      align: "right"
    },
    {
      title: 'Jusqu\'à',
      dataIndex: 'bjbis',
      render: (text: string, record: any) => <div>{this.flybyUtils.convertNumberToDateMMYYYY(record.bjbis)}</div>,
      width: 120,
      align: "right"
    }
  ];

  constructor(props: { tecdocSearch: ITecdocSearch, setTecdocSearch: any }) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
    };
  }

  componentDidMount() {
    if (this.props.tecdocSearch.vehicle !== undefined && this.props.tecdocSearch.vehicle.herNr !== undefined) {
      this.herNr = this.props.tecdocSearch.vehicle.herNr;
      this.loadTecdocVehicleModelSerie(this.props.tecdocSearch.vehicle.herNr, this.flybyUtils.getDefaultPageSize(), 0);
    }
  }

  componentWillReceiveProps(newProps: { tecdocSearch: ITecdocSearch, setTecdocSearch: any }) {
    if (newProps.tecdocSearch.vehicle !== undefined && newProps.tecdocSearch.vehicle.herNr !== undefined && this.herNr !== newProps.tecdocSearch.vehicle.herNr) {
      this.herNr = newProps.tecdocSearch.vehicle.herNr;
      this.loadTecdocVehicleModelSerie(newProps.tecdocSearch.vehicle.herNr, this.flybyUtils.getDefaultPageSize(), 0);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) =>
          <Table rowKey="kmodNr" loading={this.state.dataLoading} columns={this.columns} dataSource={this.state.data.content}
            onChange={this.onChange} pagination={{ current: (this.state.data.number + 1), pageSize: this.state.data.size, total: this.state.data.totalElements }}
            style={{ border: '1px solid #e8e8e8', borderWidth: '1px 1px 1px 1px' }} size="middle" />
        }
      </BasketContext.Consumer>
    );
  }

  private onSelectVehicleModelSerie = (vehicleModelSerie: IVehicleModelSerie) => {
    let tecdocSearch = this.props.tecdocSearch;
    if (this.props.tecdocSearch.vehicle !== undefined) {

      tecdocSearch = {
        ...tecdocSearch,
        vehicle: {
          herNr: this.props.tecdocSearch.vehicle.herNr,
          herNrDescription: this.props.tecdocSearch.vehicle.herNrDescription,
          kmodNr: vehicleModelSerie.kmodNr,
          kmodNrDescription: vehicleModelSerie.bez,
          ktypNr: undefined
        },
        searchStructure: undefined
      }

      this.props.setTecdocSearch(tecdocSearch);
    }
  }

  private onChange = (pagination: any, filters: Record<never, string[]>, sorter: any, extra: any) => {
    if (this.props.tecdocSearch.vehicle !== undefined && this.props.tecdocSearch.vehicle.herNr !== undefined) {
      pagination = this.flybyUtils.getCurrentPage(pagination);
      this.loadTecdocVehicleModelSerie(this.props.tecdocSearch.vehicle.herNr, pagination.pageSize, pagination.current);
    }
  }

  private loadTecdocVehicleModelSerie = (herNr: number, pageSize: number | undefined, current: number | undefined) => {
    this.setState({
      dataLoading: true,
    });

    this.tecdocService.findTecdocVehicleModelSerie(herNr, pageSize, current)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            data: result,
            dataLoading: false
          });
        }
        else {
          this.setState({
            dataLoading: false
          });
        }

      });
  }
}

export default withBasket(TecdocModel);     