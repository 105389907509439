import React from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { Input, Button, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';

import { BasketContext, withBasket } from '../../../Utils/BasketProvider';
import FlybyUtils from '../../../Utils/FlybyUtils';

const Search = Input.Search;

class TecdocCardSearch extends React.Component<
  {
    onSearchTecdoc: any,
    setCustomProfil: any,
  },
  {
  }
  >{

  private flybyUtils: FlybyUtils = new FlybyUtils();

  componentDidMount() {
    this.flybyUtils.setProfil(this.props.setCustomProfil);
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) =>
          <Card bordered={false} title={<div><FontAwesomeIcon icon={faCar} /> Recherche par véhicule (base Tecdoc)</div>} size="small"
            bodyStyle={{ minHeight: 100, marginTop: 10 }} headStyle={{ fontWeight: "bold", background: "#FFFFFF", fontSize: "1.1em", color: "#545454" }}>
            {contextValues.customProfil !== undefined && contextValues.customProfil.searchByPlateNumber &&
              <div>
                <Search size="large" placeholder="Immatriculation AA-123-BB"
                  onSearch={(value: any) => this.props.onSearchTecdoc(value, 'plateNumber')} style={{ width: "100%" }}
                  defaultValue={contextValues.tecdocSearch.query !== undefined ? contextValues.tecdocSearch.query.plateNumber : ''} enterButton />
                <Button type="link" style={{ paddingLeft: 0 }}><Link to="/tecdoc">Par modèle de véhicule<RightOutlined /></Link></Button>
              </div>
            }
            {contextValues.customProfil !== undefined && !contextValues.customProfil.searchByPlateNumber &&
            <Button type="default" size="large" style={{ width: '100%' }}><Link to="/tecdoc">Par modèle de véhicule<RightOutlined /></Link></Button>
            }
          </Card>
        }
      </BasketContext.Consumer>
    );
  }
}

export default withBasket(TecdocCardSearch);