import React from "react";
import "../../App.css";
import {
  LeftOutlined,
  RightOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Input,
  Card,
  Button,
  List,
  Modal,
  Radio,
  Checkbox,
  notification,
  Popconfirm,
} from "antd";

import FlybyLayoutForm from "../../Utils/FlybyLayoutForm";
import IStep from "./IStep";
import IAddress from "./IAddress";
import CustomerService from "../mail/CustomerService";
import ISalesOrder from "./ISalesOrder";
import ISession from "../myaccount/ISession";
import ParameterService from "../../Utils/ParameterService";
import { getSession } from "../login/LoginServiceFunctional";

interface IAddressCard {
  address: IAddress;
  onDelete: (addressId: number) => void;
  onEdit: (address: IAddress) => void;
}

const AddressCard: React.FC<IAddressCard> = ({ address, onDelete, onEdit }) => {
  return (
    <Card
      key={"C" + address.id}
      title={<Radio value={address.id}>{address.name}</Radio>}
      bordered={true}
      headStyle={{ height: 65 }}
      extra={[
        address.canUpdated ? (
          <Button
            key={"B1" + address.id}
            type="link"
            style={{
              marginTop: 0,
              marginBottom: 0,
              marginRight: 2,
              marginLeft: 0,
              padding: 0,
            }}
            onClick={() => onEdit(address)}
          >
            <EditOutlined />
          </Button>
        ) : null,
        address.canDeleted ? (
          <Popconfirm
            key={"P" + address.id}
            title={"Supprimer l'adresse ?"}
            okText="Oui"
            cancelText="Non"
            onConfirm={() => onDelete(address.id)}
          >
            <Button
              key={"B2" + address.id}
              type="link"
              style={{
                marginTop: 0,
                marginBottom: 0,
                marginRight: 0,
                marginLeft: 2,
                padding: 0,
              }}
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        ) : null,
      ]}
    >
      <p>
        {address.address1}
        <br />
        {address.address2}
        <br />
        {address.zipCode} {address.city}
        <br />
        {address.phone !== "" && <span>Tel. : {address.phone}</span>}
        <br />
        {address.mobile !== "" && <span>Mobile : {address.mobile}</span>}
        <br />
        {address.email !== "" && <span>Email : {address.email}</span>}
        <br />
      </p>
    </Card>
  );
};

interface IAddressForm {
  visible: boolean;
  onCreate: (values: any) => void;
  onCancel: () => void;
  address: IAddress;
}

const AddressForm: React.FC<IAddressForm> = ({
  visible,
  onCreate,
  onCancel,
  address,
}) => {
  const [form] = Form.useForm();

  const checkEmail = (rule: any, value: string) => {
    /* eslint-disable no-useless-escape */
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "" || (value !== "" && re.test(value))) {
      return Promise.resolve();
    }
    return Promise.reject("L'email est invalide");
  };

  const checkZipCode = (rule: any, value: string) => {
    /* eslint-disable no-useless-escape */
    var re = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (re.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Le code postal est invalide");
  };

  const checkPhone = (rule: any, value: string) => {
    /* eslint-disable no-useless-escape */
    var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    if (value === "" || (value !== "" && re.test(value))) {
      return Promise.resolve();
    }
    return Promise.reject("Le numéro de téléphone est invalide");
  };

  return (
    <Modal
      width={800}
      open={visible}
      destroyOnClose={true}
      title="Adresse"
      okText="Valider"
      cancelText="Annuler"
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then((values) => {
          form.resetFields();
          onCreate(values);
        });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="addressForm"
        size={"middle"}
        fields={[
          {
            name: ["name"],
            value: address.name,
          },
          {
            name: ["address1"],
            value: address.address1,
          },
          {
            name: ["address2"],
            value: address.address2,
          },
          {
            name: ["zipCode"],
            value: address.zipCode,
          },
          {
            name: ["city"],
            value: address.city,
          },
          {
            name: ["phone"],
            value: address.phone,
          },
          {
            name: ["mobile"],
            value: address.mobile,
          },
          {
            name: ["email"],
            value: address.email,
          },
          {
            name: ["favorite"],
            value: address.favorite,
          },
          {
            name: ["id"],
            value: address.id,
          },
        ]}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              label="Raison sociale"
              name="name"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "La raison sociale est obligatoire.",
                },
                { max: 250 },
              ]}
            >
              <Input placeholder="Raison sociale" />
            </Form.Item>

            <Form.Item
              label="Adresse 1"
              name="address1"
              hasFeedback
              rules={[
                { required: true, message: "L'adresse est obligatoire." },
                { max: 250 },
              ]}
            >
              <Input placeholder="Adresse 1" />
            </Form.Item>

            <Form.Item label="Adresse 2" name="address2" rules={[{ max: 250 }]}>
              <Input placeholder="Adresse 2" />
            </Form.Item>

            <Form.Item
              label="Code postal"
              name="zipCode"
              hasFeedback
              rules={[
                { required: true, message: "Le code postal est obligatoire." },
                { max: 10 },
                { validator: checkZipCode },
              ]}
            >
              <Input placeholder="Code postal" />
            </Form.Item>

            <Form.Item
              label="Ville"
              name="city"
              hasFeedback
              rules={[
                { required: true, message: "La ville est obligatoire." },
                { max: 250 },
              ]}
            >
              <Input placeholder="Ville" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Téléphone"
              name="phone"
              rules={[{ max: 20 }, { validator: checkPhone }]}
            >
              <Input placeholder="Téléphone" />
            </Form.Item>

            <Form.Item
              label="Mobile"
              name="mobile"
              rules={[{ max: 20 }, { validator: checkPhone }]}
            >
              <Input placeholder="Mobile" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ validator: checkEmail }, { max: 250 }]}
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Adresse favorite"
              name="favorite"
              valuePropName="checked"
            >
              <Checkbox>Oui</Checkbox>
            </Form.Item>

            <Form.Item name="id">
              <Input hidden={true} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

class Step3 extends React.Component<
  {
    stepConf: IStep | undefined;
    values: any;
    updateSalesOrder: any;
    onNextStep: any;
  },
  {
    dataLoading: boolean;
    listBillingAddress: IAddress[];
    listDeliveryAddress: IAddress[];
    showForm: boolean;
    address: IAddress;
    selectedDelivery: number;
    addEditAddress: boolean;
  }
> {
  private customerService: CustomerService = new CustomerService();
  private parameterService: ParameterService = new ParameterService();

  constructor(props: {
    stepConf: IStep | undefined;
    values: any;
    onNextStep: any;
    updateSalesOrder: any;
  }) {
    super(props);

    let listBillingAddress: IAddress[] = [];
    listBillingAddress.push({
      id: -1,
      name: this.props.values.salesOrder.billingCompanyName,
      favorite: false,
      type: "DELIVERY",
      address1: this.props.values.salesOrder.billingAddress1,
      address2: this.props.values.salesOrder.billingAddress2,
      zipCode: this.props.values.salesOrder.billingZipCode,
      city: this.props.values.salesOrder.billingCity,
      country: "",
      phone: "",
      mobile: "",
      email: "",
      canDeleted: false,
      canUpdated: false,
    });

    this.state = {
      dataLoading: false,
      listBillingAddress: listBillingAddress,
      listDeliveryAddress: [],
      showForm: false,
      address: {
        id: -1,
        name: "",
        favorite: false,
        type: "DELIVERY",
        address1: "",
        address2: "",
        zipCode: "",
        city: "",
        country: "",
        phone: "",
        mobile: "",
        email: "",
        canDeleted: false,
        canUpdated: false,
      },
      selectedDelivery: -1,
      addEditAddress: false,
    };
  }

  componentDidMount() {
    this.parameterService.findValue("ADDEDIT_ADDRESS").then((value: string) => {
      if (value !== undefined) {
        this.setState({
          addEditAddress: JSON.parse(value),
        });
      } else {
        this.setState({
          addEditAddress: false,
        });
      }
    });

    this.loadDeliveryAddress();
  }

  render() {
    return (
      <div>
        {this.props.values.salesOrder !== undefined && (
          <Form
            {...FlybyLayoutForm}
            onFinish={this.onSubmit}
            size={"middle"}
            initialValues={{ selectedDelivery: -1, selectedBilling: -1 }}
            fields={[
              {
                name: ["selectedDelivery"],
                value: this.state.selectedDelivery,
              },
            ]}
          >
            <Row
              style={{
                position: "sticky",
                top: -20,
                backgroundColor: "#fff",
                zIndex: 1,
              }}
            >
              <Col span={24}>
                <div
                  style={{
                    float: "right",
                    margin: 10,
                  }}
                >
                  <Button
                    type="primary"
                    size="large"
                    onClick={() =>
                      this.props.onNextStep(
                        this.props.stepConf?.prevAction.prevStep
                      )
                    }
                    hidden={!this.props.stepConf?.prevAction.enable}
                    style={{ marginRight: 5 }}
                  >
                    <LeftOutlined />
                    {this.props.stepConf?.prevAction.text}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    hidden={!this.props.stepConf?.nextAction.enable}
                  >
                    {this.props.stepConf?.nextAction.text}
                    <RightOutlined />
                  </Button>
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="selectedDelivery" style={{ width: "100%" }}>
                  <Radio.Group style={{ width: "100%" }}>
                    <Card
                      title="Adresse de livraison"
                      headStyle={{ height: 64 }}
                      bordered={false}
                      extra={
                        this.state.addEditAddress ? (
                          <Button
                            size={"middle"}
                            onClick={() => this.setState({ showForm: true })}
                          >
                            Ajouter une adresse de livraison
                          </Button>
                        ) : null
                      }
                    >
                      <List
                        loading={this.state.dataLoading}
                        grid={{ gutter: 4, column: 2 }}
                        size={"small"}
                        dataSource={this.state.listDeliveryAddress}
                        renderItem={(item) => (
                          <List.Item>
                            <AddressCard
                              key={item.id}
                              address={item}
                              onDelete={this.deleteAddress}
                              onEdit={this.editAddress}
                            />
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="selectedBilling" style={{ width: "100%" }}>
                  <Radio.Group style={{ width: "100%" }}>
                    <Card
                      title="Adresse de facturation"
                      headStyle={{ height: 64 }}
                      bordered={false}
                    >
                      <List
                        grid={{ gutter: 4, column: 2 }}
                        size={"small"}
                        dataSource={this.state.listBillingAddress}
                        renderItem={(item) => (
                          <List.Item>
                            <AddressCard
                              key={item.id}
                              address={item}
                              onDelete={this.deleteAddress}
                              onEdit={this.editAddress}
                            />
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <AddressForm
              visible={this.state.showForm}
              onCreate={this.onValidFormAddress}
              onCancel={() => {
                this.setState({ showForm: false });
              }}
              address={this.state.address}
            />
          </Form>
        )}
      </div>
    );
  }

  private editAddress = (address: IAddress) => {
    this.setState({
      address: address,
      showForm: true,
    });
  };

  private deleteAddress = (addressId: number) => {
    this.customerService.deleteAddress(addressId).then((response: any) => {
      if (response.status !== 204) {
        notification.error({
          message: "Adresse",
          description: "Erreur lors de la suppression de l'adresse.",
        });
      }
      this.loadDeliveryAddress();
    });
  };

  private onValidFormAddress = (values: IAddress) => {
    values.type = "DELIVERY";

    if (values.id === -1) {
      this.customerService.addAddress(values).then((response: any) => {
        if (response.status !== 201) {
          notification.error({
            message: "Adresse",
            description: "Erreur lors de la création de l'adresse.",
          });
        }
        this.loadDeliveryAddress();
      });
    } else {
      this.customerService
        .updateAddress(values, values.id)
        .then((response: any) => {
          if (response.status !== 200) {
            notification.error({
              message: "Adresse",
              description: "Erreur lors de la mise à jour de l'adresse.",
            });
          }
          this.loadDeliveryAddress();
        });
    }
    this.setState({
      showForm: false,
      address: {
        id: -1,
        name: "",
        favorite: false,
        type: "DELIVERY",
        address1: "",
        address2: "",
        zipCode: "",
        city: "",
        country: "",
        phone: "",
        mobile: "",
        email: "",
        canDeleted: true,
        canUpdated: true,
      },
    });
  };

  private onSubmit = (values: any) => {
    let address = this.state.listDeliveryAddress.find(
      (address: IAddress) => address.id === values.selectedDelivery
    );
    if (address !== undefined) {
      let salesOrder: ISalesOrder = this.props.values.salesOrder;

      salesOrder.billingId = -1;

      if (address.id !== -1) {
        salesOrder.deliveryAddress = {
          id: address.id,
        };

        salesOrder.deliveryId = address.id;
        salesOrder.deliveryCompanyName = address.name;
        salesOrder.deliveryAddress1 = address.address1;
        salesOrder.deliveryAddress2 = address.address2;
        salesOrder.deliveryZipCode = address.zipCode;
        salesOrder.deliveryCity = address.city;
      }

      this.props.updateSalesOrder(salesOrder);
    }
    this.props.onNextStep(this.props.stepConf?.nextAction.nextStep);
  };

  private loadDeliveryAddress = () => {
    this.setState({
      dataLoading: true,
    });

    let listDeliveryAddress: IAddress[] = [];

    getSession().then((session: ISession) => {
      if (session !== undefined) {
        listDeliveryAddress.push({
          id: -1,
          name: session.customers[0].name,
          favorite: false,
          type: "DELIVERY",
          address1: session.customers[0].deliveryAddress1,
          address2: session.customers[0].deliveryAddress2,
          zipCode: session.customers[0].deliveryZipCode,
          city: session.customers[0].deliveryCity,
          country: "",
          phone: "",
          mobile: "",
          email: "",
          canUpdated: false,
          canDeleted: false,
        });
      }

      this.customerService
        .findAddress("DELIVERY", undefined, undefined, "")
        .then((data: any) => {
          if (data !== undefined) {
            data.content?.map((o: IAddress) => {
              o.canDeleted = this.state.addEditAddress;
              o.canUpdated = this.state.addEditAddress;
              return o;
            });

            listDeliveryAddress = listDeliveryAddress.concat(data.content);

            let favoriteAddress = listDeliveryAddress.find(
              (address: IAddress) => address?.favorite === true
            );
            let selectedDelivery = -1;
            if (favoriteAddress !== undefined) {
              selectedDelivery = favoriteAddress.id;
            } else {
              selectedDelivery = listDeliveryAddress[0].id;
            }

            this.setState({
              listDeliveryAddress: listDeliveryAddress,
              selectedDelivery: selectedDelivery,
              dataLoading: false,
            });
          }
        });
    });
  };
}

export default Step3;
