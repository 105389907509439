import React from 'react';
import '../../App.css';
import { Table, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';


import FlybyUtils from '../../Utils/FlybyUtils';
import DocumentHistoryService from './DocumentHistoryService';
import IData from '../../Utils/IData';
import IDocumentHistoryReturn from './IDocumentHistoryReturn';

class Return extends React.Component<
  {
    externalRequest: boolean
  },
  {
    data: IData,
    dataLoading: boolean,
    queryText: string
  }>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private documentHistoryService: DocumentHistoryService = new DocumentHistoryService();

  private columns: ColumnProps<IDocumentHistoryReturn>[] = [
    {
      title: 'N° document',
      dataIndex: 'documentHistoryDetail.documentHistory.number',
      render: (text: string, record: any) => record.documentHistoryDetail.documentHistory.number,
      width: 80,
    },
    {
      title: 'Plateforme',
      dataIndex: 'plateforme',
      render: (text: string, record: any) =>
        <div>
          {(record.documentHistoryDetail.documentHistory.platform !== null && record.documentHistoryDetail.documentHistory.platform.name !== null) ? record.documentHistoryDetail.documentHistory.platform.name : ""}
        </div>,
      width: 150,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 80,
      render: (text: string, record: any) => this.flybyUtils.formatJsonDateDDMMYYYY(record.requestDate),
    },
    {
      title: 'N° d\'accord',
      dataIndex: 'approvalNumber',
      render: (text: string, record: any) => record.approvalNumber === '0' ? '' : record.approvalNumber,
      width: 100,
    },
    {
      title: 'Etat',
      dataIndex: 'state',
      render: (text: string, record: any) => this.flybyUtils.getStateReturnText(record.state),
      width: 150,
    },
    {
      title: 'Référence',
      dataIndex: 'documentHistoryDetail.articleReference',
      render: (text: string, record: any) => record.documentHistoryDetail.articleReference,
      width: 100,
    },
    {
      title: 'Libellé',
      dataIndex: 'documentHistoryDetail.description',
      render: (text: string, record: any) => record.documentHistoryDetail.description,
      width: 200,
    },
    {
      title: 'Qté demandée',
      dataIndex: 'requestQuantity',
      width: 80,
      render: (text: string, record: any) =>
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(record.requestQuantity, 2))}
        </div>,
      align: "right",
    },
    {
      title: 'Qté acceptée',
      dataIndex: 'approvalQuantity',
      width: 80,
      render: (text: string, record: any) =>
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(record.approvalQuantity, 2))}
        </div>,
      align: "right",
    },
    {
      title: 'Qté refusée',
      dataIndex: 'rejectionQuantity',
      width: 80,
      render: (text: string, record: any) =>
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.hideZeroValue(this.flybyUtils.numberFormat(record.rejectionQuantity, 2))}
        </div>,
      align: "right",
    }
  ];

  constructor(props: { externalRequest: boolean }) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
      queryText: '',
    };
  }

  componentDidMount() {
    this.searchDocumentHistory(this.state.queryText, 20, 0, 'requestDate,desc');
  }

  render() {

    return (
      <div>
        <h3>Demandes de retour</h3>

        {!this.props.externalRequest &&
          <div style={{ marginTop: 15, marginBottom: 20 }}>
            <Link to="/return-request">
              <Button type="primary">Nouvelle demande</Button>
            </Link>
          </div>
        }

        <Table rowKey="id" loading={this.state.dataLoading} columns={this.columns} dataSource={this.state.data.content}
          onChange={this.onChange} pagination={{ current: (this.state.data.number + 1), pageSize: this.state.data.size, total: this.state.data.totalElements }}
          style={{ border: '1px solid #e8e8e8', borderWidth: '1px 1px 1px 1px' }} size="middle" />
      </div>
    );
  }

  private onChange = (pagination: any, filters: Record<never, string[]>, sorter: any, extra: any) => {

    pagination = this.flybyUtils.getCurrentPage(pagination);

    this.searchDocumentHistory(this.state.queryText, pagination.pageSize, pagination.current, 'requestDate,desc');
  }

  private searchDocumentHistory = (query: string, pageSize: number | undefined, current: number | undefined, sort: string) => {

    if (!this.state.dataLoading) {
      this.setState({
        dataLoading: true,
      });
    }

    this.documentHistoryService.getDocumentHistoryReturns(query, pageSize, current, sort)
      .then((data: any) => {
        if (data !== undefined) {

          this.setState({
            data: data,
            dataLoading: false
          });
        }
      });
  }

}

export default Return;