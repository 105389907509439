import React from "react";
import { Tabs, Skeleton, Tag, Typography } from "antd";
import ArticleCriteria from "./ArticleCriteria";

import IArticleTecdoc from "../../model/IArticle";
import IArticle from "../../../home/IArticle";
import ArticleUtils from "../../../home/ArticleUtils";
import IArticleEquivalence from "../../../home/IArticleEquivalence";
import FlybyUtils from "../../../../Utils/FlybyUtils";
import IArticleTag from "../../../home/IArticleTag";
import ArticleService from "../../../home/ArticleService";

const { TabPane } = Tabs;
const { Text } = Typography;

class ArticleTab extends React.Component<
  {
    tecdocArticle: IArticleTecdoc;
    flybyArticle: IArticle | undefined;
    onSelect: any;
  },
  {
    dataLoading: boolean;
    articleTags?: IArticleTag[];
  }
> {
  private articleUtils: ArticleUtils = new ArticleUtils();
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private articleService: ArticleService = new ArticleService();

  constructor(props: {
    tecdocArticle: IArticleTecdoc;
    flybyArticle: IArticle | undefined;
    onSelect: any;
  }) {
    super(props);

    this.state = {
      dataLoading: false,
      articleTags: undefined,
    };
  }

  render() {
    return (
      <Tabs
        defaultActiveKey="0"
        onTabClick={this.onTabClick}
        // onChange={(activeKey): void => this.onTabClick(activeKey)}
      >
        <TabPane tab="Caractéristiques" key="0">
          <ArticleCriteria tecdocArticle={this.props.tecdocArticle} />
        </TabPane>
        <TabPane tab="Equivalence(s)" key="1">
          <Skeleton
            loading={
              this.props.flybyArticle !== undefined &&
              this.props.flybyArticle.equivalence !== undefined &&
              this.props.flybyArticle.equivalence.loading
            }
            active
          >
            {this.props.flybyArticle !== undefined &&
              this.props.flybyArticle.equivalence !== undefined &&
              this.props.flybyArticle.equivalence.list !== undefined &&
              this.props.flybyArticle.equivalence.list.length > 0 &&
              this.props.flybyArticle.equivalence.list.map(
                (articleEquivalence: IArticleEquivalence) => (
                  <Tag
                    key={articleEquivalence.id}
                    onClick={() =>
                      this.props.onSelect(
                        articleEquivalence.equivalentArticle.id
                      )
                    }
                    style={{
                      marginBottom: 2,
                      padding: 3,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span>
                      <img
                        src={
                          "/api/market/manufacturers/" +
                          articleEquivalence.equivalentArticle.manufacturer.id +
                          "/logo"
                        }
                        alt={articleEquivalence.equivalentArticle.reference}
                        key={articleEquivalence.equivalentArticle.reference}
                        style={{ height: 20 }}
                      />
                      <br />
                    </span>

                    <span
                      style={{
                        color: this.flybyUtils.getAvailabilityEquivalenceColor(
                          articleEquivalence.available
                        ),
                      }}
                    >
                      {articleEquivalence.equivalentArticle.manufacturer.name +
                        " :: " +
                        articleEquivalence.equivalentArticle.reference}
                    </span>
                  </Tag>
                )
              )}
            {(this.props.flybyArticle === undefined ||
              this.props.flybyArticle.equivalence === undefined ||
              this.props.flybyArticle.equivalence.list === undefined ||
              this.props.flybyArticle.equivalence.list.length <= 0) && (
              <Text type="secondary">
                Nous n'avons pas trouvé d'équivalence.
              </Text>
            )}
          </Skeleton>
        </TabPane>
        <TabPane tab="Compatibilité" key="2">
          <Skeleton loading={this.state.dataLoading} active>
            {this.state.articleTags !== undefined &&
              this.state.articleTags.length > 0 &&
              this.state.articleTags.map(
                (articleTag: IArticleTag) =>
                  articleTag.type === "EQUIVALENCE" && (
                    <Tag
                      key={articleTag.id}
                      style={{
                        marginBottom: 2,
                        padding: 3,
                        textAlign: "center",
                      }}
                    >
                      {articleTag.value}
                    </Tag>
                  )
              )}
            {(this.state.articleTags === undefined ||
              this.state.articleTags.length <= 0) && (
              <Text type="secondary">
                Nous n'avons pas trouvé d'article compatible.
              </Text>
            )}
          </Skeleton>
        </TabPane>
      </Tabs>
    );
  }

  private onTabClick = (key: string) => {
    if (this.props.flybyArticle !== undefined) {
      switch (key) {
        case "1":
          this.articleUtils.findEquivalences(
            this.props.flybyArticle,
            true,
            this
          );
          break;

        case "2":
          this.setState({
            dataLoading: true,
          });

          this.articleService
            .findTags(this.props.flybyArticle.id, "EQUIVALENCE")
            .then((data: any) => {
              if (data !== undefined) {
                this.setState({
                  articleTags: data.content,
                  dataLoading: false,
                });
              }
            });
          break;
      }
    }
  };
}

export default ArticleTab;
