import "../../App.css";
import { MailOutlined } from "@ant-design/icons";
import { Table, Button, Tooltip, Col, Row } from "antd";
import parse from "html-react-parser";

import IData from "../../Utils/IData";
import { useCallback, useEffect, useState } from "react";
import { getEmptyData } from "../../Utils/FlybyUtilsFunctional";
import { ColumnProps } from "antd/lib/table";
import {
  findParameterValueByName,
  getPublicHtmlPage,
} from "../../Utils/ParameterServiceFunctional";
import { getContacts } from "../../apis/Contact/ContactService";
import { Contact } from "../../apis/Contact/Contact";

const Mail = (): JSX.Element => {
  const [data, setData] = useState<IData>(getEmptyData);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [page, setPage] = useState<string | undefined>(undefined);

  const columns: ColumnProps<Contact>[] = [
    {
      title: "Contact",
      dataIndex: "label",
    },
    {
      title: "Téléphone",
      dataIndex: "phone",
    },
    {
      title: "",
      width: 200,
      align: "center",
      render: (text: string, record: Contact) => (
        <div>
          <Tooltip title={!record.email ? "" : record.email}>
            <Button
              type="link"
              icon={<MailOutlined />}
              disabled={!record.email}
              href={`mailto:${record.email}`}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const searchContact = useCallback(
    async (
      pageSize: number | undefined,
      current: number | undefined,
      sort: string
    ) => {
      setDataLoading(true);

      getContacts(pageSize, current, sort).then((data: any) => {
        if (data !== undefined) {
          setData(data);
          setDataLoading(false);
        }
      });
    },
    []
  );

  const initMailForm = useCallback(async (): Promise<void> => {
    try {
      const loadedPage = await findParameterValueByName("APP_CONTACTS");
      if (loadedPage) {
        getPublicHtmlPage(loadedPage).then((content: string) => {
          setPage(content);
        });
      }
      if (!loadedPage) {
        await searchContact(100, 0, "label");
      }
    } catch (error) {
      setDataLoading(false);
    }
  }, [searchContact]);

  useEffect(() => {
    initMailForm();
  }, [initMailForm]);

  return (
    <Row justify="center">
      {page === undefined && data.content && data.content.length > 0 && (
        <Col span={24}>
          <h3>Mes contacts</h3>
          <Table<Contact>
            // scroll={{ y: 300 }}
            style={{
              flex: "auto",
              // overflowX: "hidden",
              // height: "100%",
              border: "1px solid #e8e8e8",
              borderWidth: "1px 1px 0px 1px",
            }}
            rowKey="id"
            loading={dataLoading}
            columns={columns}
            dataSource={data.content}
            pagination={false}
          />
        </Col>
      )}
      {page !== undefined && <div>{parse(page)}</div>}
    </Row>
  );
};

export default Mail;

// class Mail extends React.Component<
//   {},
//   {
//     data: IData;
//     dataLoading: boolean;
//     page: string | undefined;
//   }
// > {
//   private flybyUtils: FlybyUtils = new FlybyUtils();
//   private customerService: CustomerService = new CustomerService();
//   private parameterService: ParameterService = new ParameterService();

//   private columns = [
//     {
//       title: "Contact",
//       dataIndex: "fullName",
//       width: 250,
//     },
//     {
//       title: "Email",
//       dataIndex: "email",
//       width: 250,
//     },
//     {
//       title: "",
//       width: 50,
//       render: (text: string, record: any) => (
//         <div style={{ textAlign: "right" }}>
//           <Button href={"mailto:" + record.email}>
//             <MailOutlined />
//             Contacter
//           </Button>
//         </div>
//       ),
//     },
//   ];

//   constructor(props: {}) {
//     super(props);

//     this.state = {
//       data: this.flybyUtils.getEmptyData(),
//       dataLoading: true,
//       page: undefined,
//     };
//   }

//   componentDidMount() {
//     this.parameterService.findValue("APP_CONTACTS").then((page: string) => {
//       if (page !== undefined) {
//         this.parameterService
//           .getPublicHtmlPage(page)
//           .then((content: string) => {
//             this.setState({
//               page: content,
//             });
//           });
//       }

//       if (page === undefined) {
//         this.searchCustomerContact("", 100, 0, "id");
//       }
//     });
//   }

//   render() {
//     return (
//       <div>
//         <h3>Mes contacts</h3>

//         {this.state.page === undefined && (
//           <Table
//             rowKey="email"
//             loading={this.state.dataLoading}
//             columns={this.columns}
//             dataSource={this.state.data.content}
//             pagination={false}
//             style={{
//               border: "1px solid #e8e8e8",
//               borderWidth: "1px 1px 0px 1px",
//             }}
//           />
//         )}
//         {this.state.page !== undefined && <div>{parse(this.state.page)}</div>}
//       </div>
//     );
//   }

//   private searchCustomerContact = (
//     query: string,
//     pageSize: number | undefined,
//     current: number | undefined,
//     sort: string
//   ) => {
//     this.setState({
//       dataLoading: true,
//     });

//     this.customerService
//       .search(query, pageSize, current, sort)
//       .then((data: any) => {
//         if (data !== undefined) {
//           this.setState({
//             data: data,
//             dataLoading: false,
//           });
//         }
//       });
//   };
// }

// export default Mail;
