import React from "react";
import { Typography, Tooltip, TablePaginationConfig } from "antd";

import moment from "moment";
import "moment/locale/fr";

import IData from "./IData";
import TecdocService from "../containers/tecdoc/TecdocService";
import ICustomProfil from "../containers/tecdoc/model/ICustomProfil";
import { WarningOutlined } from "@ant-design/icons";

enum State {
  ENABLED = "Actif",
  DISABLED = "Inactif",
}

const { Text } = Typography;

export const tecdocService: TecdocService = new TecdocService();

// constructor() {
//   moment.locale("fr");
// }

export const toTop = (): void => {
  const element = document.getElementById("mainContent");

  if (!element) {
    return;
  }

  if (element.scrollTop > 0) {
    element.scrollTo(0, 0);
  }
};

export const getState = (state: string) => {
  return State[state];
};

export const getCurrentPage = (
  pagination: TablePaginationConfig
): TablePaginationConfig => {
  if (pagination.current === undefined) {
    pagination.current = 0;
  }

  if (pagination.current !== undefined && pagination.current > 0) {
    pagination.current = pagination.current - 1;
  }

  return pagination;
};

export const getSort = (field: string, order: string): string => {
  let sort = "id";
  if (field !== undefined) {
    sort = field;
    sort += ",";
    if (order === "descend") {
      sort += "desc";
    } else {
      sort += "asc";
    }
  }

  return sort;
};

export const getEmptyData = (): IData => {
  let emptyData: IData = {
    content: [],
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      offset: 100,
      pageSize: 50,
      pageNumber: 0,
      paged: true,
      unpaged: false,
    },
    totalPages: 0,
    totalElements: 0,
    last: false,
    size: 50,
    number: 0,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    numberOfElements: 0,
    first: false,
    empty: false,
  };

  return emptyData;
};

export const getDefaultPageSize = (): number => {
  return 20;
};

export const formatJsonDate = (date: any): string => {
  if (date === null || date === undefined) {
    return "";
  }
  return moment.parseZone(date).format("ddd DD/MM/YYYY HH:mm").toString();
};

export const formatDeliveredAt = (date: any): string => {
  if (date === null || date === undefined) {
    return "Nous contacter";
  }
  return formatJsonDate(date);
};

export const formatJsonDateDDMMYYYY = (date: any): string => {
  if (date === null || date === undefined) {
    return "";
  }
  return moment.parseZone(date).format("DD/MM/YYYY").toString();
};

export const convertNumberToDateMMYYYY = (date: number): string => {
  if (date === null || date === undefined) {
    return "";
  }
  return moment(date.toString(), "YYYYMM").format("MM/YYYY").toString();
};

export const getEans = (eans: any[]): string => {
  let items: string = "";
  if (eans !== undefined) {
    eans.forEach((ean: string) => {
      items += ean + " ";
    });
  }
  return items;
};

export const getEans2 = (eans: any[]): [] => {
  let items: any = [];
  let idx: number = 0;
  if (eans !== undefined) {
    eans.forEach((ean: string) => {
      items.push(
        <span key={"ean" + idx}>
          {ean}
          <br />
        </span>
      );
      idx++;
    });
  }
  return items;
};

export const getFirstEan = (eans: string[]): string => {
  let item: string = "";
  if (eans !== undefined && eans.length > 0) {
    item = eans[0];
  }
  return item;
};

export const getAvailabilityColor = (
  state: string | undefined,
  locallyAvailable: boolean,
  deliveredAt: string | undefined | null,
  quantityBackorder: number | undefined,
  longerAvailabilityAlert: boolean | undefined,
  platformId?: number | undefined
): string => {
  let color: string = "#f50";
  if (
    deliveredAt === null &&
    quantityBackorder !== undefined &&
    quantityBackorder > 0
  ) {
    return "#108ee9";
  }
  if (longerAvailabilityAlert) {
    return "#ffbb00";
  }

  if (state !== undefined) {
    switch (state) {
      case "PENDING":
        color = "#ffbb00";
        break;
      case "ACCEPTED":
      case "AVAILABLE":
        color = "#87d068";
        break;
      case "BACKORDER":
      case "PARTIAL":
        color = "#108ee9";
        break;
      case "ERROR":
      case "UNAVAILABLE":
      case "REJECTED":
        color = "#FF0000";
        break;
      case "ARCHIVED":
        color = "#bfbfbf";
        break;
    }
  }
  return color;
};

export const getAvailabilityEquivalenceColor = (
  available: boolean | undefined
): string => {
  let color: string = "#f50";
  if (available !== undefined && available) {
    color = "#87d068";
  }
  return color;
};

export const isSupplierAvailability = (
  quantityBackorder: number
): React.ReactElement => {
  return (
    <>
      {quantityBackorder > 0 && (
        <Tooltip title="Disponibilité fournisseur, le délai peut être plus long">
          <Text
            type="danger"
            style={{
              marginLeft: 5,
              marginBottom: 0,
              position: "relative",
              top: 3,
            }}
          >
            <WarningOutlined style={{ fontSize: 21 }} />
          </Text>
        </Tooltip>
      )}
    </>
  );
};

export const getAvailabilityText = (
  state: string | undefined,
  deliveredAt: string | undefined | null,
  quantityBackorder: number | undefined,
  longerAvailabilityAlert: boolean | undefined,
  supplierAvailabilityLabel: string | undefined
): string => {
  if (supplierAvailabilityLabel) {
    return supplierAvailabilityLabel;
  }
  let text = "Indisponible";

  if (
    deliveredAt === null &&
    quantityBackorder !== undefined &&
    quantityBackorder > 0
  ) {
    return "Commande fournisseur";
  }

  if (state !== undefined) {
    switch (state) {
      case "AVAILABLE":
        text = "Disponible";
        if (longerAvailabilityAlert) {
          text = "Disponible J+";
        }
        break;
      case "PARTIAL":
        text = "Partiellement disponible";
        break;
      case "UNAVAILABLE":
        text = "Indisponible";
        break;
      case "PENDING":
        text = "En cours de traitement";
        break;
      case "ERROR":
        text = "En erreur";
        break;
    }
  }

  return text;
};

export const getStateOrderText = (state: string | undefined): string => {
  let text = "En cours de traitement";
  if (state !== undefined) {
    switch (state) {
      case "ACCEPTED":
        text = "Commande acceptée";
        break;
      case "BACKORDER":
        text = "Commande en reliquat";
        break;
      case "PARTIAL":
        text = "Commande partiellement acceptée";
        break;
      case "REJECTED":
        text = "Commande rejetée";
        break;
      case "PENDING":
        text = "En cours de traitement";
        break;
      case "ERROR":
      case "UNAVAILABLE":
        text = "En erreur";
        break;
      case "ARCHIVED":
        text = "Commande traitée";
        break;
    }
  }

  return text;
};

export const numberFormat = (value: number, fractionDigits: number): string => {
  return value !== undefined
    ? new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(value)
    : "";
};

export const showFile = (blob: any, type: string, filename: string): void => {
  var newBlob = new Blob([blob], { type: type });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //   window.navigator.msSaveOrOpenBlob(newBlob);
  //   return;
  // }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.download = filename;
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const getDocumentHistoryType = (type: string): string => {
  let t: string = "Commande";
  switch (type) {
    case "ORDER":
      return "Commande";
    case "INVOICE":
      return "Facture";
    case "SUMMARY":
      return "Relevé";
    case "RETURN":
      return "Retour";
    case "CREDIT_NOTE":
      return "Avoir";
    case "DELIVERY_NOTE":
      return "Bon de livraison";
  }
  return t;
};

export const hideZeroValue = (value: string): string => {
  let val = "";
  if (value !== "0,00" && value !== "0") val = value;
  return val;
};

export const getStateReturnText = (state: string | undefined): string => {
  let text = "En cours";
  if (state !== undefined) {
    switch (state) {
      case "PENDING":
        text = "En cours d'envoi";
        break;
      case "SUBMITTED":
        text = "En cours de traitement";
        break;
      case "WAITING_RECEIPT":
        text = "Attente réception marchandise";
        break;
      case "RECEIVED":
        text = "Marchandise réceptionnée";
        break;
      case "ACCEPTED":
        text = "Accepté";
        break;
      case "REFUSED":
        text = "Refusé";
        break;
      case "IMPOSSIBLE":
        text =
          "Merci de contacter votre distributeur pour cette demande de retour";
        break;
      case "ERROR":
        text =
          "Erreur : merci de contacter votre distributeur pour cette demande de retour";
        break;
    }
  }

  return text;
};

export const getReturnColor = (state: string | undefined): string => {
  let color: string = "#f50";
  if (state !== undefined) {
    switch (state) {
      case "SUBMITTED":
      case "PENDING":
        color = "#ffbb00";
        break;
      case "ACCEPTED":
        color = "#87d068";
        break;
      case "REJECTED":
        color = "#f50";
        break;
    }
  }

  return color;
};

export const stringFormatUndefined = (value: string | undefined): string => {
  return value !== undefined ? value : "";
};

export const getSessionValue = (key: string): string => {
  let value = sessionStorage.getItem(key);
  if (value != null) {
    return value;
  }
  return "";
};

export const createFilters = (values: any): string => {
  let filters = "";
  Object.keys(values).forEach((key: string) => {
    if (key !== "submit" && values[key] !== undefined) {
      filters += key + ":" + values[key].toString() + ";";
    }
  });
  filters = filters.substring(0, filters.lastIndexOf(";"));
  return filters;
};

export const setProfil = (setCustomProfil: any): void => {
  let uid = sessionStorage.getItem("tecdocUid");
  if (uid !== null) {
    tecdocService.findByUid(uid).then((customProfil: ICustomProfil) => {
      setCustomProfil(customProfil);
    });
  }
};
