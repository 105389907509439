import { Button, Card, Col, Image, Row, Tag, Tooltip } from "antd";
import IArticle from "../IArticle";
import IArticleSearchResult from "../IArticleSearchResult";
import { ShoppingCartOutlined, StarOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { MarketParameterContext } from "../../../Utils/MarketParameterContext";
import { BasketContext } from "../../../Utils/BasketProvider";
import {
  checkArticleAvailability,
  onAddBookmark,
  onChangePlatform,
  onChangeQuantity,
} from "../ArticleUtilsFunctional";
import ArticleAvailability2 from "./ArticleAvailability2";
import ArticleImage from "./ArticleImage";
import ArticleWishlistForm from "../ArticleWishlistForm";
import MoreAvailabilities from "./MoreAvailabilities";
import ArticlePrice from "./ArticlePrice";
import ArticleEquivalence2 from "../ArticleEquivalence2";

interface ArticleCardProps {
  item: IArticleSearchResult;
  dataLoading: boolean;
  // onChangeSelect: (platformId: number, item: IArticle) => void;
  // onChangeQuantity: (
  //   quantity: number,
  //   platformId: number,
  //   item: IArticle
  // ) => void;
  setSelectedArticleSearchResult: (
    selectedArticleSearchResult: IArticleSearchResult
  ) => void;
  onSelectEquivalence: (articleId: number) => void;
  setSelectedArticle: (selectedArticle: IArticle | undefined) => void;
  isModal: boolean;
  infosTecdoc?: boolean;
  infosCatalogF1?: boolean;
  openModalAccount: () => void;
}

const ArticleCard2 = ({
  item,
  dataLoading,
  // onChangeSelect,
  // onChangeQuantity,
  setSelectedArticleSearchResult,
  onSelectEquivalence,
  setSelectedArticle,
  isModal,
  infosTecdoc,
  infosCatalogF1,
  openModalAccount,
}: ArticleCardProps): React.ReactElement => {
  const customerId = sessionStorage.getItem("customerId");

  const [articleState, setArticleState] = useState<IArticleSearchResult>();
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const onSelectedArticle = useCallback(
    (articleSearchResult: IArticleSearchResult) => {
      setSelectedArticleSearchResult(articleSearchResult);
      setSelectedArticle(articleSearchResult.article);
    },
    [setSelectedArticle, setSelectedArticleSearchResult]
  );

  // const onChangeArticleQuantity = async () => {};
  const onChangeArticleQuantity = async (
    quantity: number,
    platformId: number
  ) => {
    if (articleState) {
      setLoadingState(true);
      try {
        const newItem = await onChangeQuantity(
          quantity,
          platformId,
          articleState.article
        );
        setArticleState({
          ...articleState,
          article: { ...newItem, quantity: quantity },
        });
      } finally {
        setLoadingState(false);
      }
    }
  };

  const onChangeArticlePlatform = async (
    platformId: number,
    currentItem: IArticle
  ) => {
    if (articleState) {
      // const item = {...currentItem}
      setLoadingState(true);
      try {
        const newItem = await onChangePlatform(
          platformId,
          articleState.article
        );
        setArticleState({ ...articleState, article: newItem });
      } finally {
        setLoadingState(false);
      }
    }
  };

  const searchAvailabilities = useCallback(async () => {
    if (
      item.article.articlePlatforms &&
      item.article.articlePlatforms.length > 0
    ) {
      item.article.quantity = item.article.articlePlatforms[0].salePackaging;

      try {
        setLoadingState(true);
        const articleWithAvailabilities = await checkArticleAvailability(
          undefined,
          item.article
        );

        const newArticle = {
          ...item,
          article: {
            ...item.article,
            moreAvailabilitiesShow:
              articleWithAvailabilities.moreAvailabilitiesShow,
            listAvailabilities: articleWithAvailabilities.listAvailabilities,
            availability: articleWithAvailabilities.availability,
            moreAvailabilities: articleWithAvailabilities.moreAvailabilities,
          },
        };
        setArticleState(newArticle);
      } finally {
        setLoadingState(false);
      }
    }
  }, [item]);

  useEffect(() => {
    searchAvailabilities();
  }, [searchAvailabilities]);

  const displayTitleCard = useCallback(
    (userRole: string, showManufacturer: boolean): React.ReactElement => {
      return (
        <div>
          <Row>
            {userRole !== "ROLE_GUEST" && (
              <Col flex="40px">
                {item.bookmarked && (
                  <Tooltip
                    title="Cet article est dans vos favoris"
                    placement="right"
                  >
                    <StarOutlined
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#1890ff",
                        marginTop: 10,
                      }}
                    />
                  </Tooltip>
                )}
                {!item.bookmarked && (
                  <Tooltip
                    title="Ajouter cet article au favoris"
                    placement="right"
                  >
                    <Button
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      onClick={() => onAddBookmark(item)}
                      type="link"
                    >
                      <StarOutlined
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          color: "#BFBFBF",
                        }}
                      />
                    </Button>
                  </Tooltip>
                )}
              </Col>
            )}
            <Col flex="auto">
              <Button
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                }}
                type="link"
                onClick={() => onSelectedArticle(item)}
              >
                {item.article.reference} : {item.article.name}
              </Button>
            </Col>
            <Col flex="70px">
              {item.quantityInWishlists > 0 && (
                <Tooltip
                  title={`${item.quantityInWishlists} dans le panier`}
                  placement="left"
                >
                  <Tag color="orange">
                    {item.quantityInWishlists}
                    <ShoppingCartOutlined />
                  </Tag>
                </Tooltip>
              )}
            </Col>
            <Col flex="100px" style={{ textAlign: "right" }}>
              {showManufacturer && (
                <Image
                  src={
                    "/api/market/manufacturers/" +
                    item.article.manufacturer.id +
                    "/logo?customer=" +
                    customerId
                  }
                  key={item.article.reference}
                  alt={item.article.reference}
                  style={{ maxWidth: 100, maxHeight: 30 }}
                />
              )}
            </Col>
          </Row>
        </div>
      );
    },
    [customerId, item, onSelectedArticle]
  );

  return (
    <MarketParameterContext.Consumer>
      {(parameters) => (
        <BasketContext.Consumer>
          {(basket) => (
            <Card
              title={displayTitleCard(
                basket.userRole,
                parameters.SHOW_MANUFACTURER
              )}
              headStyle={{
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: "#FAFAFA",
              }}
            >
              {item.known && (
                <>
                  <Row>
                    <Col flex="130px">
                      <ArticleImage item={item.article} />
                    </Col>
                    <Col flex="550px">
                      {/* <Skeleton loading={loadingState} active> */}
                      {articleState && (
                        <ArticleAvailability2
                          articleState={articleState.article}
                          loadingState={loadingState}
                          showAvailability={false}
                          showManufacturer={parameters.SHOW_MANUFACTURER}
                          currentSupplierAvailabilityStatus={
                            parameters.SUPPLIER_AVAILABILITY_STATUS
                          }
                          currentSupplierAvailabilityLabel={
                            parameters.SUPPLIER_AVAILABILITY_LABEL
                          }
                          currentSupplierAvailabilityWarningEnabled={
                            parameters.SUPPLIER_AVAILABILITY_WARNING_ENABLED
                          }
                          openModalAccount={openModalAccount}
                        />
                      )}
                      {/* </Skeleton> */}
                    </Col>
                    <Col flex="auto">
                      {articleState && (
                        <ArticleWishlistForm
                          articleState={articleState.article}
                          currentSupplierAvailabilityStatus={
                            parameters.SUPPLIER_AVAILABILITY_STATUS
                          }
                          onSelectArticlePlatform={(value: number) =>
                            onChangeArticlePlatform(value, item.article)
                          }
                          onChangeQuantity={onChangeArticleQuantity}
                          loading={loadingState}
                        />
                      )}
                      <br />
                      {/* TODO : */}
                      {articleState && (
                        <Col xl={24} xxl={12}>
                          <MoreAvailabilities
                            item={articleState.article}
                            loading={loadingState}
                          />
                        </Col>
                      )}
                    </Col>
                    <Col flex="140px">
                      {basket.userRole !== "ROLE_GUEST" && articleState && (
                        <ArticlePrice
                          item={articleState?.article}
                          loading={loadingState}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 15 }}>
                    <Col span={24}>
                      <ArticleEquivalence2
                        item={item.article}
                        onSelectEquivalence={onSelectEquivalence}
                        isModal={isModal}
                        infosTecdoc={infosTecdoc}
                        infosCatalogF1={infosCatalogF1}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          )}
        </BasketContext.Consumer>
      )}
    </MarketParameterContext.Consumer>
  );
};

export default ArticleCard2;
