import IManufacturer from './IManufacturer';


class NotificationService {

  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");

  public async search(type: string, size: number | undefined, page: number | undefined, sort: string): Promise<IManufacturer> {

    return fetch('/api/market/notifications?customer=' + this.customerId + '&type=' + type + '&size=' + size + '&page=' + page + '&sort=' + sort, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

}

export default NotificationService;