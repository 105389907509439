import IOrderProgress from "./IOrderProgress";
import ISalesOrder from "./ISalesOrder";

class SalesOrderService {
  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");

  public async postOrder(salesOrder: any): Promise<any> {
    return fetch("/api/market/orders?customer=" + this.customerId, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(salesOrder),
    })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getOrder(salesOrderId: number): Promise<ISalesOrder> {
    return fetch(
      "/api/market/orders/" + salesOrderId + "?customer=" + this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/pdf;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getOrderProgress(): Promise<IOrderProgress> {
    return fetch("/api/market/orderImport?customer=" + this.customerId, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async search(
    query: string,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    return fetch(
      "/api/market/orders?size=" +
        size +
        "&page=" +
        page +
        "&query=" +
        query +
        "&sort=" +
        sort +
        "&customer=" +
        this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async report(salesOrderId: number): Promise<any> {
    return fetch(
      "/api/market/orders/" +
        salesOrderId +
        "/report?customer=" +
        this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/pdf",
          "Content-Type": "application/pdf;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.blob();
      })
      .then((data) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async platformExport(): Promise<any> {
    return fetch("/api/market/platformExport?customer=" + this.customerId, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "text/csv",
        "Content-Type": "text/csv;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.blob();
      })
      .then((data) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async manufacturerExport(): Promise<any> {
    return fetch(
      "/api/market/manufacturerExport?customer=" +
        this.customerId +
        "&sort=name",
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "text/csv",
          "Content-Type": "text/csv;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.blob();
      })
      .then((data) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}

export default SalesOrderService;
