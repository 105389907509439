import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useMarketParameter } from "../Utils/MarketParameterContext";
import ParameterService from "../Utils/ParameterService";

const Header = (): React.ReactElement => {
  const { APP_HEADER } = useMarketParameter();
  const [page, setPage] = useState<string>();

  const parameterService: ParameterService = new ParameterService();

  useEffect(() => {
    if (APP_HEADER !== undefined && APP_HEADER !== "") {
      parameterService
        .getPublicHtmlPage(APP_HEADER)
        .then((response: string) => {
          setPage(response);
        });
    }

    // eslint-disable-next-line
  }, []);

  return <div>{page !== undefined && <div>{parse(page)}</div>}</div>;
};

export default Header;
