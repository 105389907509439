import React from 'react';
import '../../../App.css';
import { List, Card } from 'antd';

import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import IManufacturer from '../model/IManufacturer';
import TecdocService from '../TecdocService';
import IData from '../../../Utils/IData';
import FlybyUtils from '../../../Utils/FlybyUtils';
import ITecdocSearch from '../model/ITecdocSearch';


class TecdocManufacturer extends React.Component<
  {
    tecdocSearch: ITecdocSearch,
    setTecdocSearch: any
  },
  {
    data: IData,
    dataLoading: boolean,
  }>{

  private tecdocService: TecdocService = new TecdocService();
  private flybyUtils: FlybyUtils = new FlybyUtils();

  constructor(props: { tecdocSearch: ITecdocSearch, setTecdocSearch: any }) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
    };
  }

  componentDidMount() {
    this.loadTecdocManufactuer(150, 0);
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) =>

          <List
            loading={this.state.dataLoading}
            grid={{ gutter: 4, column: 6 }}
            dataSource={this.state.data.content}
            size={"small"}
            renderItem={(item: IManufacturer) => (
              <List.Item key={item.herNr}>

                <Card hoverable bodyStyle={{ textAlign: 'center', padding: 5, cursor: "pointer" }} onClick={() => this.onSelectManufacturer(item)}>
                  <img src={'/tecdoc-api/manufacturers/' + item.herNr + '/logo'}
                    title={item.herNr + '-' + item.bez}
                    alt={item.bez} style={{ height: 60, marginBottom: 5 }} />
                  <br />
                  {item.bez}
                </Card>
              </List.Item>
            )}
          />
        }
      </BasketContext.Consumer>
    );
  }

  private onSelectManufacturer = (manufacturer: IManufacturer) => {

    let tecdocSearch = this.props.tecdocSearch;

    tecdocSearch = {
      ...tecdocSearch,
      vehicle: {
        herNr: manufacturer.herNr,
        herNrDescription: manufacturer.bez,
        kmodNr: undefined,
        ktypNr: undefined,
      },
      searchStructure: undefined
    }

    this.props.setTecdocSearch(tecdocSearch);
  }

  private loadTecdocManufactuer = (pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.tecdocService.findManufacturerHighlight(pageSize, page)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            data: result,
            dataLoading: false
          });
        }
        else {
          this.setState({
            dataLoading: false
          });
        }
      });
  }
}

export default withBasket(TecdocManufacturer);     